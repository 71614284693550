import { API_SERVICE_URL } from '../config/service'
import api from './auth/client'
const serviceConfig = require('../config/service.json')

class SupportService {
    async submitSupport(supportRequest: any) {
        var url = API_SERVICE_URL + serviceConfig.submitSupportURL
        return api.post(url, supportRequest, {
            headers: {
                Pragma: 'no-cache',
                'Cache-Control':
                    'no-cache, no-store, max-age=0, must-revalidate',
                Expires: -1,
            },
        })
    }

    async supportAttachFile(payload: any) {
        const { id, file } = payload
        const formData = new FormData()
        formData.append('document', file[0])
        var url = API_SERVICE_URL + serviceConfig.submitSupportURL
        url += `/${id}/attachment/${file[0].name}`

        return api.post(url, formData.get('document'), {
            headers: {
                'Content-Type': 'application/octet-stream',
                Pragma: 'no-cache',
                'Cache-Control':
                    'no-cache, no-store, max-age=0, must-revalidate',
                Expires: -1,
            },
        })
    }
}
export default new SupportService()
