import { Spinner } from 'react-bootstrap'

const LoadingSection = () => (
    <div style={{ marginLeft: '1.5rem' }}>
        <Spinner animation="grow" variant="info" size="sm" />
        <Spinner animation="grow" variant="info" size="sm" />
        <Spinner animation="grow" variant="info" size="sm" />
        <span> Working on it...</span>
    </div>
)

export default LoadingSection
