import { Cache } from '@aws-amplify/cache'
import { Amplify } from 'aws-amplify'
import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios'
import qs from 'qs'
import amplify_conf from './amplify'

Amplify.configure(amplify_conf)
const authClient = axios.create({})

authClient.interceptors.request.use(
    async (config) => {
        try {
            const response = await fetchAuthSession()
            const accessToken = response.tokens
            const access_token = accessToken ? accessToken.accessToken : null
            if (access_token) {
                config.headers['Authorization'] =
                    'Bearer ' + access_token.toString()
            } else {
                Cache.clear()
                // await Auth.Credentials.clear()
                window.location.href = '/signIn'
            }
        } catch (err) {
            Cache.clear()
            //await Auth.Credentials.clear()
            window.location.href = '/signIn'
        }
        return config
    },
    (error: any) => {
        return Promise.reject(error)
    }
)

authClient.defaults.paramsSerializer = (params) =>
    qs.stringify(params, { arrayFormat: 'repeat' })

export const isLoggedIn = async () => {
    const response = await fetchAuthSession()
    const accessToken = response.tokens
    const access_token = accessToken ? accessToken.accessToken : null
    if (access_token) return true

    return false
}
export default authClient
