import { FormEvent, FunctionComponent, useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { LayoutStylePreference } from '../models/layoutStyle'

const BackgroundImageContainer = styled.div`
    position: relative;
    height: 200px;
    min-height: 200px;
    max-height: 200px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
`

const StaticBackgroundImage = styled(BackgroundImageContainer)`
    z-index: 2;
`

const HoverBackgroundImage = styled(BackgroundImageContainer)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.5s ease;
`
const NoImageFallback = styled(HoverBackgroundImage)`
    opacity: 1;
    z-index: 1;
`

const GradientOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0) 52.5%,
        rgba(0, 0, 0, 0.46) 100%
    );
`

const DataContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-bottom: auto;
    max-width: 100%;
`

const CardCopy = styled.span`
    display: flex;
    color: #fff;
    text-align: left;
    justify-content: left;
    font-size: 1.75rem;
    font-family: 'IntelOne Display';
    font-weight: 300;
    color: ${(props) => props.theme.color.text.navy};
    margin-bottom: 0.5rem;
`

const CardName = styled(CardCopy)`
    line-height: 2rem;
    margin-bottom: 0.5rem;
`

const PoweredBy = styled(CardCopy)`
    font-weight: 500;
    font-size: 0.9rem;
`

const CardDescription = styled(CardCopy)`
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    font-family: 'IntelOne Text';
`

const PreviewCompareWrapper = styled.div`
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 1rem;
`

const ActionSpan = styled.span`
    .form-check {
        min-height: unset;
        padding: 0;
        margin: 0;
        .form-check-input {
            float: unset;
            margin: 0;
        }
    }
`

const FormCheck = styled(Form.Check)`
    position: relative;
    height: 20px;
    width: 20px;
    display: block;
    input[type='checkbox'] {
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.5);
        border: 1px solid #fff;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            background-color: transparent;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); /* Centered box shadow */
            transform: translate(-50%, -50%);
            border-radius: 4px;
            opacity: 0.5;
            transition: opacity 0.2s ease;
        }

        &:checked {
            background-color: #9fd541;
        }

        &:disabled {
            opactiy: 0.5;
        }
    }
    input {
        height: 20px;
        width: 20px;
    }
`

interface cardOrLinkProps {
    to?: string
    onClick?: (event: FormEvent<HTMLElement>) => void
}

export interface IAiModelCardProps {
    uuid: string
    modelName: string
    displayName: string
    name: string
    publishedBy: string
    author?: string
    logo?: string
    category: []
    description: string
    renderStyle: LayoutStylePreference // remove this... it is vStyle in every case
    isActive?: boolean
    showCheckboxPanel?: boolean
    isChecked?: boolean
    isCompare?: boolean
    index?: number
    showRadioOptionPanel?: boolean
    solution?: string
    isDeployView?: boolean
    poweredBy?: any
    stream?: boolean
    tags?: { [key: string]: string }
    onCardClickCallBack?: (event: FormEvent<HTMLElement>, model: any) => void
    onHCardClickCallBack?: (event: FormEvent<HTMLElement>, model: any) => void

    video?: string
    repository?: string // "{name: string, link: string}" JSON string of content, must parse
    downloadDescription?: string // installation content
    version?: string //version content JSON string of content, must parse

    download?: string // download link
    documentation?: string // documentation content
}

export interface IAiModelProps {
    model: IAiModelCardProps
}

const ModelCardStyle = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 1px solid #e2e2e4;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border-radius: 0;
    flex-grow: 1;
    background: #fff;
    color: #000;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active,
    &.active {
        color: #000;
        background-color: #fff;
        border-color: none;
    }

    & :hover {
        .bgImage {
            background-size: 150%;
            opacity: 1;
        }
        .cardName {
            text-decoration: underline;
        }
        .previewSpan {
            background-color: rgba(242, 242, 242, 0.9);
        }
    }
`

const ModelCardLink = styled(ModelCardStyle).attrs({ as: Link })``

const AiModelCard: FunctionComponent<IAiModelProps> = ({
    model,
}: IAiModelProps) => {
    const handleOnCardClick = useCallback(
        (event: FormEvent<HTMLElement>, thisModel: IAiModelCardProps) => {
            // dont try to modify the original
            const modelClone = JSON.parse(JSON.stringify(thisModel))
            if (thisModel.onCardClickCallBack) {
                modelClone.isChecked = !thisModel.isChecked
                thisModel.onCardClickCallBack(event, modelClone)
            }
        },
        []
    )

    const renderCheckboxPanel = (
        <FormCheck
            type="checkbox"
            key={model.displayName}
            value={model.displayName}
            label=""
            aria-label={model.displayName}
            checked={model.isChecked}
            onChange={() => {}}
            disabled={
                model.isDeployView
                    ? !model.isActive
                    : model.isCompare
                    ? !model.isActive
                    : false
            }
        />
    )

    const renderRadioOptionPanel = (
        <FormCheck
            type="radio"
            key={model.modelName}
            id={model.modelName}
            value={model.modelName}
            label=""
            aria-label={model.modelName}
            checked={model.isChecked}
            onChange={() => {}}
            disabled={!model.isActive}
        />
    )

    const renderCardInnerComponent = (
        <>
            <BackgroundImageContainer>
                <NoImageFallback
                    style={{
                        backgroundImage: `url('/aiModelImage/no-img-default.gif')`,
                    }}
                />
                <StaticBackgroundImage
                    style={{ backgroundImage: `url('${model.logo}')` }}
                />
                <HoverBackgroundImage
                    className="bgImage"
                    style={{ backgroundImage: `url('${model.logo}')` }}
                />
                <GradientOverlay />
            </BackgroundImageContainer>

            <PreviewCompareWrapper>
                <ActionSpan>
                    {model.isCompare && renderCheckboxPanel}
                    {model.showRadioOptionPanel && renderRadioOptionPanel}
                </ActionSpan>
            </PreviewCompareWrapper>

            <DataContainer>
                <CardName className="cardName">{model.displayName}</CardName>
                {model.tags?.PoweredBy  && (
                    <PoweredBy>Powered by {model.tags?.PoweredBy[0]}</PoweredBy>
                )}
                <CardDescription>{model.description}</CardDescription>
            </DataContainer>
        </>
    )

    const renderCard = () => {
        let cardAttributes: cardOrLinkProps = {}

        if (!model.isCompare && model.isActive) {
            cardAttributes.to = `/solution/${model.uuid}`
        } else {
            cardAttributes.onClick = (e: FormEvent<HTMLElement>) =>
                handleOnCardClick(e, model)
        }

        const Card = model.isCompare ? ModelCardStyle : ModelCardLink

        return (
            <Card {...cardAttributes} key={model.uuid} tabIndex={0}>
                {renderCardInnerComponent}
            </Card>
        )
    }

    return renderCard()
}

export default AiModelCard
