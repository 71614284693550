/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { FunctionComponent } from 'react'
import { genericErrorMessage } from '../models/error'

export interface IErrorProps {}

const ErrorPanel: FunctionComponent<IErrorProps> = ({}: IErrorProps) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '1rem',
                    justifyContent: 'center',
                }}
            >
                <img src="/astronaut.png" height="30px" width="30px" />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <p>{genericErrorMessage}</p>
            </div>
        </div>
    )
}

export default ErrorPanel
