import { FunctionComponent, useEffect, useState } from 'react'
import { CopyBlock, dracula } from 'react-code-blocks'
import { audioChatPythonCode } from '../../../components/code/audioPythonCode'
import { reasoningChatPythonCode } from '../../../components/code/reasoningPythonCode'
import { visualChatPythonCode } from '../../../components/code/visualChatPythonCode'
import { APP_DOMAIN, HOST_ENV_DOMAIN_MAPPING } from '../../../config/service'
import { ragModelExampleData } from '../../../data/ragExampleData'
import { isDevAndLocal } from '../../../lib/host'
import { ModelSubType, ModelType } from '../../../models/modelType'
import { IAiModelCardProps } from '../../aiModelCard'

export interface PythonTabProps {
    model: IAiModelCardProps
    currentPromptMessage?: string
}

const PythonTab: FunctionComponent<PythonTabProps> = ({
    model,
    currentPromptMessage,
}: PythonTabProps) => {
    const chatMessage = currentPromptMessage || `User input text`

    const escapeQuotes = (str: string) => {
        return str.replace(/'/g, "\\'").replace(/"/g, '\\"')
    }
    const stream = model.stream ? 'True' : 'False'

    const baseURL = isDevAndLocal(APP_DOMAIN)
        ? `https://${HOST_ENV_DOMAIN_MAPPING.dev}/api/ai`
        : `https://${APP_DOMAIN}/api/ai`

    const commandText = `from openai import OpenAI

        #Command Text (Default)

        client = OpenAI(
            base_url = "${baseURL}",
            api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
        )

        completion = client.chat.completions.create(
            model="${model.name}",
            messages="${escapeQuotes(chatMessage)}",
            temperature=0.5,
            top_p=1,
            max_tokens=512,
            stream=${stream.toLowerCase()}
        )

        print(completion.choices[0].message)

    `

    const commandSummarizationText = `from openai import OpenAI

        #Summarization Text

        client = OpenAI(
            base_url = "${baseURL}",
            api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
        )

        completion = client.chat.completions.create(
            model="${model.name}",
            messages="${escapeQuotes(chatMessage)}",
            stream=${stream.toLowerCase()}
        )

        print(completion.choices[0].message)

    `

    const commandTranslationText = `from openai import OpenAI

        #Translation Text

        client = OpenAI(
            base_url = "${baseURL}",
            api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
        )

        completion = client.chat.completions.create(
            model="${model.name}",
            messages="${escapeQuotes(chatMessage)}",
            max_tokens=512,
            stream=${stream.toLowerCase()}
        )

        print(completion.choices[0].message)

    `

    const commandImageGenrationText = `import base64 from openai import OpenAI

        #Image Generation Text

        client = OpenAI(
            base_url = "${baseURL}",
            api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
        )

        image = client.images.generate(
            model="stabilityai/stable-diffusion-2-base",
            prompt="${escapeQuotes(chatMessage)}",
            n=1
        )

        with open("sample.png", "wb") as fd:
            fd.write(base64.b64decode(image.b64_json))
    `

    const ChatQnAInputFileCommandText = `from openai import OpenAI

        #Chat QnA Input File Command Text

        client = OpenAI(
            base_url = "${baseURL}",
            api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
        )    
        completion = client.chat.completions.create(
            model="${model.name}",
            messages=[
            {
                "role": "system",
                "content":"<file content>"
            }
            {
                "role": "user",
                "content": "${escapeQuotes(chatMessage)}"
            }     
        ],     
            max_tokens=512,
            stream=${stream.toLowerCase()}
        )

        print(completion.choices[0].message)
    `

    const [codeType, setCodeType] = useState<string>(commandText)

    useEffect(() => {
        let modelTypeValues = Object.values(ModelType)

        // TODO: this could be dicey since the enterprise rag is a "use case" tag, and we are grabbing the first case from the array
        let primaryUse = modelTypeValues.find((modelType) =>
            model?.tags?.['Use Case'].includes(modelType)
        )

        console.log('primaryUse', primaryUse)

        let modelSubType = model?.tags?.['Subtype']

        switch (primaryUse) {
            // case ModelType.Trending:
            //     break
            case ModelType.Audio:
                setCodeType(audioChatPythonCode)
                break
            case ModelType.Retrieval:
                //TODO: resetClicked needs to be integrated into the SolutionTabsLayout so that
                //demo interaction propogates to python tab. also need to integrate the retrievalOutputData

                // !isResetClicked &&
                //     retrievalOutputData?.data[0]?.embedding
                //         ?
                //         `    [\n\t${retrievalOutputData?.data[0]?.embedding.join(
                //                 ', \n\t'
                //             )}\n    ]`
                //         :
                setCodeType(ragModelExampleData)
                break
            case ModelType.Reasoning:
                if (modelSubType?.includes(ModelSubType.WithDataInputFile))
                    setCodeType(ChatQnAInputFileCommandText)
                else if (modelSubType?.includes(ModelSubType.textToCode))
                    setCodeType(reasoningChatPythonCode)
                else setCodeType(commandText)
                break
            case ModelType.SummaryDoc:
                setCodeType(commandSummarizationText)
                break
            case ModelType.Translation:
                setCodeType(commandTranslationText)
                break
            case ModelType.Visual:
                if (modelSubType?.includes(ModelSubType.textToImage)) {
                    // if(isNewModelLoad
                    //     ? defaultImagePath
                    //     : outputImageURL){}
                    setCodeType(commandImageGenrationText) //ModelSubType.textToImage
                } else setCodeType(visualChatPythonCode) //ModelSubType.imageToText
                break
            // case ModelType.SolutionVector:
            //     break
            // case ModelType.Deploy:
            //     break
            // case ModelType.Custom:
            //     break
            default:
                setCodeType(commandText)
                break
        }
    }, [])

    return (
        <pre style={{ width: '100%', margin: 0 }}>
            <CopyBlock
                key="copy-block"
                theme={dracula}
                text={codeType}
                language={'Python'}
                showLineNumbers={false}
                wrapLongLines={true}
                customStyle={{ padding: '1rem' }}
            />
        </pre>
    )
}

export default PythonTab
