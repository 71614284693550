import { FunctionComponent } from 'react'
import styled from 'styled-components'

const VideoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

interface DemoVideoProps {
    video: string
    modelName?: string
    width?: string
    height?: string
}

const DemoVideo: FunctionComponent<DemoVideoProps> = ({
    video,
    modelName = 'Solution',
    width = '560',
    height = '315',
}) => {
    return (
        <VideoWrapper>
            <iframe
                width={width}
                height={height}
                src={`https://www.youtube.com/embed/${video}`}
                frameBorder="0"
                allow="autoplay; encrypted-media;"
                allowFullScreen
                title={`${modelName} Demo Video`}
            ></iframe>
        </VideoWrapper>
    )
}

export default DemoVideo
