import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import SupportService from '../services/supportService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

let response: any = {}
export const submitSupport = createAsyncThunk(
    'submitSupport',
    async (supportRequest: any) => {
        response = await SupportService.submitSupport(supportRequest)
    }
)
// Then, handle actions in your reducers:
const submitSupportSlice = createSlice({
    name: 'submitSupport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitSupport.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(submitSupport.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                    else state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(submitSupport.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const supportAttachFile = createAsyncThunk(
    'support/supportAttachFile',
    async (payload: any) => {
        return await SupportService.supportAttachFile(payload)
    }
)

const supportAttachFileSlice = createSlice({
    name: 'supportAttachFile',
    initialState: {
        data: null,
        loading: LoadingState.Idle,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(supportAttachFile.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(supportAttachFile.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(supportAttachFile.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const submitSupportResult = submitSupportSlice.reducer

export const supportAttachFileResult = supportAttachFileSlice.reducer
