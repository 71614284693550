import { FunctionComponent } from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
    height: 30rem;
`

interface SpinnerContainerProps {
    variant?: string
}

const SpinnerContainer: FunctionComponent<SpinnerContainerProps> = ({
    variant,
}) => {
    return (
        <SpinnerDiv>
            <Spinner animation="border" variant={variant || 'dark'}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </SpinnerDiv>
    )
}

export default SpinnerContainer
