import { Dispatch, FunctionComponent } from 'react'
import { Alert } from 'react-bootstrap'
import { connect, ConnectedProps } from 'react-redux'
import { GeneralInfoPanel } from '../../../components/customMessage'
import ErrorPanel from '../../../components/errorPanel'
import { ModelSubType, ModelType } from '../../../models/modelType'
import { IAiModelCardProps } from '../../aiModelCard'
import ChatPanel from '../../panels/chatPanel'
import ImagePanel from '../../panels/imagePanel'
import RetrievalPanel from '../../panels/retrieval/retrievalPanel'
import SummarizationPanel from '../../panels/summarization/summarizationPanel'
import TranslationPanel from '../../panels/translation/translationPanel'

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({})

const mapStateToProps = (state: any) => {
    return {
        chatCompletionError: state.postChatCompletionResult.error,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type DemoTabProps = ReduxProps & {
    model: IAiModelCardProps
    isUserLoggedIn?: boolean
    currentPromptMessage?: string
    setCurrentPromptMessage?: any
}

const DemoTab: FunctionComponent<DemoTabProps> = ({
    model,
    isUserLoggedIn,
    currentPromptMessage,
    setCurrentPromptMessage,
    chatCompletionError,
}) => {
    const modelSubType = model?.tags?.['Subtype']
    const modelTypeValues = Object.values(ModelType)
    const modelType = modelTypeValues.find((item) =>
        model?.tags?.['Use Case'].includes(item)
    )

    const selectPanelContent = () => {
        if (modelSubType?.includes(ModelSubType.textToImage)) {
            return <ImagePanel model={model} />
        } else if (modelType?.includes(ModelType.Translation)) {
            return (
                <TranslationPanel
                    model={model}
                    setCurrentPromptMessage={setCurrentPromptMessage}
                />
            )
        } else if (modelType?.includes(ModelType.Retrieval)) {
            return <RetrievalPanel model={model} />
        } else if (modelType?.includes(ModelType.SummaryDoc)) {
            return <SummarizationPanel model={model} />
        }

        // textToText
        else
            return (
                <ChatPanel
                    model={model}
                    currentPromptMessage={currentPromptMessage}
                    setCurrentPromptMessage={setCurrentPromptMessage}
                />
            )
    }

    const renderErrorPanel = () => {
        return (
            <Alert
                style={{
                    width: '100%',
                    backgroundColor: 'rgb(193, 166, 242, 0.2)',
                    borderColor: 'rgb(193, 166, 242, 0.2)',
                    display: 'flex',
                    marginTop: '1rem',
                    justifyContent: 'center',
                    borderRadius: '0',
                }}
                variant="primary"
                show={chatCompletionError}
            >
                <ErrorPanel />
            </Alert>
        )
    }

    return (
        <>
            {renderErrorPanel()}
            {selectPanelContent()}
            <div>{!isUserLoggedIn ? GeneralInfoPanel : ''}</div>
        </>
    )
}

export default connector(DemoTab)
