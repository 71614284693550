import { Dispatch, FunctionComponent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import AiModelCard from './aiModelCard'

import { LoadingState } from '../models/loadingState'
import { getAIModels } from '../store/chatCompletion.slice'

import { connect } from 'react-redux'
import SearchGroupComponent from '../components/searchGroupComponent'
import {
    CardColumn,
    CardPanelContainer,
    ContainerHeadline,
} from '../containers/_styledContainers'
import SpinnerContainer from '../layouts/spinner'

import { ConnectedProps } from 'react-redux'

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => dispatch(getAIModels(qParam)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getAIModelsResultData: state.getAIModelsResult.data,

        getAIModelsLoading: state.getAIModelsResult.loading,
        getComposeSolutionLoading: state.getComposeSolutionResult.loading,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type CardLinkProps = ReduxProps & {
    title?: string
    description?: string
    queryType?: string | 'trending'
    isUserLoggedIn?: boolean
    count?: number | 50
    home?: boolean
}

const CardLinks: FunctionComponent<CardLinkProps> = ({
    title,
    home,
    description,
    queryType = 'trending',
    count = 50,
    getAIModels,
    getAIModelsResultData,
    getAIModelsLoading,
    getComposeSolutionLoading,
}: CardLinkProps) => {
    const [currentModels, setCurrentModels] = useState<any>([])

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const model = queryParameters.get('model')

    let isEnterpriseRagActive = false
    let isAllCatalogActive = false

    if (window.location.pathname === '/explore/enterprise-rag')
        isEnterpriseRagActive = true
    if (window.location.pathname === '/explore') isAllCatalogActive = true

    useEffect(() => {
        if (!currentModels || currentModels.length === 0) {
            let queryMap: any = {
                trending: 'trending=true',
                audio: 'use-case=audio',
                retrieval: 'use-case=retrieval',
                reasoning: 'use-case=reasoning&trending=true',
                summarization: 'use-case=summarization',
                translation: 'use-case=translation',
                visual: 'use-case=visual',
                solutionVector: 'compare=solution&compare=vectordb',
                deploy: 'compare=deploy&compare=llm&compare=vectordb',
                custom: 'compare=llm&compare=vectordb',
            }

            let query = queryMap[queryType] || queryMap['trending']

            // DEBUG: Hide this while testing w/ Dummy Data
            getAIModels(`?${query}&count=${count}`)
        }
    }, [])

    useEffect(() => {
        if (getAIModelsResultData && getAIModelsResultData.models) {
            let models = getAIModelsResultData.models
            setCurrentModels(models)
        }
    }, [getAIModelsResultData])

    //TODO: look into why this exists. Is it for single model viewing route?  Image, audio and chant model panels have a ?model Queryparam...
    const filterModels = (item: any) => {
        return model
            ? item.name == model
            : isEnterpriseRagActive && item.tags['Use Case']
            ? item?.tags['Use Case'].includes('enterprise rag')
            : true
    }

    const cards = () => {
        return (
            currentModels &&
            currentModels
                .filter((item: any) => filterModels(item))
                .map((item: any, index: any) => {
                    if (count && count <= index) return null

                    return (
                        <CardColumn key={`card-${index}`}>
                            <AiModelCard model={item} />
                        </CardColumn>
                    )
                })
        )
    }

    const renderCardPanelContiner = () => {
        //DEBUG: Hide this while testing w/ Dummy Data
        if (
            !getAIModelsResultData ||
            getAIModelsLoading === LoadingState.Pending ||
            getComposeSolutionLoading === LoadingState.Pending
        )
            return <SpinnerContainer />

        return <CardPanelContainer>{cards()}</CardPanelContainer>
    }

    return (
        <>
            {title && (
                <ContainerHeadline style={home ? { fontSize: '2.5rem' } : {}}>
                    <span>{title}</span>

                    {isAllCatalogActive && !isEnterpriseRagActive && (
                        <SearchGroupComponent />
                    )}
                </ContainerHeadline>
            )}

            {description && <p>{description}</p>}

            {renderCardPanelContiner()}
        </>
    )
}

export default connector(CardLinks)
