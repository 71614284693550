/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import { Dispatch, FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import CreateAccountPanel from '../../components/createAccountPanel/createAccountPanel'
import stringUtils from '../../lib/stringUtils'
import { AcceptInvitesRequest } from '../../models/acceptInvitesRequest'
import { AcceptInvitesResponse } from '../../models/acceptInvitesResponse'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { LoadingState } from '../../models/loadingState'
import { createOrg } from '../../store/createOrg.slice'
import { acceptInvites } from '../../store/invitation.slice'
import { CreateAccountContainerLogic } from './CreateAccountContainer.hook'

const FullPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    background-color: ${(props) => props.theme.color.background.defaultWhite};
`

const BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`

const ParentMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
`

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    height: 100%;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
    @media screen and (min-width: 501px) and (max-width: 900px) {
        width: 70%;
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => props.theme.align.mainContainerRowAlign};
    flex-grow: 1;
`

const CreateAccountBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultWhite};
    border-radius: 1rem;
    margin: 1rem;
    padding-bottom: 2rem;
`

const CreateAccountBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const CreateAccountPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
`

const HeaderContainer = styled.div`
    display: flex;
    padding-bottom: 2rem;
    justify-content: left;
`

const IntelLogoStyling = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
`

export interface ICreateAccountContainerProps {
    createOrg: typeof createOrg
    createOrgLoading: LoadingState
    createOrgResult: any
    createOrgError?: any

    acceptInvites: typeof acceptInvites
    acceptInvitesError: any
    acceptInvitesLoading: LoadingState
    acceptInvitesResult: AcceptInvitesResponse
}

const CreateAccountContainer: FunctionComponent<ICreateAccountContainerProps> & {
    defaultProps: Partial<ICreateAccountContainerProps>
} = ({
    createOrg,
    createOrgLoading,
    createOrgResult,
    createOrgError,
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
}: ICreateAccountContainerProps) => {
    const { onCreateOrgSubmit } = CreateAccountContainerLogic({
        createOrg,
    } as ICreateAccountContainerProps)

    let invitationKey = localStorage.getItem('invitationKey')
    let navigate = useNavigate()

    const homeURL = stringUtils.isNullOrUndefinedOrEmpty(invitationKey)
        ? '/'
        : `/?invitationKey=${invitationKey}`

    return (
        <FullPageContainer>
            <HeaderContainer>
                <IntelLogoStyling>
                    <img
                        src="/Intel_energyblue_logo.png"
                        height="65px"
                        width="171px"
                        alt="intel logo"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(homeURL)}
                    />
                </IntelLogoStyling>
            </HeaderContainer>
            <BodyContainer>
                <ParentMainContainer>
                    <MainContainer>
                        <ContentContainer>
                            <CreateAccountBodyContainer>
                                <CreateAccountBodyContainerReposition>
                                    <CreateAccountPanelWrapper>
                                        <CreateAccountPanel
                                            onCreateOrgSubmit={
                                                onCreateOrgSubmit
                                            }
                                            createOrgResponse={createOrgResult}
                                            createOrgLoading={createOrgLoading}
                                            createOrgError={createOrgError}
                                            invitationKey={invitationKey}
                                            acceptInvites={acceptInvites}
                                            acceptInvitesError={
                                                acceptInvitesError
                                            }
                                            acceptInvitesLoading={
                                                acceptInvitesLoading
                                            }
                                            acceptInvitesResult={
                                                acceptInvitesResult
                                            }
                                        />
                                    </CreateAccountPanelWrapper>
                                </CreateAccountBodyContainerReposition>
                            </CreateAccountBodyContainer>
                        </ContentContainer>
                    </MainContainer>
                </ParentMainContainer>
            </BodyContainer>
        </FullPageContainer>
    )
}

CreateAccountContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        createOrg: (createOrgRequest: CreateOrgRequest) =>
            dispatch(createOrg(createOrgRequest)),

        acceptInvites: (acceptInvitesRequest: AcceptInvitesRequest) =>
            dispatch(acceptInvites(acceptInvitesRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        createOrgError: state.createOrgResult.error,
        createOrgLoading: state.createOrgResult.loading,
        createOrgResult: state.createOrgResult.data,

        acceptInvitesError: state.acceptInvitesResult.error,
        acceptInvitesLoading: state.acceptInvitesResult.loading,
        acceptInvitesResult: state.acceptInvitesResult.data,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(CreateAccountContainer)
