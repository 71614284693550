import { FunctionComponent } from 'react'

const Rocket: FunctionComponent = ({}) => {
    return (
        <svg
            fill="none"
            height="153"
            viewBox="0 0 156 153"
            width="156"
            xmlns="http://www.w3.org/2000/svg"
        >
            <linearGradient
                id="a"
                gradientUnits="userSpaceOnUse"
                x1="42.67"
                x2="152.15"
                y1="112.29"
                y2="2.79962"
            >
                <stop offset="0" stopColor="#00c5fc" />
                <stop offset=".07" stopColor="#00bcf6" />
                <stop offset=".7" stopColor="#0279c9" />
                <stop offset="1" stopColor="#045fb8" />
            </linearGradient>
            <linearGradient
                id="b"
                gradientUnits="userSpaceOnUse"
                x1="45.9926"
                x2="32.1629"
                y1="116.004"
                y2="103.157"
            >
                <stop offset="0" stopColor="#00c5fc" stopOpacity="0" />
                <stop offset=".84" stopColor="#00c5fc" />
            </linearGradient>
            <linearGradient
                id="c"
                gradientUnits="userSpaceOnUse"
                x1="50.216"
                x2="63.3596"
                y1="119.952"
                y2="132.165"
            >
                <stop offset="0" stopColor="#00c5fc" stopOpacity="0" />
                <stop offset=".88" stopColor="#00c5fc" />
            </linearGradient>
            <linearGradient
                id="d"
                gradientUnits="userSpaceOnUse"
                x1="79.9248"
                x2="45.2216"
                y1="72.7681"
                y2="110.113"
            >
                <stop offset=".02" stopColor="#001e50" />
                <stop offset="1" stopColor="#001e50" stopOpacity="0" />
            </linearGradient>
            <mask
                id="e"
                height="32"
                maskUnits="userSpaceOnUse"
                width="33"
                x="104"
                y="18"
            >
                <path
                    d="m120.44 49.9994c8.726 0 15.8-7.0739 15.8-15.8s-7.074-15.8-15.8-15.8-15.8 7.0739-15.8 15.8 7.074 15.8 15.8 15.8z"
                    fill="#fff"
                />
            </mask>
            <path
                d="m97.92 143.21h-20.23l-8.95-27.18-9.71 10.35-2.56-2.4 13.76-14.68 10.01 30.39h14.17v-37.59l55.9-55.2104c1.06-1.05 1.67-2.5 1.67-3.99v-32.69c0-3.49999-2.85-6.34999-6.35-6.34999h-32.49c-1.43 0-2.78.55-3.8 1.55l-57.03 55.66999h-37.57v14.92l28 10.32-12.47 13.4-2.57-2.4 8.87-9.53-25.35-9.34v-20.88h39.65l56.01-54.66999c1.68-1.64 3.9-2.550001 6.25-2.550001h32.49c5.44 0 9.86 4.420001 9.86 9.859991v32.69c0 2.42-.99 4.79-2.71 6.49l-54.85 54.1804v39.63z"
                fill="url(#a)"
            />
            <path
                d="m42.7013 109.016-3.9414 4.242 4.2414 3.941 3.9414-4.241z"
                fill="#001e50"
            />
            <path
                d="m28.0699 96.9117-2.4161 2.601 13.8839 12.8973 2.4162-2.601z"
                fill="url(#b)"
            />
            <path
                d="m46.1824 113.773-2.4165 2.601 13.1857 12.253 2.4166-2.601z"
                fill="url(#c)"
            />
            <path
                d="m4.31001 152.4-3.55-.05c.31-20.68 4.91-34.54 13.66999-41.2 7.49-5.69 15.14-3.79 15.46-3.7l-.89 3.43c-.26-.06-6.4-1.54-12.49 3.14-7.69999 5.91-11.91999 19.18-12.20999 38.38z"
                fill="#001e50"
            />
            <path
                d="m5.26998 152.52c-1.2 0-2.42-.021-3.67-.051l.09-3.55c19.19002.51 32.63002-3.149 38.86002-10.599 4.93-5.89 3.72-12.091 3.66-12.351l3.47-.74c.07.32 1.66 8.051-4.35 15.291-6.6 7.959-19.4 11.989-38.07002 11.989z"
                fill="#001e50"
            />
            <path
                d="m11.51 133.069v-.1c.75-9.08 8.77-16.04 17.92-15.29l-.14 1.77c-8.18-.66-15.34 5.55-16.01 13.6z"
                fill="#030c26"
                opacity=".4"
            />
            <path
                d="m121.5 51.1596c-9.54 0-17.3-7.76-17.3-17.3s7.76-17.3 17.3-17.3 17.3 7.76 17.3 17.3-7.76 17.3-17.3 17.3zm0-31.61c-7.89 0-14.3 6.42-14.3 14.3s6.42 14.3 14.3 14.3 14.3-6.42 14.3-14.3-6.42-14.3-14.3-14.3z"
                fill="#001e50"
            />
            <path
                d="m78.7152 71.8852-34.6966 37.3508 2.6009 2.416 34.6966-37.3507z"
                fill="url(#d)"
            />
            <g fill="#030c26" mask="url(#e)">
                <path d="m122.29 30.2393h-15.58v1.77h15.58z" opacity=".4" />
                <path
                    d="m127.24 36.7895h-6.89v-1.77h6.89zm-11.37 0h-26.87v-1.77h26.87z"
                    opacity=".4"
                />
            </g>
        </svg>
    )
}

export default Rocket
