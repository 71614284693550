// Brand
export const navyBrand = '#0068B5'
export const blueBrand = '#00A3F6'
export const brightBlueBrand = '#00C7FD'
export const darkNavyBrand = '#183544'

// New Colors
export const lipstick = '#d7252c'

export const white = '#ffffff'
export const softBlue = '#4da1ff'
export const paleGrey = '#f6f9fc'
export const strokesGrey = '#d8e3e7'
export const bodyGrey = '#536676'
export const timestampGrey = '#7E969F'
export const commsBlue = '#dff4ff'

// notification/ error/ success
export const blueInfo = '#4DA1FF'
export const yellowWarning = '#FF8A04'
export const redError = '#e0182d'
export const greenSuccess = '#39B54A'
export const yellow = '#ffc029'
