/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { signOut as SignOut } from 'aws-amplify/auth'
import {
    Dispatch,
    FunctionComponent,
    ReactNode,
    useEffect,
    useState,
} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getAIModels } from '../store/chatCompletion.slice'

import { Cache } from '@aws-amplify/cache'
import { useNavigate } from 'react-router-dom'
import { clearAllItemsFromStorage } from '../lib/encryptStorage'

import { Alert } from 'react-bootstrap'
import ErrorPanel from '../components/errorPanel'
import {
    HomeSection,
    MainSection,
    SidePanelComponent,
} from '../containers/_styledContainers'
import AiModelFooterPanel from '../layouts/footer'
import AiModelHeaderPanel from '../layouts/header'
import SideLinkPanel from '../layouts/sidePanel'

const PrimaryContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
    overflow-y: auto;
    overflow-x: hidden;

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const HorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    background-color: #fff;
    margin-top: 40px;
    @media screen and (min-width: 768px) {
        margin-top: 72px;
    }
`

const BodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    width: 100%;
    overflow-y: auto;
`

interface ContainerWrapperProps {
    children?: ReactNode | ((isUserLoggedIn: boolean) => ReactNode)
    home?: boolean
    compose?: boolean
    hideSidePanel?: boolean
    getAIModelsError: any
}

const signOut = async () => {
    try {
        Cache.clear()
        await SignOut()
    } catch (error) {
        console.log('error signing out: ', error)
    }
}

const ContainerWrapper: FunctionComponent<ContainerWrapperProps> = ({
    children,
    home,
    compose,
    hideSidePanel,
    getAIModelsError,
}: ContainerWrapperProps) => {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    let navigate = useNavigate()

    useEffect(() => {
        if (location.pathname.includes('logOut')) {
            signOut()
            clearAllItemsFromStorage()
        }
    }, [location.pathname])

    useEffect(() => {
        if (window.location.pathname.indexOf('login/callback') > -1) {
            navigate('/')
        }
        if (!home) getAIModels('?trending=true&count=50')
    }, [])

    const renderSideLinkPanel = () => {
        return <SideLinkPanel isUserLoggedIn={isUserLoggedIn}></SideLinkPanel>
    }

    const renderMainSection = () => {
        const SectionWrapper = home ? HomeSection : MainSection

        const section = (
            <SectionWrapper>
                {typeof children === 'function'
                    ? children(isUserLoggedIn)
                    : children}
            </SectionWrapper>
        )

        return section
    }

    return (
        <PrimaryContainer>
            <AiModelHeaderPanel
                isUserLoggedIn={isUserLoggedIn}
                setIsUserLoggedIn={setIsUserLoggedIn}
            ></AiModelHeaderPanel>

            <HorizontalContainer>
                {!hideSidePanel && (
                    <SidePanelComponent>
                        {renderSideLinkPanel()}
                    </SidePanelComponent>
                )}

                <BodyContainer>
                    {getAIModelsError && (
                        <Alert
                            variant="primary"
                            show={getAIModelsError ? true : false}
                            style={{
                                width: '100%',
                                backgroundColor: 'rgb(193, 166, 242, 0.2)',
                                borderColor: 'rgb(193, 166, 242, 0.2)',
                                display: 'flex',
                                marginTop: '1rem',
                                justifyContent: 'center',
                                borderRadius: '0',
                            }}
                        >
                            <ErrorPanel />
                        </Alert>
                    )}

                    {renderMainSection()}
                </BodyContainer>
            </HorizontalContainer>

            <AiModelFooterPanel></AiModelFooterPanel>
        </PrimaryContainer>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({})

const mapStateToProps = (state: any) => {
    return {
        getAIModelsError: state.getAIModelsResult.error,
        getAIModelsLoading: state.getAIModelsResult.loading,
        getComposeSolutionLoading: state.getComposeSolutionResult.loading,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(ContainerWrapper)
