import { lighten, rgba } from 'polished'
import {
    IColor,
    IColorBackground,
    IColorBorder,
    IColorButton,
    IColorIndicators,
    IColorText,
} from '../color'
import {
    blueBrand,
    blueInfo,
    brightBlueBrand,
    commsBlue,
    darkNavyBrand,
    greenSuccess,
    navyBrand,
    paleGrey,
    redError,
    strokesGrey,
    timestampGrey,
    white,
    yellow,
    yellowWarning,
} from './palette'

const button: IColorButton = {
    background: {
        default: {
            primary: yellow,
            link: 'transparent',
            action: greenSuccess,
        },
        hover: {
            primary: 'green',
            link: blueBrand,
            action: lighten(0.1, greenSuccess),
        },
        disabled: {
            primary: timestampGrey,
            link: 'transparent',
            action: lighten(0.2, greenSuccess),
        },
        selected: {
            primary: white,
            link: blueBrand,
            action: white,
        },
    },

    disabled: timestampGrey,

    text: {
        default: {
            primary: white,
            link: blueBrand,
            action: white,
        },
        hover: {
            primary: white,
            link: white,
            action: white,
        },
        disabled: {
            primary: white,
            link: lighten(0.2, blueBrand),
            action: white,
        },
        selected: {
            primary: navyBrand,
            link: white,
            action: white,
        },
    },
    border: {
        default: {
            primary: navyBrand,
            link: 'transparent',
            action: greenSuccess,
        },
        hover: {
            primary: blueBrand,
            link: blueBrand,
            action: lighten(0.1, greenSuccess),
        },
        disabled: {
            primary: timestampGrey,
            link: 'transparent',
            action: lighten(0.2, greenSuccess),
        },
        selected: {
            primary: navyBrand,
            link: 'transparent',
            action: greenSuccess,
        },
    },
}

export const text: IColorText = {
    default: '#36454F',
    heading: '#7b7878',
    value: '#36454F',
    success: greenSuccess,
    blue: blueBrand,
    navy: navyBrand,
    darkNavy: darkNavyBrand,
    brightBlue: brightBlueBrand,
}

export const border: IColorBorder = {
    default: strokesGrey,
}

export const background: IColorBackground = {
    defaultGrey: '#e5e5e5',
    defaultWhite: '#ffffff',
    defaultBeige: '#fff8dc',
    label: {
        title: '#ffffff',
    },
    dropdown: {
        active: blueBrand,
        hover: commsBlue,
        hoverActive: blueBrand,
    },
    table: {
        header: paleGrey,
        selectedRow: rgba(commsBlue, 0.5),
    },
}

const indicators: IColorIndicators = {
    notifications: {
        info: blueInfo,
        warning: yellowWarning,
        error: redError,
        default: blueBrand,
    },
}

export const color: IColor = {
    button,
    text,
    border,
    background,
    indicators,
}
