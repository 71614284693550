/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import {
    Dispatch,
    FormEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { LoadingState } from '../models/loadingState'

import { DndContext, DragEndEvent } from '@dnd-kit/core'
import 'bootstrap/dist/css/bootstrap.min.css'
import { EmblaOptionsType } from 'embla-carousel'
import * as _ from 'radash'
import { Alert, Form, Tab, Tabs } from 'react-bootstrap'
import { CodeBlock, dracula } from 'react-code-blocks'
import { Column, IElement } from '../components/drag-drop-comp/Column'
import '../components/emblaCarousel/css/embla.css'
import EmblaCarousel from '../components/emblaCarousel/EmblaCarousel'
import AiModelCard from '../layouts/aiModelCardV1'
import { setScrollIntoViewWithId } from '../lib/scroll'
import { LayoutStylePreference } from '../models/layoutStyle'
import { libType, ModelSubType } from '../models/modelType'
import { getAIModels } from '../store/chatCompletion.slice'
import { getComposeSolution } from '../store/composeGeneration.slice'

import { Cache } from '@aws-amplify/cache'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import ErrorPanel from '../components/errorPanel'
import ContainerWrapper from '../layouts/containerWrapper'
import SpinnerContainer from '../layouts/spinner'

const CreateButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: #8f5da2;
    margin: 0 1.5rem;
    border: none;
    min-width: 10rem;
    height: 2rem;
    border-radius: 0;
    border: none;
    &:disabled {
        opacity: 0.1;
        --tw-text-opacity: 1;
    }
`

const ViewPortControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    //margin-top: 20px;
    border-top: solid 1px;
    border-color: rgb(101, 49, 113);
    //border: 1px solid rgb(101, 49, 113);
    min-height: 15rem;
    margin: 1.2rem 2rem;
`

const ButtonTab = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(44, 44, 44);
    border-radius: 0px;
    border: none;
    color: rgb(224, 224, 224);
    font-family: 'IntelOne Display';
    font-size: 0.8rem;
    font-weight: 500;
    padding: 3px 12px;
    text-transform: capitalize;
    margin-right: 0.5rem;
    &:hover {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &:focus {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &.active {
        border-bottom: 4px solid #61dafb; //4px solid rgb(84, 255, 46);
        font-weight: 500;
    }
`
const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
`

const StyledTabLabel = styled.div`
    min-width: 100px;
    max-width: 150px;
    padding: 0.5rem;
    margin: 0 0.1rem;
    text-align: center;

    line-height: 18px; /* 112.5% */
    border-radius: 6px 6px 0px 0px;
    box-shadow: 1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        -1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        0px 1px 0px 0px rgba(43, 44, 48, 0.3) inset;
`
const MainWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #fff;
    padding-top: 40;
    padding-bottom: 40;
    font-family: 'IntelOne Display';
    height: auto;
`
const ValidationMessage = styled.span`
    display: flex;
    font-size: 1rem;
    color: red;
    padding: 1rem;
`
const GPUCountContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    background-image: url('/aiModelImage/gaudi.png');
    background-size: 100%;
    background-position: top center;
    height: 8.5rem;
    background-repeat: no-repeat;
    padding-top: 1.5rem;
    margin-left: 5%;
    margin-right: 5%;

    @media screen and (min-width: 800px) {
        height: 8.5rem;
    }
    @media screen and (min-width: 1200px) {
        height: 9.5rem;
    }
    @media screen and (min-width: 1600px) {
        height: 10.5remrem;
    }
    @media screen and (min-width: 2000px) {
        height: 13rem;
    }
    @media screen and (min-width: 2500px) {
        height: 16rem;
    }
    @media screen and (min-width: 3000px) {
        height: 22rem;
    }
    @media screen and (min-width: 4000px) {
        height: 30rem;
    }
`

const COLUMNS = ['available', 'current']
export const DEFAULT_COLUMN = 'available'

export interface IComposeContainerProps {
    getAIModels: typeof getAIModels
    getAIModelsResultData: any
    getAIModelsLoading: LoadingState
    getComposeSolution: typeof getComposeSolution
    getComposeSolutionResult: any
    getComposeSolutionLoading: LoadingState
    getComposeSolutionError: any
}

const ComposeContainer: FunctionComponent<IComposeContainerProps> & {
    defaultProps: Partial<IComposeContainerProps>
} = ({
    getAIModels,
    getAIModelsResultData,
    getAIModelsLoading,
    getComposeSolution,
    getComposeSolutionResult,
    getComposeSolutionLoading,
    getComposeSolutionError,
}: IComposeContainerProps) => {
    let navigate = useNavigate()

    const getRefreshToken = useCallback(async () => {
        try {
            const response = await fetchAuthSession()
            const refresh_token = response?.tokens?.accessToken
            if (!refresh_token) {
                Cache.clear()
                navigate('/signIn')
            }
        } catch (err) {
            console.error(err)
            Cache.clear()
            navigate('/signIn')
        }
    }, [])

    useEffect(() => {
        getRefreshToken()
    }, [])

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const solution = queryParameters.get('solution')
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const [compareLLMModelsList, setCompareLLMModelsList] = useState<any>()

    const [selectedSolutionName, setSelectedSolutionName] = useState<
        string | undefined
    >(undefined)

    const [selectedLLMModelName, setSelectedLLMModelName] = useState<
        string | undefined
    >(undefined)

    const [selectedGaudiCards, setSelectedGaudiCards] = useState<number>(1)

    const [showComposeSolution, setShowComposeSolution] =
        useState<boolean>(false)
    const [clickedLLMCards, setClickedLLMCards] = useState<string[]>([])
    const [currentTab, setCurrentTab] = useState<string>('solution')

    const [compareVectorModelsList, setCompareVectorModelsList] =
        useState<any>()

    const [selectedVectorDBModelName, setSelectedVectorDBModelName] = useState<
        string | undefined
    >(undefined)
    const DefaultDraggableData: IElement[] = []
    const [availableLlmsData, setAvailableLlmsData] =
        useState<IElement[]>(DefaultDraggableData)
    const [hasReplacebleLlms, setHasReplacebleLlms] = useState<boolean>(false)
    const [hasReplacebleVectorDb, setHasReplacebleVectorDb] =
        useState<boolean>(false)
    const [isSelectedLlmAndVector, setIsSelectedLlmAndVector] =
        useState<boolean>(true)

    const gaudiCardsRef = useRef(selectedGaudiCards)
    gaudiCardsRef.current = selectedGaudiCards

    const llmModelsRef = useRef(compareLLMModelsList)
    llmModelsRef.current = compareLLMModelsList

    const clickedLLMCardsRef = useRef(clickedLLMCards)
    clickedLLMCardsRef.current = clickedLLMCards

    const currentTabRef = useRef(currentTab)
    currentTabRef.current = currentTab

    const inputGaudiCardsRef = useRef<HTMLInputElement>(null)
    const solutionQueryParam =
        '?compare=deploy&compare=llm&compare=vectordb&count=50'

    const getData = (queryParam: string) => {
        getAIModels(queryParam)
    }

    useEffect(() => {
        if (inputGaudiCardsRef.current) {
            inputGaudiCardsRef.current.focus()
        }
        getData(solutionQueryParam)
    }, [])

    useEffect(() => {
        if (getAIModelsResultData && getAIModelsResultData.models) {
            setShowComposeSolution(false)
            setSelectedLLMModelName(undefined)
            setSelectedVectorDBModelName(undefined)
            setClickedLLMCards([])
            setAvailableLlmsData([])

            const modelList = getAIModelsResultData.models.map((item: any) => {
                return {
                    ...item,
                    isChecked: false,
                    isRagModel: item.name.startsWith('rag-') ? true : false,
                    isCompatible:
                        selectedGaudiCards >= Number(item.tags['GaudiCards']),
                }
            })

            if (currentTab == 'solution') {
                setCompareLLMModelsList(
                    modelList.filter((item: any) => {
                        if (
                            item?.tags?.['Compare'] &&
                            item?.tags?.['Compare']
                        ) {
                            return (
                                Number(item.tags['GaudiCards']) >= 0 &&
                                item.tags['Compare'].includes('deploy')
                            )
                        }
                    })
                )

                const elementsList = modelList
                    .filter((item: any) => {
                        if (
                            item?.tags?.['Compare'] &&
                            item?.tags?.['Compare']
                        ) {
                            return (
                                (Number(item.tags['GaudiCards']) >= 0 &&
                                    item.tags['Compare'].includes('llm') &&
                                    gaudiCardsRef.current >=
                                        Number(item.tags['GaudiCards'])) ||
                                item.tags['Compare'].includes('vectordb')
                            )
                        }
                    })
                    .map((item: any) => ({
                        id: item.uuid,
                        name: item.name,
                        displayName: item.displayName,
                        content: item.displayName,
                        column:
                            selectedLLMModelName &&
                            selectedLLMModelName?.indexOf(item.name) > -1
                                ? 'current'
                                : DEFAULT_COLUMN,
                        type: item?.tags?.['Compare'].includes('vectordb')
                            ? libType.VectorDb
                            : libType.Llm,
                    }))
                    .sort((a: any, b: any) =>
                        (a.type ? a.type : '') > (b.type ? b.type : '') ? 1 : -1
                    )
                setAvailableLlmsData(elementsList)

                if (solution) {
                    setSelectedLLMModelName(solution)
                    setClickedLLMCards((prev: string[]) => {
                        if (prev.includes(solution)) {
                            return prev.filter((i) => i != solution)
                        } else {
                            return [...prev, solution]
                        }
                    })

                    setCompareLLMModelsList((prev: any) =>
                        prev.map((model: any) => {
                            if (solution === model.name) {
                                model.isChecked = !model.isChecked
                                setHasReplacebleLlms(
                                    model.tags['Composable']?.includes('llm')
                                )

                                setAvailableLlmsData((prev) =>
                                    prev.map((item: any) => ({
                                        id: item.id,
                                        name: item.name,
                                        displayName: item.displayName,
                                        content: item.content,
                                        column:
                                            (model.name &&
                                                model.name?.indexOf(item.name) >
                                                    -1) ||
                                            (item.displayName == 'Redis' &&
                                                model.name?.indexOf(
                                                    'neural-chat'
                                                ) > -1)
                                                ? 'current'
                                                : DEFAULT_COLUMN,
                                        type: item.type,
                                        visible:
                                            (item.type == libType.VectorDb &&
                                                model.tags[
                                                    'Composable'
                                                ]?.includes('vectordb')) ||
                                            (item.type == libType.Llm &&
                                                model.tags[
                                                    'Composable'
                                                ]?.includes('llm'))
                                                ? true
                                                : false,
                                    }))
                                )
                            }
                            return model
                        })
                    )
                }
            } else {
                setCompareLLMModelsList(
                    modelList.filter(
                        (item: any) => Number(item.tags['GaudiCards']) >= 0
                    )
                )
                setCompareVectorModelsList(
                    modelList.filter(
                        (item: any) => item.tags['Compare'] == 'vectordb'
                    )
                )
            }
        }
    }, [
        getAIModelsResultData,
        currentTab,
        selectedGaudiCards,
        setCompareLLMModelsList,
        setAvailableLlmsData,
    ])

    const onCardClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            modelName: string,
            displayName: string,
            subType: any,
            gaudiCards: number,
            index: number
        ) => {
            setShowComposeSolution(false)
            setIsSelectedLlmAndVector(true)
            if (
                subType['Compare'] &&
                subType['Compare'] == ModelSubType.vectorDb
            ) {
                setSelectedVectorDBModelName(displayName)

                setCompareVectorModelsList((prev: any) =>
                    prev.map((item: any) => {
                        if (modelName === item.name) {
                            item.isChecked = !item.isChecked
                        } else {
                            item.isChecked = false
                        }
                        return item
                    })
                )
            } else if (currentTabRef.current == 'customize') {
                setSelectedLLMModelName(modelName)

                setCompareLLMModelsList((prev: any) =>
                    prev.map((item: any) => {
                        if (modelName === item.name) {
                            item.isChecked = !item.isChecked
                        } else {
                            item.isChecked = false
                        }
                        return item
                    })
                )
            } else if (gaudiCardsRef.current >= gaudiCards) {
                let isComposableLLM = false
                let isComposableVector = false
                setSelectedLLMModelName(modelName)
                setClickedLLMCards((prev: string[]) => {
                    if (prev.includes(modelName)) {
                        return prev.filter((i) => i != modelName)
                    } else {
                        return [...prev, modelName]
                    }
                })

                setCompareLLMModelsList((prev: any) =>
                    prev.map((item: any) => {
                        if (modelName === item.name) {
                            item.isChecked = !item.isChecked
                            isComposableLLM = item.tags['Composable']?.includes(
                                'llm'
                            )
                                ? true
                                : false
                            setHasReplacebleLlms(isComposableLLM)
                            isComposableVector = item.tags[
                                'Composable'
                            ]?.includes('vectordb')
                                ? true
                                : false

                            setHasReplacebleVectorDb(isComposableVector)
                        } else {
                            item.isChecked = false
                        }
                        return item
                    })
                )
                setAvailableLlmsData((prev) =>
                    prev.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                        displayName: item.displayName,
                        content: item.content,
                        column:
                            (modelName && modelName?.indexOf(item.name) > -1) ||
                            (item.displayName == 'Redis' &&
                                modelName?.indexOf('neural-chat') > -1)
                                ? 'current'
                                : DEFAULT_COLUMN,
                        type: item.type,
                        visible:
                            (item.type == libType.VectorDb &&
                                isComposableVector) ||
                            (item.type == libType.Llm && isComposableLLM)
                                ? true
                                : false,
                    }))
                )
            }
        },
        [
            setCompareLLMModelsList,
            compareLLMModelsList,
            selectedGaudiCards,
            setClickedLLMCards,
            clickedLLMCards,
            currentTab,
        ]
    )

    useEffect(() => {
        if (
            availableLlmsData &&
            selectedLLMModelName &&
            availableLlmsData.length > 0
        ) {
            setScrollIntoViewWithId('scrollUpToGetButton')
        }
    }, [availableLlmsData])

    useEffect(() => {
        if (showComposeSolution) {
            setScrollIntoViewWithId('scrollUpToId')
        }
    }, [showComposeSolution])

    useEffect(() => {
        setCompareLLMModelsList([])

        if (currentTab == 'customize') {
            setClickedLLMCards([])
            getData('?compare=llm&compare=vectordb&count=50')
        }

        if (currentTab == 'solution') {
            getData(solutionQueryParam)
        }
    }, [currentTab])

    const handleCreateClick = () => {
        var queryArg = new URLSearchParams()

        if (currentTab == 'solution') {
            if (
                hasReplacebleLlms &&
                availableLlmsData &&
                availableLlmsData.length > 0
            ) {
                availableLlmsData
                    .filter(
                        (item: any) => item.visible && item.column === 'current'
                    )
                    .map((item: any) => {
                        if (item.type == libType.Llm)
                            queryArg.append('llm', item.name ?? '')
                        else if (item.type == libType.VectorDb)
                            queryArg.append('vector-db', item.displayName ?? '')
                    })
                const selectedSolution = compareLLMModelsList.filter(
                    (item: any) => item.isChecked == true
                )
                if (
                    selectedSolution &&
                    selectedSolution.length > 0 &&
                    selectedSolution[0].tags['Solution']
                )
                    queryArg.append(
                        'solution',
                        selectedSolution[0].tags['Solution']
                            ? selectedSolution[0].tags['Solution'][0]
                            : ''
                    )

                getComposeSolution(
                    `/custom?${queryArg}&number-of-gaudis=${selectedGaudiCards}`
                )
            } else {
                for (let model of clickedLLMCards) {
                    queryArg.append('solution', model)
                }
                getComposeSolution(
                    `?${queryArg}&&number-of-gaudis=${selectedGaudiCards}`
                )
            }
        }

        if (currentTab == 'customize') {
            queryArg.append('llm', selectedLLMModelName ?? '')
            queryArg.append('vector-db', selectedVectorDBModelName ?? '')
            queryArg.append('number-of-gaudis', selectedGaudiCards.toString())

            getComposeSolution(`/custom?${queryArg}`)
        }
    }

    useEffect(() => {
        if (getComposeSolutionResult || getComposeSolutionError) {
            setShowComposeSolution(true)
        }
    }, [getComposeSolutionResult, getComposeSolutionError])

    const handleDownloadClick = useCallback(() => {
        if (getComposeSolutionResult) {
            let blob = new Blob(['\uFEFF' + getComposeSolutionResult], {
                type: 'text/yaml;charset=utf-18',
            })

            let url = window.URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'compose.yaml')
            document.body.appendChild(link)
            link.click()
            link.remove()
        }
    }, [getComposeSolutionResult])

    const OPTIONS: EmblaOptionsType = { slidesToScroll: 'auto' }

    const LLM_SLIDES = (
        <div>
            {llmModelsRef.current &&
                llmModelsRef.current.map((item: any, index: number) => {
                    item.isDeployView = true
                    item.solution = selectedSolutionName + '-llm'
                    item.isActive =
                        gaudiCardsRef.current >= Number(item.tags['GaudiCards'])
                    item.showRadioOptionPanel =
                        currentTab == 'customize' || currentTab == 'solution'
                    return (
                        <div key={`card-${index}`}>
                            {/* <AiModelCard model={item} /> */}
                            <AiModelCard
                                imageURL={item.logo}
                                uuid={item.uuid}
                                modelName={item.name}
                                displayName={item.displayName}
                                publishedBy={item.author}
                                description={item.description}
                                onCardClickCallBack={(e: any) => {
                                    onCardClick(
                                        e,
                                        item.name,
                                        item.displayName,
                                        item?.tags,
                                        Number(item.tags['GaudiCards']),
                                        index
                                    )
                                }}
                                category={item?.tags['Feature']}
                                renderStyle={LayoutStylePreference.vStyle}
                                isActive={
                                    gaudiCardsRef.current >=
                                    Number(item.tags['GaudiCards'])
                                } //disable if gaudi cards aren't enough
                                // showCheckboxPanel={false}
                                showRadioOptionPanel={
                                    currentTab == 'customize' ||
                                    currentTab == 'solution'
                                }
                                isDeployView={true}
                                isChecked={item.isChecked}
                                index={index}
                                solution={selectedSolutionName + '-llm'}
                            />
                        </div>
                    )
                })}
        </div>
    )

    const llmSlidesRef = useRef(LLM_SLIDES)
    llmSlidesRef.current = LLM_SLIDES

    const selectSolutionSection = (
        <GPUCountContainer>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '2%',
                    color: 'white',
                }}
            >
                <h6>Gaudi® 2 Cards:</h6>
                <Form.Control
                    id="input"
                    name="input"
                    type="number"
                    onChange={(event: any) => {
                        setSelectedGaudiCards(event.target.value)
                    }}
                    min={1}
                    max={8}
                    defaultValue={1}
                    ref={inputGaudiCardsRef}
                />
            </div>
        </GPUCountContainer>
    )

    const selectLLMSection = (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '6%',
                    marginTop: '1.6rem',
                }}
            >
                <h6>
                    {currentTab == 'solution'
                        ? 'Choose a Solution:'
                        : 'Select LLM model:'}
                </h6>{' '}
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                {llmModelsRef.current && llmModelsRef.current.length > 0 ? (
                    <EmblaCarousel
                        slides={llmSlidesRef.current}
                        options={OPTIONS}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    )

    const VectorDB_SLIDES = (
        <div>
            {compareVectorModelsList &&
                compareVectorModelsList.map((item: any, index: number) => {
                    item.isDeployView = true
                    item.solution = selectedSolutionName + '-vectordb'
                    item.isActive = true
                    item.showRadioOptionPanel = true

                    return (
                        <div key={`card-${index}`}>
                            {/* <AiModelCard model={item} /> */}
                            <AiModelCard
                                imageURL={item.logo}
                                uuid={item.uuid}
                                modelName={item.name}
                                displayName={item.displayName}
                                publishedBy={item.author}
                                description={item.description}
                                onCardClickCallBack={(e: any) =>
                                    onCardClick(
                                        e,
                                        item.name,
                                        item.displayName,
                                        item?.tags,
                                        Number(item.tags['GaudiCards']),
                                        index
                                    )
                                }
                                category={item?.tags['Feature']}
                                renderStyle={LayoutStylePreference.vStyle}
                                isActive={true} //true always for vector db
                                showRadioOptionPanel={true}
                                isChecked={item.isChecked}
                                isDeployView={true}
                                index={index}
                                solution={selectedSolutionName + '-vectordb'}
                                poweredBy={item?.tags['PoweredBy']}
                            />
                        </div>
                    )
                })}
        </div>
    )
    const selectVectorDBSection = (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '6%',
                }}
            >
                <h6>Select vector db:</h6>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left',
                }}
            >
                {compareVectorModelsList &&
                compareVectorModelsList.length > 0 ? (
                    <EmblaCarousel slides={VectorDB_SLIDES} options={OPTIONS} />
                ) : (
                    ''
                )}
            </div>
        </div>
    )

    const handleOnDragEnd = useCallback(
        ({ active, over }: DragEndEvent) => {
            const elementId = active.id
            let activeElementType = libType.Llm

            availableLlmsData.map((elm: any) => {
                if (elm.id === elementId) {
                    activeElementType = elm.type
                }
            })
            const nonActiveTypeElements = availableLlmsData.filter(
                (item) => item.type !== activeElementType
            )

            const updatedState = availableLlmsData
                .filter((item) => item.type === activeElementType)
                .map((elm): IElement => {
                    if (elm.id === elementId) {
                        activeElementType = elm.type ? elm.type : libType.Llm
                        //const column = 'available' //over?.id ? String(over.id) : elm.column
                        const column =
                            elm.column == 'current' ? 'available' : 'current'
                        elm.column = column
                    } else {
                        elm.column = 'available'
                    }
                    return elm
                })
            nonActiveTypeElements.map((item) => {
                updatedState.push(item)
            })
            const currentItems = updatedState.filter(
                (item: any) => item.column == 'current'
            )
            setAvailableLlmsData(updatedState)

            if (currentItems && currentItems.length >= 2) {
                setIsSelectedLlmAndVector(true)
            } else setIsSelectedLlmAndVector(false)
        },
        [availableLlmsData]
    )

    const bodyContent = (isUserLoggedIn: boolean) => {
        return (
            <>
                <div style={{ marginBottom: '1rem' }}>
                    {selectSolutionSection}
                </div>
                <div
                    style={{
                        paddingLeft: '5%',
                        marginRight: '5%',
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        width: '100%',
                    }}
                >
                    <Tabs
                        variant="tabs"
                        id="tabs"
                        className="mb-3"
                        style={{
                            paddingTop: '1rem',
                            borderBottom: '2px solid rgb(101, 49, 113)',
                        }}
                        defaultActiveKey={currentTab}
                        onSelect={(eventKey: any) => {
                            setCurrentTab(eventKey)
                        }}
                    >
                        <Tab
                            style={{
                                color: 'var(--bs-heading-color)',
                            }}
                            eventKey="solution"
                            title={
                                <StyledTabLabel tabIndex={0}>
                                    Solution
                                </StyledTabLabel>
                            }
                        >
                            {getAIModelsLoading !== LoadingState.Pending ? (
                                <div>{selectLLMSection}</div>
                            ) : (
                                <SpinnerContainer />
                            )}
                        </Tab>
                        <Tab
                            style={{
                                color: 'var(--bs-heading-color)',
                            }}
                            eventKey="customize"
                            title={
                                <StyledTabLabel tabIndex={0}>
                                    Customize
                                </StyledTabLabel>
                            }
                        >
                            {getAIModelsLoading !== LoadingState.Pending ? (
                                <>
                                    {' '}
                                    <div>{selectLLMSection}</div>
                                    <div
                                        style={{
                                            margin: '1rem 3rem',
                                            borderTop:
                                                '1px solid rgb(143, 93, 162)',
                                        }}
                                    ></div>
                                    <div> {selectVectorDBSection} </div>
                                </>
                            ) : (
                                <SpinnerContainer />
                            )}
                        </Tab>
                    </Tabs>
                </div>
                {currentTab == 'solution' &&
                clickedLLMCards.length > 0 &&
                hasReplacebleLlms ? (
                    <>
                        <div
                            style={{
                                margin: '1rem 3rem',
                                borderTop: '1px solid rgb(143, 93, 162)',
                            }}
                        ></div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingBottom: '0.5rem',
                            }}
                        >
                            <h6>
                                To replace the current default selected LLM or
                                Vector DB, please drag & drop the item from
                                available list to current list.{' '}
                            </h6>{' '}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {/* draggable component */}

                            <DndContext onDragEnd={handleOnDragEnd}>
                                <MainWrapper>
                                    {COLUMNS.map((column, columnIndex) => (
                                        <Column
                                            key={`column-${columnIndex}`}
                                            heading={column}
                                            elements={_.select(
                                                availableLlmsData,
                                                (elm) => elm,
                                                (elm) =>
                                                    elm.column ===
                                                    _.camel(column)
                                            )}
                                            subHeading={
                                                column == 'available'
                                                    ? ' replace list'
                                                    : 'default LLM and Vector DB'
                                            }
                                        />
                                    ))}
                                </MainWrapper>
                            </DndContext>
                            {/* draggable component end */}
                        </div>
                    </>
                ) : (
                    ''
                )}
                <div
                    style={{
                        margin: '1rem 3rem',
                        borderTop: '1px solid rgb(143, 93, 162)',
                    }}
                ></div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div id="scrollUpToGetButton"></div>
                    {selectedLLMModelName && !isSelectedLlmAndVector && (
                        <ValidationMessage>
                            Please select one LLM{' '}
                            {hasReplacebleVectorDb ? 'and one Vector DB ' : ''}
                            to proceed.
                        </ValidationMessage>
                    )}

                    <CreateButton
                        disabled={
                            currentTab == 'solution'
                                ? !(
                                      selectedLLMModelName &&
                                      isSelectedLlmAndVector
                                  )
                                : !selectedLLMModelName ||
                                  !selectedVectorDBModelName
                        }
                        onClick={handleCreateClick}
                    >
                        Get
                    </CreateButton>
                </div>

                {showComposeSolution == true && (
                    <ViewPortControl>
                        <Alert
                            variant="primary"
                            show={getComposeSolutionError ? true : false}
                            style={{
                                width: '100%',
                                backgroundColor: 'rgb(193, 166, 242, 0.2)',
                                borderColor: 'rgb(193, 166, 242, 0.2)',
                                display: 'flex',
                                marginTop: '1rem',
                                justifyContent: 'center',
                                borderRadius: '0',
                            }}
                        >
                            <ErrorPanel />
                        </Alert>
                        {!getComposeSolutionError ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <div id="scrollUpToId"></div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: '0.5rem',
                                        }}
                                    >
                                        <ButtonTab className={'active'}>
                                            Preview YAML
                                        </ButtonTab>
                                        <ButtonTab
                                            onClick={() =>
                                                handleDownloadClick()
                                            }
                                        >
                                            Download YAML
                                        </ButtonTab>
                                    </div>
                                    <OutputInnerContainer>
                                        <pre
                                            style={{
                                                padding: '1rem',
                                            }}
                                        >
                                            <CodeBlock
                                                key="code-block"
                                                theme={dracula}
                                                text={getComposeSolutionResult}
                                                language={'yaml'}
                                                showLineNumbers={false}
                                                wrapLongLines={true}
                                                customStyle={{
                                                    height: '600px',
                                                    width: '70rem',
                                                    overflow: 'scroll',
                                                }}
                                            />
                                        </pre>
                                    </OutputInnerContainer>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </ViewPortControl>
                )}
            </>
        )
    }

    return (
        <ContainerWrapper compose={true}>
            {/* isUserLoggedIn passed as argument in child function from ContainerWrapper */}
            {(isUserLoggedIn) => bodyContent(isUserLoggedIn)}
        </ContainerWrapper>
    )
}

ComposeContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => {
            dispatch(getAIModels(qParam))
        },
        getComposeSolution: (qParam: any) =>
            dispatch(getComposeSolution(qParam)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getAIModelsResultData: state.getAIModelsResult.data,
        getAIModelsLoading: state.getAIModelsResult.loading,

        getComposeSolutionResult: state.getComposeSolutionResult.data,
        getComposeSolutionLoading: state.getComposeSolutionResult.loading,
        getComposeSolutionError: state.getComposeSolutionResult.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(ComposeContainer)
