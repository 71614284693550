export enum ModelType {
    Trending = 'chat',
    Compare = 'compare',
    Visual = 'visual',
    Audio = 'audio',
    SummaryDoc = 'summarization',
    Translation = 'translation',
    Retrieval = 'retrieval',
    Reasoning = 'reasoning',
}

export enum ModelSubType {
    textToImage = 'TextToImage',
    imageToText = 'ImageToText',
    imageToVideo = 'ImageToVideo',
    WithDataInputFile = 'WithDataInputFile',
    videoToTextSearch = 'VideoToTextSearch',
    vectorDb = 'vectordb',
    Llms = 'llm',
    Solution = 'solution',
    none = 'none',
    audioToText = 'AudioToText',
    audioToAudio = 'AudioToAudio',
    textToCode = 'TextToCode',
    textTotext = 'TextToText',
}
export enum libType {
    Llm = 'llm',
    VectorDb = 'vectordb',
}
export enum ViewType {
    ModelView = 'modelView',
    ExpandedView = 'expandedView',
}
