import { Grid, SelectChangeEvent } from '@mui/material'
import moment, { Moment } from 'moment/moment'
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { LoadingState } from '../../models/loadingState'
import {
    getAnalytics,
    getModelsDownloads,
    getSearchMisses,
    getUserDomainDistribution,
} from '../../store/dashboard.slice'
import ModelDownloadsByDomainCard from './cards/ModelDownloadsByDomainCard'
import ModelMissesSearchesCard from './cards/ModelMissesSearchesCard'
import ModelSearchesCard from './cards/ModelSearchesCard'
import MostMetricsCards from './cards/MostMetricsCards'
import UserDistributionCard from './cards/UserDistributionCard'
import DashboardFilters, {
    DateRange,
    FilterType,
    TimeFiltersOption,
} from './DashboardFilters'

export const defaultSelectedDomainDownloadsDist = 'all'

const DashboardPanel = () => {
    const uniqueLoginsLoading = useAppSelector<any>(
        (state: any) => state.getUniqueLoginsResult.loading
    )
    const dashboardDataLoading = useAppSelector<any>(
        (state: any) => state.getDashboardDataResult.loading
    )
    const searchesLoading = useAppSelector(
        (state) => state.getAnalyticsResult?.loading
    )

    const [isDashboardLoading, setIsDashboardLoading] = useState(false)

    useEffect(() => {
        const loadingStates = [
            uniqueLoginsLoading,
            dashboardDataLoading,
            searchesLoading,
        ]
        setIsDashboardLoading(
            loadingStates.some(
                (state: LoadingState) => state === LoadingState.Pending
            )
        )
    }, [uniqueLoginsLoading, dashboardDataLoading, searchesLoading])

    const [selectedFilterType, setSelectedFilterType] =
        useState<FilterType>('time-filters')
    const [selectedTimeFilter, setSelectedTimeFilter] =
        useState<TimeFiltersOption>(TimeFiltersOption.OneMonth)
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange>([
        moment().subtract(1, 'month'),
        moment(),
    ])

    const [selectedDownloadsDomain, setSelectedDownloadsDomain] =
        useState<string>(defaultSelectedDomainDownloadsDist)

    const dispatch = useAppDispatch()
    const fetchDashboardData = () => {
        const [fromDate, toDate] = getTimeRange()

        Promise.all([
            dispatch(getAnalytics({ fromDate, toDate })),
            dispatch(getSearchMisses({ fromDate, toDate })),
            dispatch(getUserDomainDistribution({ fromDate, toDate })),
            dispatch(getModelsDownloads({ fromDate, toDate })),
        ])
    }

    useEffect(() => {
        fetchDashboardData()
    }, [])

    useEffect(() => {
        setSelectedDownloadsDomain(defaultSelectedDomainDownloadsDist)
        fetchDashboardData()
    }, [selectedFilterType, selectedTimeFilter])

    const getTimeRange = () => {
        let [fromDate, toDate] = ['', '']
        if (selectedFilterType === 'date-range') {
            ;[fromDate, toDate] = selectedDateRange.map((value) =>
                value.toISOString()
            )
        }
        if (selectedFilterType === 'time-filters') {
            const now = moment()
            const fromMoment = moment()

            if (selectedTimeFilter === TimeFiltersOption.OneDay) {
                fromMoment.subtract(1, 'days')
            } else if (selectedTimeFilter === TimeFiltersOption.OneWeek) {
                fromMoment.subtract(7, 'days')
            } else if (selectedTimeFilter === TimeFiltersOption.OneMonth) {
                fromMoment.subtract(1, 'months')
            } else if (selectedTimeFilter === TimeFiltersOption.ThreeMonths) {
                fromMoment.subtract(3, 'months')
            } else if (selectedTimeFilter === TimeFiltersOption.SixMonths) {
                fromMoment.subtract(6, 'months')
            } else if (selectedTimeFilter === TimeFiltersOption.OneYear) {
                fromMoment.subtract(1, 'years')
            }

            fromDate = fromMoment.toISOString()
            toDate = now.toISOString()
        }
        return [fromDate, toDate]
    }

    const handleFilterSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newSelectedFilterType = event.target.checked
            ? 'date-range'
            : 'time-filters'
        setSelectedFilterType(newSelectedFilterType)
    }

    const handleTimeFilterChange = (
        _: MouseEvent<HTMLElement>,
        newTimeFilter: TimeFiltersOption | null
    ) => {
        if (newTimeFilter !== null) {
            setSelectedTimeFilter(newTimeFilter)
        }
    }

    const handleStartDateChange = (newStartDate: Moment | null) => {
        if (newStartDate) {
            setSelectedDateRange((prevDateRange) => [
                newStartDate,
                prevDateRange[1],
            ])
        }
    }

    const handleEndDateChange = (newEndDate: Moment | null) => {
        if (newEndDate) {
            setSelectedDateRange((prevDateRange) => [
                prevDateRange[0],
                newEndDate,
            ])
        }
    }

    const handleSelectedDownloadsDomainChange = (event: SelectChangeEvent) => {
        setSelectedDownloadsDomain(event.target.value)
    }

    return (
        <>
            <DashboardFilters
                disabled={isDashboardLoading}
                selectedFilterType={selectedFilterType}
                selectedTimeFilter={selectedTimeFilter}
                handleFilterSwitchChange={handleFilterSwitchChange}
                handleTimeFilterChange={handleTimeFilterChange}
                selectedDateRange={selectedDateRange}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                onApplyDateRangeFilters={fetchDashboardData}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MostMetricsCards />
                </Grid>
                <Grid item xs={12}>
                    <UserDistributionCard />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ModelSearchesCard />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ModelMissesSearchesCard />
                </Grid>
                <Grid item xs={12}>
                    <ModelDownloadsByDomainCard
                        selectedDownloadsDomain={selectedDownloadsDomain}
                        handleSelectedDownloadsDomainChange={
                            handleSelectedDownloadsDomainChange
                        }
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default DashboardPanel
