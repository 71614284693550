import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Container, Navbar, Offcanvas } from 'react-bootstrap'
import { useAppDispatch } from '../config/hooks'
import SideLinkPanel from './sidePanel'

import { getCurrentUser } from 'aws-amplify/auth'
import { useEffect } from 'react'
import UserProfilePanel from './userProfile'

import BoxLogo from '../icons/boxLogo'
import Logo from '../icons/logo'
import { getAuthPermissions } from '../store/authPermissions.slice'

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 50;
    background-color: #fff;
    border-bottom: 1px solid #aeaeae;
    height: 56px;
    @media screen and (min-width: 768px) {
        height: 72px;
    }
`

const HeaderMenuDiv = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`

const DesktopLogo = styled.div`
    display: none;
    @media screen and (min-width: 768px) {
        display: block;
    }
`

const MobileLogo = styled.div`
    display: block;
    @media screen and (min-width: 768px) {
        display: none;
    }
`

const OHTitleText = styled.h3`
    text-decoration: none;
    text-align: center;
    justify-content: center;
    color: ${(props) => props.theme.color.text.blue};
    font-family: IntelOne Display;

    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 1rem;
    font-size: 1.25rem;
    @media screen and (min-width: 768px) {
        font-size: 1.5rem;
    }

    a {
        text-decoration: none;
        color: ${(props) => props.theme.color.text.blue};
    }
`

const HeaderNavDiv = styled(HeaderMenuDiv)`
    display: block;
    @media screen and (min-width: 768px) {
        display: none;
    }
`

const CustomToggle = styled(Navbar.Toggle)`
    background-color: ${(props) => props.theme.color.text.navy};
    border-radius: 0;
    color: white;
    height: 40px;
    width: 40px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .navbar-toggler-icon {
        background-color: white;
        background-image: none;
        height: 2px;
        width: 14px;

        &:before {
            display: block;
            position: relative;
            content: '';
            background-color: white;
            height: 2px;
            width: 14px;
            top: -5px;
        }

        &:after {
            display: block;
            position: relative;
            content: '';
            background-color: white;
            height: 2px;
            width: 14px;
            top: 3px;
        }
    }
`

const CustomClose = styled(Offcanvas.Header)`
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    .btn-close {
        min-height: 40px;
        min-width: 40px;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background-color: ${(props) => props.theme.color.text.navy};
        opacity: 1;
        border-radius: 0;
        background-image: none;
        &:before {
            color: white;
            content: 'X';
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }
    }
`

export interface IHeaderPanelProps {
    isUserLoggedIn?: boolean
    setIsUserLoggedIn?: any
}

export const AiModelHeaderPanel: FunctionComponent<IHeaderPanelProps> = ({
    isUserLoggedIn,
    setIsUserLoggedIn,
}: IHeaderPanelProps) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                await getCurrentUser()
                console.log('user logged in already')
                setIsUserLoggedIn(true)
            } catch {
                setIsUserLoggedIn(false)
            }
        }
        if (window.location.pathname.indexOf('/logOut') > -1) {
            setIsUserLoggedIn(false)
        } else fetchUserData()
    }, [])

    useEffect(() => {
        if (isUserLoggedIn) dispatch(getAuthPermissions())
    }, [isUserLoggedIn])

    const offCanvasNav = () => {
        return (
            <>
                {[false].map((expand, index) => (
                    <Navbar expand={expand} key={`nav-${index}`}>
                        <Container fluid style={{ paddingLeft: '9px' }}>
                            <CustomToggle
                                aria-controls={`offcanvasNavbar-expand-${expand}`}
                                id="offcanvasNavButton"
                            />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="start"
                                style={{
                                    width: '250px',
                                    backgroundColor: '#f7f7f7',
                                }}
                            >
                                <CustomClose closeButton />

                                <Offcanvas.Body
                                    style={{ margin: '0', padding: '0' }}
                                >
                                    {
                                        <SideLinkPanel
                                            isUserLoggedIn={isUserLoggedIn}
                                        ></SideLinkPanel>
                                    }
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </>
        )
    }

    return (
        <HeaderContainer>
            <HeaderMenuDiv>
                <HeaderNavDiv>{offCanvasNav()}</HeaderNavDiv>
                <DesktopLogo>
                    <BoxLogo />
                </DesktopLogo>
                <MobileLogo>
                    <Logo />
                </MobileLogo>
                <OHTitleText>
                    <Link to={'/'}>AI Software Catalog</Link>
                </OHTitleText>
            </HeaderMenuDiv>

            <UserProfilePanel isUserLoggedIn={isUserLoggedIn} />
        </HeaderContainer>
    )
}

export default AiModelHeaderPanel
