import { Box } from '@mui/material'
import { FC } from 'react'
import { Chart } from 'react-google-charts'
import { LoadingState } from '../../../../models/loadingState'
import { colors } from '../../../../utils/colors'
import chartColors from './chartColors'
import ChartLoader from './ChartLoader'
import ChartWrapper from './ChartWrapper'

const donutChartBoxSx = {
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

const chartOptions = {
    fontName: 'IntelOne Text',
    fontSize: 12,
    chartArea: {
        top: 16,
        bottom: 48,
        left: 48,
        right: 48,
    },
    pieHole: 0.45,
    pieSliceText: 'label',
    sliceVisibilityThreshold: 0,
    legend: {
        position: 'bottom',
        scrollArrows: {
            activeColor: colors.classicBlue,
        },
        pagingTextStyle: {
            color: colors.classicBlue,
        },
    },
    colors: chartColors,
}

export type DonutChartData = [[string, string], ...(string | number)[][]]

interface DonutChartProps {
    chartData: DonutChartData
    dataLoadingState: LoadingState | LoadingState[]
}

const DonutChart: FC<DonutChartProps> = ({ chartData, dataLoadingState }) => {
    const isDataLoading = Array.isArray(dataLoadingState)
        ? dataLoadingState.some((state) => state === LoadingState.Pending)
        : dataLoadingState === LoadingState.Pending
    return (
        <Box sx={donutChartBoxSx}>
            <ChartWrapper
                isDataLoading={isDataLoading}
                noDataCondition={chartData.length === 1}
            >
                <Chart
                    chartType="PieChart"
                    data={chartData}
                    loader={<ChartLoader message="Loading chart..." />}
                    width="100%"
                    height={350}
                    chartLanguage="en-US"
                    options={chartOptions}
                />
            </ChartWrapper>
        </Box>
    )
}

export default DonutChart
