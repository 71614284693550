import { Amplify } from 'aws-amplify'
import {
    confirmSignUp as ConfirmSignup,
    fetchUserAttributes,
    resendSignUpCode,
    signIn as Signin,
} from 'aws-amplify/auth'
import {
    ChangeEvent,
    FormEvent,
    KeyboardEvent,
    useCallback,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmailFormatValid } from '../../lib/validate'
import amplify_conf from '../../services/auth/amplify'
import { IActivateAccountProps } from './activateAccountContainer'
Amplify.configure(amplify_conf)

export function ActivateAccountPanelLogic({}: IActivateAccountProps) {
    const [validated, setValidated] = useState(false)
    //const [acountOrgName, setAcountOrgName] = useState<string>('')
    const [accountEmail, setAccountEmail] = useState<string>('')
    const [accountPassword, setAccountPassword] = useState<string>('')
    const [confirmationCode, setConfirmationCode] = useState<string>('')
    const [onError, setOnError] = useState<any>('')
    const [onSuccess, setOnSuccess] = useState<any>('')

    let navigate = useNavigate()

    // const onAccountOrgChange = useCallback(
    //     (event: ChangeEvent<HTMLElement>) => {
    //         const target = event.currentTarget as HTMLTextAreaElement
    //         if (target.value) {
    //             setAcountOrgName(target.value)
    //         }
    //     },
    //     []
    // )

    const onAccountEmailChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountEmail(target.value)
                setOnError('')
            }
        },
        []
    )

    const onAccountPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountPassword(target.value)
            }
        },
        []
    )

    async function confirmSignUp(username: string, confirmationCode: string) {
        try {
            await ConfirmSignup({ username, confirmationCode }).then(() => {
                //silent signIn
                let successMessage: {
                    message: string
                } = {
                    message:
                        "Your account has been activated now! You're being sign in...",
                }
                setOnSuccess(successMessage)
                setTimeout(() => {
                    signIn(accountEmail, accountPassword)
                }, 1000)
            })
        } catch (error) {
            setOnError(error)
        }
    }
    async function resendConfirmationCode(username: string) {
        try {
            await resendSignUpCode({ username })
            console.log('code resent successfully')
        } catch (err) {
            setOnError(err)
        }
    }
    async function signIn(username: string, password: string) {
        try {
            const { isSignedIn, nextStep } = await Signin({
                username,
                password,
            })
            if (isSignedIn) {
                const attributes = fetchUserAttributes()
                console.log(attributes)
                if (isSignedIn) {
                    navigate('/')
                }
            }
        } catch (error) {
            setOnError(error)
        }
    }

    const onCancel = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)
        setOnError('')
        window.location.reload()
    }, [])

    const onCodeConfirmChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setConfirmationCode(target.value)
            }
        },
        []
    )

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)

            if (
                accountEmail !== '' &&
                isEmailFormatValid(accountEmail) &&
                confirmationCode !== ''
            ) {
                //confirm signup using user entered code
                confirmSignUp(accountEmail, confirmationCode)
            }
        },
        [accountEmail, confirmationCode]
    )

    const onResendConfirmationCode = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            //resend code
            if (
                accountEmail.trim() !== '' &&
                isEmailFormatValid(accountEmail)
            ) {
                resendConfirmationCode(accountEmail)
            } else {
                let error: {
                    message: string
                } = {
                    message:
                        'Please enter email address in format name@xyg.com, to resend code!',
                }
                setOnError(error)
            }
        },
        [accountEmail]
    )

    const onCodeConfirmKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (
            key.match(/[0-9,c,v]/gi) ||
            key === 'Enter' ||
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'Home' ||
            key === 'End'
        ) {
            return true
        } else {
            event.preventDefault()
        }
    }

    return {
        onAccountEmailChange,
        onAccountPasswordChange,
        onCodeConfirmKeyDown,
        onSubmit,
        validated,
        onCancel,
        onError,
        onSuccess,
        onCodeConfirmChange,
        onResendConfirmationCode,
    }
}
