/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { Dispatch, FunctionComponent, useEffect, useState } from 'react'
import { CopyBlock } from 'react-code-blocks'
import styled from 'styled-components'
import { LoadingState } from '../../../models/loadingState'
import { postRetrievalQuery } from '../../../store/chatCompletion.slice'

import { connect, ConnectedProps } from 'react-redux'
import { CodeblockCustomTheme } from '../../../components/code/customTheme'
import { ragModelExampleData } from '../../../data/ragExampleData'
import { RetrievalModelRequest } from '../../../models/retrievalModelRequest'
import { IAiModelCardProps } from '../../aiModelCard'
import LoadingSection from '../../solutionLayout/loadingSection'
import RetrievalInputPanel from './retrievalInput'

const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 35rem;
    padding: 1rem;
`

const PreStyle = styled.pre`
    margin: 0;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    background: transparent;
    width: 100%;
    color: #ae81ff;
    .span {
        color: #ae81ff;
    }
`

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        postRetrievalQuery: (request: RetrievalModelRequest) =>
            dispatch(postRetrievalQuery(request)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        retrievalQueryAnswer: state.postRetrievalQueryResult.data,
        retrievalQueryLoading: state.postRetrievalQueryResult.loading,
        retrievalQueryError: state.postRetrievalQueryResult.error,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type IRetrievalModelPanelProps = ReduxProps & {
    model: IAiModelCardProps
}

const RetrievalPanel: FunctionComponent<IRetrievalModelPanelProps> = ({
    model,
    retrievalQueryAnswer,
    retrievalQueryLoading,
}: IRetrievalModelPanelProps) => {
    const [output, setOutput] = useState<string>('')

    const setOutputcontent = () => {
        const data = retrievalQueryAnswer?.data[0]?.embedding
            ? `    [\n\t${retrievalQueryAnswer?.data[0]?.embedding.join(
                  ', \n\t'
              )}\n    ]`
            : ragModelExampleData

        setOutput(data)
    }

    useEffect(() => {
        setOutputcontent()
    }, [])

    useEffect(() => {
        setOutputcontent()
    }, [retrievalQueryAnswer])

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <div
                id="chatDiv"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    height: '100%',
                }}
            >
                <RetrievalInputPanel
                    model={model}
                    setOutput={setOutput}
                ></RetrievalInputPanel>
            </div>
            <div
                id="seprator"
                style={{
                    width: '1px',
                    margin: '0 1rem',
                    backgroundColor: 'rgb(101, 49, 113)',
                }}
            ></div>
            <div
                id="outputtDiv"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    height: '100%',
                }}
            >
                <h3
                    style={{
                        fontSize: '1.2rem',
                        paddingLeft: '0.5rem',
                    }}
                >
                    Output
                </h3>
                <OutputInnerContainer>
                    <PreStyle>
                        <CopyBlock
                            key="copy-block"
                            theme={CodeblockCustomTheme}
                            text={output === '' ? ragModelExampleData : output}
                            language="javascript"
                            showLineNumbers={false}
                            wrapLongLines={true}
                            customStyle={{
                                padding: '1rem',
                            }}
                        />
                    </PreStyle>
                    {retrievalQueryLoading === LoadingState.Pending && (
                        <LoadingSection />
                    )}
                </OutputInnerContainer>
            </div>
        </div>
    )
}

export default connector(RetrievalPanel)
