import { Dispatch, FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import styled from 'styled-components'
import { LoadingState } from '../../models/loadingState'
import { ModelSubType, ModelType } from '../../models/modelType'
import { getAIModels } from '../../store/chatCompletion.slice'
import ContainerWrapper from '../containerWrapper'
import SpinnerContainer from '../spinner'

import SolutionDetails from './solutionDetails'
import DemoTab from './solutionTabs/demoTab'
import DemoVideo from './solutionTabs/demoVideo'
import OverviewTab from './solutionTabs/overviewTab'
import PythonTab from './solutionTabs/pythonTab'
import SupportTab from './solutionTabs/supportTab'

import { ConnectedProps } from 'react-redux'
import { useAppSelector } from '../../config/hooks'
import DocumentationTab from './solutionTabs/documentationTab'
import VersionTab from './solutionTabs/versionTab'

const SolutionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -1rem;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        height: 100%;
    }
`

const TabWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: -0.5rem;

    // this specifically counters the MainContent style
    @media screen and (max-width: 767px) {
        margin-left: -1rem;
        width: calc(100% + 2rem);
    }
`

const TabLinksWrapper = styled.div`
    position: relative;
    overflow: hidden;
    height: 49px;
    min-height: 49px;
    max-height: 49px;
`

// gradient shows over side scroll mobile right side
const overlayWidth = '100px'
const SeeMoreOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: ${overlayWidth};
    background: linear-gradient(to right, transparent, white);
    @media screen and (min-width: 768px) {
        display: none;
    }
`

const TabLinks = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 100%;
    height: 100%;
    overflow-x: auto; /* Enables horizontal scrolling */
    scrollbar-width: none; /* For Firefox - hide scrollbar */
    -ms-overflow-style: none; /* For Internet Explorer and Edge - hide scrollbar */
    scroll-behavior: smooth; /* Optional: smooth scrolling */

    /* Styling to hide scrollbar for Webkit browsers (Chrome, Safari, etc.) */
    &::-webkit-scrollbar {
        display: none; /* Hides scrollbar */
    }

    padding-right: ${overlayWidth};
    @media screen and (min-width: 768px) {
        padding-right: 0;
    }
`

const Tab = styled.div`
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid transparent;
    border-bottom: none;

    align-items: center;
    display: flex;
    color: ${(props) => props.theme.color.text.navy};

    &.activeTab {
        color: #495057;
        background: rgba(233, 236, 239, 0.3);
        border: 1px solid #aeaeae;
        border-bottom: none;
        // border-bottom: 2px solid #aeaeae;
    }
    span {
        padding: 10px 20px;
    }
`

const TabContent = styled.div`
    padding: 1rem;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15) inset;
    background-color: #e9ecef;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

interface SupportProps {
    selectedModelName?: string
    selectedModelUUID?: string
    selectedOutputTab?: string
    modelType?: ModelType
    modelSubType?: ModelSubType
    compareTags?: ModelSubType
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => dispatch(getAIModels(qParam)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getAIModelsResultData: state.getAIModelsResult.data,
        getAIModelsLoading: state.getAIModelsResult.loading,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type SolutionProps = ReduxProps & {}

const SolutionLayout: FunctionComponent<SolutionProps> = ({
    getAIModels,
    getAIModelsResultData,
    getAIModelsLoading,
}: SolutionProps) => {
    const authPermissionsResult = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )

    let isAdmin = false

    if (authPermissionsResult && authPermissionsResult?.isIntelEmployee) {
        isAdmin = authPermissionsResult?.isAdmin
    }

    const queryParams = new URLSearchParams(location.search)
    const selectedTab = queryParams.get('tab')

    const { solution } = useParams()
    const [activeSolution, setActiveSolution] = useState<any>(null)
    const [activeTab, setActiveTab] = useState<string>(selectedTab || 'Demo')
    const [modelType, setModelType] = useState<string>(ModelType.Trending)
    const [modelSubType, setModelSubType] = useState<string>(
        ModelSubType.textTotext
    )
    const [currentPromptMessage, setCurrentPromptMessage] = useState<any>(null)

    useEffect(() => {
        if (solution && !activeSolution) {
            // DEBUG: Hide this if testing
            getAIModels(`/${solution}`)

            // DEBUG: Show this if testing
            // let currentModel = dummyModelsResultData.models.find((model) => model.uuid === solution)
            // setActiveSolution(currentModel)
        }
    }, [])

    // DEBUG: Hide this when testing
    useEffect(() => {
        if (getAIModelsResultData) {
            setActiveSolution(getAIModelsResultData)
        }
    }, [getAIModelsResultData])

    useEffect(() => {
        if (activeSolution?.tags) specifyUseCase()
    }, [activeSolution])

    const specifyUseCase = () => {
        let modelTypeValues = Object.values(ModelType)

        // TODO: this could be risky since the enterprise rag is a "use case" tag, and we are grabbing the first case from the array
        let primaryUse = modelTypeValues.find((modelType) =>
            activeSolution.tags['Use Case'].includes(modelType)
        )

        setModelType(primaryUse as ModelType)

        switch (primaryUse) {
            case ModelType.Trending:
                if (activeSolution.subType !== undefined)
                    setModelSubType(activeSolution.subType)
                else setModelSubType(ModelSubType.textTotext)
                break
            case ModelType.Audio:
                if (activeSolution.tags['Subtype'] == ModelSubType.textToImage)
                    setModelSubType(ModelSubType.textToImage)
                else setModelSubType(ModelSubType.imageToText)
                break
            // case ModelType.Retrieval:
            //     break
            case ModelType.Reasoning:
                if (activeSolution.tags['Use Case'] !== undefined)
                    setModelSubType(activeSolution.tags['SubType'])
                else setModelSubType(ModelSubType.textTotext)
                break
            // case ModelType.SummaryDoc:
            //     break
            // case ModelType.Translation:
            //     break
            case ModelType.Visual:
                if (activeSolution.tags['Subtype'] == ModelSubType.textToImage)
                    setModelSubType(ModelSubType.textToImage)
                else setModelSubType(ModelSubType.imageToText)
                break
            // case ModelType.SolutionVector:
            //     break
            // case ModelType.Deploy:
            //     break
            // case ModelType.Custom:
            //     break
            default:
                break
        }
    }

    const renderTabs = () => {
        const tabs = [
            'Demo',
            /*'Python',*/ /*'Installation',*/ 'Documentation',
            'Versions',
            /*'Overview,'*/ 'Support',
        ]

        return tabs.map((tab) => {
            // only QnA gets container tab
            if (activeSolution.type !== 'chat-qna' && tab === 'Container')
                return null

            return (
                <Tab
                    key={tab}
                    onClick={() => setActiveTab(tab)}
                    className={activeTab === tab ? 'activeTab' : ''}
                >
                    <span>{tab}</span>
                </Tab>
            )
        })
    }

    const renderDemoPanel = (isUserLoggedIn: boolean) => {
        if (isAdmin) {
            return (
                <DemoTab
                    model={activeSolution}
                    isUserLoggedIn={isUserLoggedIn}
                    currentPromptMessage={currentPromptMessage}
                    setCurrentPromptMessage={setCurrentPromptMessage}
                />
            )
        }

        if (activeSolution.video) {
            return (
                <DemoVideo
                    video={activeSolution.video}
                    modelName={activeSolution.displayName}
                />
            )
        }

        // TODO: Better fallback messaging if no demo video or no demo functionality
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <h3 style={{ fontWeight: 300 }}>
                    Demonstration content available soon
                </h3>
            </div>
        )
    }

    const renderLayout = (isUserLoggedIn: boolean) => {
        if (!activeSolution || getAIModelsLoading === LoadingState.Pending)
            return <SpinnerContainer />

        return (
            <SolutionWrapper>
                <SolutionDetails
                    model={activeSolution}
                    setActiveTab={setActiveTab}
                />
                <TabWrapper>
                    <TabLinksWrapper>
                        <TabLinks>{renderTabs()}</TabLinks>
                        <SeeMoreOverlay />
                    </TabLinksWrapper>

                    <TabContent>
                        {activeTab === 'Demo' &&
                            renderDemoPanel(isUserLoggedIn)}
                        {activeTab === 'Python' && (
                            <PythonTab
                                model={activeSolution}
                                currentPromptMessage={currentPromptMessage}
                            />
                        )}
                        {activeTab === 'Documentation' && (
                            <DocumentationTab model={activeSolution} />
                        )}
                        {activeTab === 'Versions' && (
                            <VersionTab model={activeSolution} />
                        )}
                        {activeTab === 'Overview' && (
                            <OverviewTab model={activeSolution} />
                        )}
                        {activeTab === 'Support' && (
                            <SupportTab model={activeSolution} />
                        )}
                    </TabContent>
                </TabWrapper>
            </SolutionWrapper>
        )
    }

    return (
        <ContainerWrapper hideSidePanel>
            {(isUserLoggedIn) => renderLayout(isUserLoggedIn)}
        </ContainerWrapper>
    )
}

export default connector(SolutionLayout)
