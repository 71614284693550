import { Grid } from '@mui/material'
import { FC } from 'react'
import { useAppSelector } from '../../../config/hooks'
import DashboardMetricCard from '../shared/cards/DashboardMetricCard'

const mostMetricsCardsGridSx = { paddingTop: '1rem' }

interface MostMetricsCardsProps {}

const MostMetricsCards: FC<MostMetricsCardsProps> = () => {
    const userDistribution = useAppSelector(
        (state: any) => state.getUserDomainDistributionResult?.data
    )
    const userDistributionLoading = useAppSelector(
        (state: any) => state.getUserDomainDistributionResult?.loading
    )

    const searchesData = useAppSelector(
        (state: any) => state.getAnalyticsResult.data
    )
    const searchesLoading = useAppSelector(
        (state: any) => state.getAnalyticsResult?.loading
    )

    const downloadsCount = useAppSelector<any>(
        (state: any) =>
            state.getModelsDownloadsResult.data?.distribution.totalDownloads
    )
    const downloadsLoading = useAppSelector(
        (state: any) => state.getModelsDownloadsResult?.loading
    )

    return (
        <Grid
            container
            columns={30}
            spacing={2}
            alignItems="stretch"
            sx={mostMetricsCardsGridSx}
        >
            <Grid item xs={30} sm={10}>
                <DashboardMetricCard
                    title="Total Downloads"
                    count={downloadsCount}
                    loading={downloadsLoading}
                    dataTestId="Total Downloads-label"
                />
            </Grid>
            <Grid item xs={30} sm={10}>
                <DashboardMetricCard
                    title="Total Users Logged in"
                    count={userDistribution?.totalUsers}
                    loading={userDistributionLoading}
                    dataTestId="Total Searches-label"
                />
            </Grid>
            <Grid item xs={30} sm={10}>
                <DashboardMetricCard
                    title="Most Searched"
                    count={searchesData?.pageInfo?.total}
                    technologyName={
                        searchesData?.pageInfo?.total > 0
                            ? searchesData?.searchCounts[0].search
                            : ''
                    }
                    loading={searchesLoading}
                    dataTestId="Most Searched-label"
                />
            </Grid>
        </Grid>
    )
}

export default MostMetricsCards
