import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import ComposeSolutionService from '../services/composeSolutionService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getComposeSolution = createAsyncThunk(
    'getComposeSolution',
    async (qParam: any) => {
        return await ComposeSolutionService.composeGeneration(qParam)
    }
)

// Then, handle actions in your reducers:
const getComposeSolutionSlice = createSlice({
    name: 'getComposeSolution',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(getComposeSolution.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getComposeSolution.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(getComposeSolution.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getComposeSolutionResult = getComposeSolutionSlice.reducer
