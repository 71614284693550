import { API_SERVICE_URL } from '../config/service'
import { GroupObj } from '../containers/accessMgmtContainer/accessMgmtContainer'
import api from './auth/client'

const serviceConfig = require('../config/service.json')
const queryString = require('query-string')

class AccessMgmtService {
    async getAccessGroups() {
        const url = API_SERVICE_URL + serviceConfig.accessMgmtURL
        return await api.get(url)
    }

    async addGroup(group: string) {
        const url = API_SERVICE_URL + serviceConfig.accessMgmtURL

        return await api.post(url, { id: group })
    }

    async getMembers(group: string) {
        const url = `${API_SERVICE_URL}${serviceConfig.accessMgmtURL}/${group}/access`

        return await api.get(url)
    }

    async removeMember(payload: any) {
        const { group } = payload
        delete payload['group']
        delete payload['email']
        const url = `${API_SERVICE_URL}${serviceConfig.accessMgmtURL}/${group}/access`

        return await api.delete(url, { data: payload })
    }

    async setAccess(groupObj: GroupObj) {
        const url = API_SERVICE_URL + serviceConfig.accessMgmtURL
        const { group } = groupObj

        delete groupObj.group

        return await api.post(`${url}/${group}/access`, groupObj)
    }
}

export default new AccessMgmtService()
