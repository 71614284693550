import { API_SERVICE_URL } from '../config/service'
import api, { isLoggedIn } from './auth/client'
import apiNoAuth from './auth/noAuthClient'
const serviceConfig = require('../config/service.json')

class QueueAttributesService {
    async getQueueAttributes(qParam: any) {
        var url = API_SERVICE_URL + serviceConfig.queueAttributes + qParam
        if (await isLoggedIn()) return api.get(url)

        return apiNoAuth.get(url)
    }
}
export default new QueueAttributesService()
