/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import { Dispatch, FunctionComponent } from 'react'
import { Alert, Button, Form, FormLabel } from 'react-bootstrap'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { AcceptInvitesResponse } from '../../models/acceptInvitesResponse'
import { LoadingState } from '../../models/loadingState'
import { createOrg } from '../../store/createOrg.slice'
import { acceptInvites } from '../../store/invitation.slice'
import { ActivateAccountPanelLogic } from './activateAccountContainer.hook'
// import { CreateOrgRequest } from '../../models/createOrgRequest'
// import { AcceptInvitesRequest } from '../../models/acceptInvitesRequest'
import { useLocation, useNavigate } from 'react-router-dom'
import stringUtils from '../../lib/stringUtils'

const FullPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    background-color: ${(props) => props.theme.color.background.defaultWhite};
`

const ParentMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
`

const AccountFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
`

const HorizontalCenterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`

const VerticalCenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
`

const PageTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    color: ${(props) => props.theme.color.text.heading};
    text-align: left;
    margin-bottom: 1rem;
`

const PageTitleTextLabel2 = styled(FormLabel)`
    ${(props) => props.theme.typography.medium}
    color: ${(props) => props.theme.color.text.heading};
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
`

const ConfirmCodeComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 100rem) {
        flex-grow: 1;
    }
`

const ConfirmButtonComponentDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 1rem;
`

const ResendCodeButtonComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
`

const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: 0;
    margin-bottom: 1rem;
    width: 100%;
    @media screen and (max-width: 500px) {
        width: 20rem;
    }
`

const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`

const SignUpButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 10rem;
    border-radius: 0;
`

const ResetButton = styled(SignUpButton)`
    margin-left: 1rem;
    width: 50%;
`

const SubmitButton = styled(SignUpButton)`
    margin-right: 0;
    width: 50%;
`

const ResendCodeButton = styled(SignUpButton)`
    width: 100%;
`

const HaveAccountContainer = styled.div`
    display: flex;
    padding: 1rem;
    margin-top: 5rem;
    width: 100%;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
`

const HeadingLabel = styled(FormLabel)`
    display: flex;
    color: ${(props) => props.theme.color.text.heading};
    width: 15rem;
    font-size: 1rem;
    font-weight: 500;
`

const StyledAnchor = styled.a`
    margin-left: 0.25rem;
    cursor: pointer;
    color: ${(props) => props.theme.color.text.heading};
`

const IntelLogoStyling = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
`

export interface IActivateAccountProps {
    invitationKey?: string
    createOrg: typeof createOrg
    createOrgLoading: LoadingState
    createOrgResult: any
    createOrgError?: any

    acceptInvites: typeof acceptInvites
    acceptInvitesError: any
    acceptInvitesLoading: LoadingState
    acceptInvitesResult: AcceptInvitesResponse
}

const ActivateAccountContainer: FunctionComponent<IActivateAccountProps> & {
    defaultProps: Partial<IActivateAccountProps>
} = ({
    createOrg,
    createOrgLoading,
    createOrgResult,
    createOrgError,
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
}: IActivateAccountProps) => {
    const location = useLocation()
    const qParam = new URLSearchParams(location.search)
    const invitationKey = qParam.get('invitationKey')
        ? qParam.get('invitationKey')
        : localStorage.getItem('invitationKey')

    let navigate = useNavigate()

    const {
        onAccountEmailChange,
        onAccountPasswordChange,
        onCodeConfirmKeyDown,
        onSubmit,
        validated,
        onCancel,
        onError,
        onSuccess,
        onCodeConfirmChange,
        onResendConfirmationCode,
    } = ActivateAccountPanelLogic({} as IActivateAccountProps)

    const homeURL = stringUtils.isNullOrUndefinedOrEmpty(invitationKey)
        ? '/'
        : `/?invitationKey=${invitationKey}`

    return (
        <FullPageContainer>
            <IntelLogoStyling>
                <img
                    src="/Intel_energyblue_logo.png"
                    height="65px"
                    width="171px"
                    alt="intel logo"
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(homeURL)}
                />
            </IntelLogoStyling>
            <ParentMainContainer>
                <AccountFormContainer>
                    <PageTitleWrapper>
                        <PageTitleTextLabel>
                            {'Activate an account'}
                        </PageTitleTextLabel>
                    </PageTitleWrapper>
                    <PageTitleWrapper>
                        <PageTitleTextLabel2 data-testid="pageSubTitle-lable">
                            {
                                'Already have confirmation code, activate your account here!'
                            }
                        </PageTitleTextLabel2>
                    </PageTitleWrapper>
                    <HorizontalCenterContainer>
                        <ConfirmCodeComponent>
                            <AlertComponent
                                style={{
                                    width: '25rem',
                                }}
                                variant="danger"
                                show={onError?.message ? true : false}
                                data-testid="signup-error-text"
                            >
                                {'Error! ' + onError?.message}
                            </AlertComponent>
                            <AlertComponent
                                style={{
                                    width: '25rem',
                                }}
                                variant="success"
                                show={onSuccess?.message ? true : false}
                            >
                                {'Success! ' + onSuccess?.message}
                            </AlertComponent>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={onSubmit}
                                id="CodeConfirmForm"
                            >
                                {/* {stringUtils.isNullOrUndefinedOrEmpty(
                                    invitationKey
                                ) ? (
                                    <Form.Group controlId="OrgName">
                                        <HeadingLabel data-testid="organization-name-label">
                                            <RequiredTextLabel>
                                                *
                                            </RequiredTextLabel>
                                            Organization name{' '}
                                        </HeadingLabel>
                                        <TextBoxWrapper
                                            placeholder="Enter your org's name..."
                                            onChange={onAccountOrgChange}
                                            required
                                            data-testid="organization-name-text"
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please enter your org's name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                ) : (
                                    <div></div>
                                )} */}
                                <Form.Group controlId="formBasicEmail">
                                    <HeadingLabel data-testid="email-address-label">
                                        <RequiredTextLabel>*</RequiredTextLabel>
                                        Email address{' '}
                                    </HeadingLabel>
                                    <TextBoxWrapper
                                        type="email"
                                        placeholder="Enter your email..."
                                        onChange={onAccountEmailChange}
                                        required
                                        data-testid="email-address-text"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter an email address in format
                                        name@xyg.com.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <HeadingLabel>
                                        <RequiredTextLabel>*</RequiredTextLabel>
                                        Password{' '}
                                    </HeadingLabel>
                                    <TextBoxWrapper
                                        id="userPassword"
                                        name="userPassword"
                                        type="password"
                                        placeholder="Enter your password..."
                                        autoComplete="new-password"
                                        onChange={onAccountPasswordChange}
                                        required
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please enter your password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="code">
                                    <HeadingLabel data-testid="entercode-label">
                                        <RequiredTextLabel>*</RequiredTextLabel>
                                        Confirmation Code{' '}
                                    </HeadingLabel>
                                    <TextBoxWrapper
                                        placeholder="Enter your confirmation code here..."
                                        type="search"
                                        onChange={onCodeConfirmChange}
                                        onKeyDown={onCodeConfirmKeyDown}
                                        required
                                        data-testid="entercode-text"
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please enter your confirmation code.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <ConfirmButtonComponentDiv>
                                        <SubmitButton
                                            variant="warning"
                                            type="submit"
                                            onClick={onSubmit}
                                            id="SubmitCodeConfirm"
                                            data-testid="confirmCode-submit-button"
                                        >
                                            Submit
                                        </SubmitButton>
                                        <ResetButton
                                            variant="info"
                                            type="reset"
                                            onClick={onCancel}
                                            data-testid="reset-button"
                                        >
                                            Reset
                                        </ResetButton>
                                    </ConfirmButtonComponentDiv>
                                    <ResendCodeButtonComponent>
                                        <HeadingLabel>
                                            Didn't get code?
                                        </HeadingLabel>
                                        <ResendCodeButton
                                            variant="success"
                                            type="resend"
                                            onClick={onResendConfirmationCode}
                                            data-testid="resend-button"
                                        >
                                            Resend confirmation code
                                        </ResendCodeButton>
                                    </ResendCodeButtonComponent>

                                    <HaveAccountContainer>
                                        <VerticalCenterContainer>
                                            <HorizontalCenterContainer>
                                                <HeadingLabel>
                                                    Already have an account?
                                                </HeadingLabel>
                                                <StyledAnchor href="/signIn">
                                                    Sign in
                                                </StyledAnchor>
                                            </HorizontalCenterContainer>
                                            <HorizontalCenterContainer>
                                                <HeadingLabel>
                                                    Need an account?
                                                </HeadingLabel>
                                                <StyledAnchor href="/createAccount">
                                                    Sign up
                                                </StyledAnchor>
                                            </HorizontalCenterContainer>
                                        </VerticalCenterContainer>{' '}
                                    </HaveAccountContainer>
                                </Form.Group>
                            </Form>
                        </ConfirmCodeComponent>
                    </HorizontalCenterContainer>
                </AccountFormContainer>
            </ParentMainContainer>
        </FullPageContainer>
    )
}

ActivateAccountContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        // createOrg: (createOrgRequest: CreateOrgRequest) =>
        //     dispatch(createOrg(createOrgRequest)),
        // acceptInvites: (acceptInvitesRequest: AcceptInvitesRequest) =>
        //     dispatch(acceptInvites(acceptInvitesRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        // createOrgError: state.createOrgResult.error,
        // createOrgLoading: state.createOrgResult.loading,
        // createOrgResult: state.createOrgResult.data,
        // acceptInvitesError: state.acceptInvitesResult.error,
        // acceptInvitesLoading: state.acceptInvitesResult.loading,
        // acceptInvitesResult: state.acceptInvitesResult.data,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(ActivateAccountContainer)
