import { FunctionComponent } from 'react'

const ViewOn: FunctionComponent = ({}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_522_4332)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1 7.99902C1 9.85554 1.7375 11.636 3.05025 12.9488C4.36301 14.2615 6.14348 14.999 8 14.999C9.85652 14.999 11.637 14.2615 12.9497 12.9488C14.2625 11.636 15 9.85554 15 7.99902C15 6.14251 14.2625 4.36203 12.9497 3.04928C11.637 1.73652 9.85652 0.999023 8 0.999023C6.14348 0.999023 4.36301 1.73652 3.05025 3.04928C1.7375 4.36203 1 6.14251 1 7.99902ZM16 7.99902C16 10.1208 15.1571 12.1556 13.6569 13.6559C12.1566 15.1562 10.1217 15.999 8 15.999C5.87827 15.999 3.84344 15.1562 2.34315 13.6559C0.842855 12.1556 0 10.1208 0 7.99902C0 5.87729 0.842855 3.84246 2.34315 2.34217C3.84344 0.841878 5.87827 -0.000976562 8 -0.000976562C10.1217 -0.000976562 12.1566 0.841878 13.6569 2.34217C15.1571 3.84246 16 5.87729 16 7.99902ZM5.854 10.802C5.76025 10.8959 5.63304 10.9487 5.50035 10.9488C5.36767 10.9489 5.24039 10.8963 5.1465 10.8025C5.05261 10.7088 4.99982 10.5816 4.99972 10.4489C4.99963 10.3162 5.05225 10.1889 5.146 10.095L9.243 5.99902H6.475C6.34239 5.99902 6.21521 5.94634 6.12145 5.85258C6.02768 5.75881 5.975 5.63163 5.975 5.49902C5.975 5.36642 6.02768 5.23924 6.12145 5.14547C6.21521 5.0517 6.34239 4.99902 6.475 4.99902H10.45C10.5826 4.99902 10.7098 5.0517 10.8036 5.14547C10.8973 5.23924 10.95 5.36642 10.95 5.49902V9.47402C10.95 9.60663 10.8973 9.73381 10.8036 9.82758C10.7098 9.92134 10.5826 9.97402 10.45 9.97402C10.3174 9.97402 10.1902 9.92134 10.0964 9.82758C10.0027 9.73381 9.95 9.60663 9.95 9.47402V6.70602L5.854 10.802Z"
                    fill="#0D6EFD"
                />
            </g>
            <defs>
                <clipPath id="clip0_522_4332">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ViewOn
