import * as _ from 'radash'
import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { libType } from '../../models/modelType'
import { DraggableElement } from './DraggableElement'
import { Droppable } from './Droppable'

const Heading = styled.h3`
    color: '#FFF';
`
const SubHeading = styled.h6`
    color: '#FFF';
    padding-top: 0.6rem;
    padding-left: 0.5rem;
`

const ColumnWrapper = styled.div`
    min-width: 30rem;
    max-width: 40rem;
    padding: 10;
    border: 1px solid rgb(143, 93, 162);
    border-radius: 0;
    margin: 0.2rem;
    min-height: 25rem;
    max-height: 55rem;
`

const DropPlaceholder = styled.div`
    background-color: transparent;
    margin-top: 15;
`

const ColumnHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-radius: 0;
    margin-bottom: 0.5rem;
`

const ColumnTasksAmout = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 6;
    color: #000;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.18);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
`

export interface IElement {
    id: string
    name?: string
    content: string
    column: string
    type?: libType
    visible: boolean
}

interface IColumn {
    heading: string
    elements: IElement[]
    subHeading?: string
}

export const Column: FC<IColumn> = ({ heading, elements, subHeading }) => {
    const columnIdentifier = useMemo(() => _.camel(heading), [heading])

    const amounts = useMemo(
        () => elements.filter((elm) => elm.column === columnIdentifier).length,
        [elements, columnIdentifier]
    )

    return (
        <ColumnWrapper>
            <ColumnHeaderWrapper
                id={columnIdentifier as any}
                style={
                    heading == 'current'
                        ? { background: '#cfe2ff' }
                        : { background: '#d1e7dd' }
                }
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Heading>{heading}</Heading>
                    {subHeading && <SubHeading>({subHeading})</SubHeading>}
                </div>
                <ColumnTasksAmout>{amounts}</ColumnTasksAmout>
            </ColumnHeaderWrapper>
            <Droppable id={columnIdentifier}>
                {elements.map((elm, elmIndex) => (
                    <DraggableElement
                        key={`draggable-element-${elmIndex}-${columnIdentifier}`}
                        identifier={elm.id}
                        content={elm.content}
                        type={elm.type}
                        visible={elm.visible}
                    />
                ))}
                <DropPlaceholder />
            </Droppable>
        </ColumnWrapper>
    )
}
