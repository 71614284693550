/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/

import { Alert, Box, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import AiModelFooterPanel from '../../layouts/footer'
import AiModelHeaderPanel from '../../layouts/header'
import SideLinkPanel from '../../layouts/sidePanel'
import { UserOtherMetaRoles } from '../../models/userRoles'
import theme from './theme'

import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import DashboardPanel from '../../components/dashboardPanel/DashboardPanel'
import { useAppSelector } from '../../config/hooks'
import { LoadingState } from '../../models/loadingState'

const dashboardPageBoxSx = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100vh',
    overflowX: 'hidden',
    flexWrap: 'no-wrap',
}

const dashboardContainerBoxSx = {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: '3.5rem',
}

const visualBodyContainer = {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#fefefe',
    padding: '1rem 2rem',
}

const DashboardContainer = () => {
    //get isAnalyst and isIntelEmployee info from storage

    const authPermissionsResult = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )

    const authPermissionsResultLoading = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.loading
    )

    let isIntelEmployee = false
    if (authPermissionsResult && authPermissionsResult?.isIntelEmployee) {
        isIntelEmployee = authPermissionsResult?.isIntelEmployee
    }

    let isAnalyst = false
    if (authPermissionsResult?.otherMetaPermissions) {
        let metaPermissions =
            authPermissionsResult.otherMetaPermissions.toString()
        if (metaPermissions) {
            metaPermissions = metaPermissions.split(',')
        }
        isAnalyst = metaPermissions.includes(UserOtherMetaRoles.Analyst)
    }

    const showDashboard = isAnalyst && isIntelEmployee
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)

    const loadingSection = (
        <div style={{ marginLeft: '1.5rem' }}>
            <Spinner animation="grow" variant="info" size="sm" />
            <span> Loading...</span>
        </div>
    )
    return (
        <Box sx={dashboardPageBoxSx}>
            <AiModelHeaderPanel
                isUserLoggedIn={isUserLoggedIn}
                setIsUserLoggedIn={setIsUserLoggedIn}
            ></AiModelHeaderPanel>
            <Box sx={dashboardContainerBoxSx}>
                <Box sx={{ display: 'block' }}>
                    <SideLinkPanel></SideLinkPanel>
                </Box>
                <ThemeProvider theme={theme}>
                    <Box sx={visualBodyContainer}>
                        {showDashboard &&
                        authPermissionsResultLoading == LoadingState.Idle ? (
                            <>
                                <Typography
                                    variant="h1"
                                    sx={{ marginBottom: '0.5rem' }}
                                    data-testid="dashboard-title"
                                >
                                    Dashboard
                                </Typography>
                                <DashboardPanel />
                            </>
                        ) : authPermissionsResultLoading ==
                          LoadingState.Pending ? (
                            loadingSection
                        ) : (
                            !showDashboard &&
                            authPermissionsResultLoading ==
                                LoadingState.Idle && (
                                <Alert severity="error">
                                    Permission denied!
                                </Alert>
                            )
                        )}
                    </Box>
                </ThemeProvider>
            </Box>
            <AiModelFooterPanel></AiModelFooterPanel>
        </Box>
    )
}

export default DashboardContainer
