/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import RAGContainerPanel from '../../../components/ragContainerDetails'
import { modelOverviewData } from '../../../data/modelOverview'
import { IAiModelCardProps } from '../../aiModelCard'

const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;
`
const OutputContainerTab = styled.div`
    display: flex;
    justify-content: center;
    min-height: 31.5rem;
    color: rgb(248, 248, 242);
    width: 100%;
`
const TextLabel = styled.div`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
`

const ContentLabel = styled.div`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    padding-bottom: 1rem;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    alignitems: center;
    width: 100%;
    padding: 0.5rem;
    @media screen and (min-width: 768px) {
        padding: 1rem;
    }
    color: var(--input-label-light-default, #494b51);
`

export interface IOverviewProps {
    model?: IAiModelCardProps
}

const OverviewTab: FunctionComponent<IOverviewProps> = ({
    model,
}: IOverviewProps) => {
    const [modelOverview, setModelOverview] = useState<any>({})

    const renderNewLine = (text: string) => {
        if (text && text.indexOf('\n') > -1) {
            let lines = text.split('\n')
            return (
                <div>
                    {lines.map((textLine: string, index: number) => {
                        return (
                            <div key={`${index}-${textLine}`}>
                                {textLine}
                                <br />
                            </div>
                        )
                    })}
                </div>
            )
        } else return text
    }

    useEffect(() => {
        if (model?.name) {
            const foundModel = modelOverviewData.find(
                (item: any) => item.model == model.name
            )
            if (foundModel) setModelOverview(foundModel)
        }
    }, [])

    const renderOverviewContainer = () => {
        if (
            model?.tags?.['Solution']?.includes('opea-chatqna') &&
            //TODO: for some reason the Enterprise Rag models have the opea-chatqna tag,
            // verify if accurate and remove line blelow
            model?.name.includes('rag-intel/neural-chat-7b-v3-3')
        )
            return <RAGContainerPanel selectedModelName={model.name} />

        return (
            <OutputInnerContainer>
                <OutputContainerTab>
                    <Container>
                        <TextLabel>Feature:</TextLabel>
                        <ContentLabel>
                            {' '}
                            {modelOverview.displayName}
                        </ContentLabel>
                        <TextLabel>Description:</TextLabel>
                        <ContentLabel>
                            {renderNewLine(modelOverview.overview)}
                        </ContentLabel>
                        <TextLabel>Platform:</TextLabel>
                        <ContentLabel>
                            Intel Gaudi2 or Intel Gaudi3
                        </ContentLabel>
                        <TextLabel>Published By:</TextLabel>
                        <ContentLabel> {modelOverview.author}</ContentLabel>
                    </Container>
                </OutputContainerTab>
            </OutputInnerContainer>
        )
    }

    return renderOverviewContainer()
}

export default OverviewTab
