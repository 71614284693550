/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import { FunctionComponent } from 'react'
import { Alert, Button, Form, FormLabel } from 'react-bootstrap'
import styled from 'styled-components'
import { ChangePasswordContainerLogic } from './changePasswordContainer.hook'

const FullPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    background-color: ${(props) => props.theme.color.background.defaultWhite};
`

const BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`

const ParentMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
`

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    height: 100%;
    width: 55%;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
`

const PasswordBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    padding-bottom: 5rem;
    margin: 2rem;
`

const PasswordBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
`

const IntelLogoStyling = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    color: ${(props) => props.theme.color.text.heading};
    ${(props) => props.theme.typography.medium}
`

const ResetPasswordButton = styled(Button)`
    width: 100%;
`
const ChangePasswordButton = styled(ResetPasswordButton)`
    width: 100%;
`

const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    color: ${(props) => props.theme.color.text.heading};
    ${(props) => props.theme.typography.mediumBold}
    color: red;
`

const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    max-width: 25rem;
`

const ForgotPasswordWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
`

const ChangePasswordWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
`

const PageTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
`

const PageTitleTextLabel2 = styled(FormLabel)`
    text-align: left;
    font-family: 'intel-one', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
`

const ValidPasswordSpan = styled.span`
    display: flex;
    color: #19bf00;
    font-size: 14px;
    font-family: 'Arial';
`

const InValidPasswordSpan = styled(ValidPasswordSpan)`
    color: #df3312;
`

const PasswordValidationdiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20rem;
    padding-bottom: 1rem;
`

const HeadingLabel = styled(FormLabel)`
    display: flex;
    color: ${(props) => props.theme.color.text.heading};
    ${(props) => props.theme.typography.mediumBold}
`

const ValueLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.small}
    color: ${(props) => props.theme.color.text.value};
    text-align: left;
    white-space: pre-wrap;
`

const BiggerHeadingLabel = styled(HeadingLabel)`
    ${(props) => props.theme.typography.xLarge}
`

const ForgotContainer = styled.div`
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: center;
`

export interface IChangePasswordContainerProps {}

const ChangePasswordContainer: FunctionComponent<
    IChangePasswordContainerProps
> = () => {
    const {
        validated,
        codeValidated,
        onForgotPasswordSubmit,
        onChangePasswordSubmit,
        onUserEmailChange,
        showForgotPasswordForm,
        userEmail,
        changePasswordError,
        forgotPasswordError,
        onCodeConfirmChange,
        onNewPasswordChange,
        onConfirmNewPasswordChange,
        onCodeConfirmKeyDown,
        onPasswordKeyUp,
        onPasswordMatchKeyUp,
        isPasswordContainLowerLetter,
        isPasswordContainSpecialChar,
        isPasswordContainNumber,
        isPasswordLength10Char,
        isPasswordMatched,
        showPasswordvalidation,
    } = ChangePasswordContainerLogic({} as IChangePasswordContainerProps)
    return (
        <FullPageContainer>
            <IntelLogoStyling>
                <img
                    src="/Intel_energyblue_logo.png"
                    height="65px"
                    width="171px"
                    alt="intel logo"
                    style={{ verticalAlign: 'middle' }}
                />
            </IntelLogoStyling>
            <BodyContainer>
                <ParentMainContainer>
                    <MainContainer>
                        <ContentContainer>
                            <PasswordBodyContainer>
                                <PasswordBodyContainerReposition>
                                    <ForgotPasswordWrapper>
                                        {showForgotPasswordForm ? (
                                            <ForgotContainer className="col-md-3 col-lg-3">
                                                <BiggerHeadingLabel>
                                                    Forgot your password?
                                                </BiggerHeadingLabel>

                                                <PageTitleWrapper>
                                                    <HeadingLabel data-testid="pageSubTitle-lable">
                                                        Enter your Email below
                                                        and we will send a
                                                        message to reset your
                                                        password
                                                    </HeadingLabel>
                                                </PageTitleWrapper>
                                                <AlertComponent
                                                    style={{ width: '25rem' }}
                                                    variant="danger"
                                                    show={
                                                        forgotPasswordError
                                                            ? true
                                                            : false
                                                    }
                                                    data-testid="forgotpass-error-text"
                                                >
                                                    {'Error! ' +
                                                        forgotPasswordError?.message}
                                                </AlertComponent>

                                                <Form
                                                    noValidate
                                                    validated={validated}
                                                    onSubmit={
                                                        onForgotPasswordSubmit
                                                    }
                                                    id="forgotPasswordForm"
                                                >
                                                    <Form.Group controlId="formBasicEmail">
                                                        <HeadingLabel data-testid="email-address-label">
                                                            <RequiredTextLabel>
                                                                *
                                                            </RequiredTextLabel>
                                                            Email address{' '}
                                                        </HeadingLabel>
                                                        <TextBoxWrapper
                                                            type="email"
                                                            placeholder="Enter email..."
                                                            onChange={
                                                                onUserEmailChange
                                                            }
                                                            required
                                                            data-testid="email-address-text"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter an
                                                            email address in
                                                            format name@xyg.com.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <ResetPasswordButton
                                                        variant="success"
                                                        type="submit"
                                                        data-testid="reset-password-button"
                                                    >
                                                        Reset my password
                                                    </ResetPasswordButton>
                                                </Form>
                                            </ForgotContainer>
                                        ) : (
                                            ''
                                        )}
                                    </ForgotPasswordWrapper>
                                    <ChangePasswordWrapper>
                                        {!showForgotPasswordForm ? (
                                            <ForgotContainer className="col-md-3 col-lg-3">
                                                <PageTitleWrapper>
                                                    <PageTitleTextLabel2 data-testid="pageSubTitle-lable">
                                                        We have sent a password
                                                        code by email to{' '}
                                                        {userEmail}. Enter it
                                                        below to reset your
                                                        password.
                                                    </PageTitleTextLabel2>
                                                </PageTitleWrapper>
                                                <AlertComponent
                                                    style={{ width: '25rem' }}
                                                    variant="danger"
                                                    show={
                                                        changePasswordError
                                                            ? true
                                                            : false
                                                    }
                                                    data-testid="changepass-error-text"
                                                >
                                                    {'Error! ' +
                                                        changePasswordError?.message}
                                                </AlertComponent>

                                                <Form
                                                    noValidate
                                                    validated={codeValidated}
                                                    onSubmit={
                                                        onChangePasswordSubmit
                                                    }
                                                    id="changePasswordForm"
                                                >
                                                    <Form.Group controlId="code">
                                                        <ValueLabel data-testid="entercode-label">
                                                            <RequiredTextLabel>
                                                                *
                                                            </RequiredTextLabel>
                                                            Code{' '}
                                                        </ValueLabel>
                                                        <TextBoxWrapper
                                                            placeholder="Enter your code here..."
                                                            type="search"
                                                            onChange={
                                                                onCodeConfirmChange
                                                            }
                                                            onKeyDown={
                                                                onCodeConfirmKeyDown
                                                            }
                                                            required
                                                            data-testid="entercode-text"
                                                        />

                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter your
                                                            code.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId="formBasicPassword">
                                                        <ValueLabel data-testid="choose-password-label">
                                                            <RequiredTextLabel>
                                                                *
                                                            </RequiredTextLabel>
                                                            New Password{' '}
                                                        </ValueLabel>
                                                        <TextBoxWrapper
                                                            id="newPassword"
                                                            name="newPassword"
                                                            type="password"
                                                            placeholder="Please enter new password..."
                                                            autoComplete="new-password"
                                                            onChange={
                                                                onNewPasswordChange
                                                            }
                                                            onKeyUp={
                                                                onPasswordKeyUp
                                                            }
                                                            required
                                                            data-testid="new-password-text"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter new
                                                            password.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId="formMatchPassword">
                                                        <ValueLabel data-testid="confirm-password-label">
                                                            <RequiredTextLabel>
                                                                *
                                                            </RequiredTextLabel>
                                                            Enter New Password
                                                            Again{' '}
                                                        </ValueLabel>
                                                        <TextBoxWrapper
                                                            type="password"
                                                            placeholder="Please enter new password again..."
                                                            onChange={
                                                                onConfirmNewPasswordChange
                                                            }
                                                            onKeyUp={
                                                                onPasswordMatchKeyUp
                                                            }
                                                            required
                                                            data-testid="confirm-password-text"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter new
                                                            password again.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    {showPasswordvalidation ? (
                                                        <PasswordValidationdiv>
                                                            {isPasswordContainLowerLetter ? (
                                                                <ValidPasswordSpan>
                                                                    ✓ Password
                                                                    must contain
                                                                    a lower case
                                                                    letter
                                                                </ValidPasswordSpan>
                                                            ) : (
                                                                <InValidPasswordSpan>
                                                                    ✖ Password
                                                                    must contain
                                                                    a lower case
                                                                    letter
                                                                </InValidPasswordSpan>
                                                            )}

                                                            {isPasswordContainSpecialChar ? (
                                                                <ValidPasswordSpan>
                                                                    ✓ Password
                                                                    must contain
                                                                    a special
                                                                    character
                                                                </ValidPasswordSpan>
                                                            ) : (
                                                                <InValidPasswordSpan>
                                                                    ✖ Password
                                                                    must contain
                                                                    a special
                                                                    character
                                                                </InValidPasswordSpan>
                                                            )}

                                                            {isPasswordContainNumber ? (
                                                                <ValidPasswordSpan>
                                                                    ✓ Password
                                                                    must contain
                                                                    a number
                                                                </ValidPasswordSpan>
                                                            ) : (
                                                                <InValidPasswordSpan>
                                                                    ✖ Password
                                                                    must contain
                                                                    a number
                                                                </InValidPasswordSpan>
                                                            )}
                                                            {isPasswordLength10Char ? (
                                                                <ValidPasswordSpan>
                                                                    ✓ Password
                                                                    must contain
                                                                    at least 10
                                                                    characters
                                                                </ValidPasswordSpan>
                                                            ) : (
                                                                <InValidPasswordSpan>
                                                                    ✖ Password
                                                                    must contain
                                                                    at least 10
                                                                    characters
                                                                </InValidPasswordSpan>
                                                            )}
                                                            {isPasswordMatched ? (
                                                                <ValidPasswordSpan>
                                                                    ✓ Password
                                                                    must match
                                                                </ValidPasswordSpan>
                                                            ) : (
                                                                <InValidPasswordSpan>
                                                                    ✖ Password
                                                                    must match
                                                                </InValidPasswordSpan>
                                                            )}
                                                        </PasswordValidationdiv>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <ChangePasswordButton
                                                        variant="success"
                                                        type="submit"
                                                        data-testid="change-password-button"
                                                    >
                                                        Change Password
                                                    </ChangePasswordButton>
                                                </Form>
                                            </ForgotContainer>
                                        ) : (
                                            ''
                                        )}
                                    </ChangePasswordWrapper>
                                </PasswordBodyContainerReposition>
                            </PasswordBodyContainer>
                        </ContentContainer>
                    </MainContainer>
                </ParentMainContainer>
            </BodyContainer>
        </FullPageContainer>
    )
}

export default ChangePasswordContainer
