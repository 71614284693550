import {
    Box,
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../../config/hooks'
import { LoadingState } from '../../../models/loadingState'
import { colors } from '../../../utils/colors'
import { defaultSelectedDomainDownloadsDist } from '../DashboardPanel'
import DashboardCard from '../shared/cards/DashboardCard'
import BarChart from '../shared/charts/BarChart'
import ColumnChart, { ColumnChartData } from '../shared/charts/ColumnChart'

const height = 400

const chartTitleSx = { marginLeft: '1rem', marginBlock: '0.5rem' }

const modelsDownloadByDomainCardGridSx = { paddingRight: '1rem' }

const domainsListTitleSx = { marginBlock: '0.5rem', fontWeight: 700 }

const domainsListSx = {
    padding: 0,
    maxHeight: '332px',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: `${colors.classicBlue} ${colors.lightGray200}`,
}

const domainsListItemSx = { padding: 0 }

const downloadsGridSx = { marginLeft: '1rem' }

const downloadsAnalyticsTitleSx = {
    display: 'inline',
    marginRight: '0.375rem',
    fontWeight: 700,
}

const downloadsAnalyticsTitleSuffixSx = {
    ...downloadsAnalyticsTitleSx,
    marginLeft: '0.375rem',
}

const downloadsDomainSelectSx = {
    '& .MuiInputBase-input': {
        fontSize: '1rem',
    },
}

const downloadsDomainSelectMenuProps = {
    sx: {
        '& .MuiMenu-list': {
            padding: 0,
        },
    },
}

const ListSkeletonStack = () => (
    <Stack spacing={1}>
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
    </Stack>
)

const downloadsByDomainChartDataHeaders: [string, string, { role: string }] = [
    'Domain',
    '# Downloads',
    { role: 'annotation' },
]

type DomainListItem = {
    name: string
    checked: boolean
}

const topDownloadsChartHeaders: [string, string, { role: string }] = [
    'Model',
    '# Downloads',
    { role: 'annotation' },
]

interface ModelDownloadsByDomainCardProps {
    selectedDownloadsDomain: string
    handleSelectedDownloadsDomainChange: (event: SelectChangeEvent) => void
}

const ModelDownloadsByDomainCard: FC<ModelDownloadsByDomainCardProps> = ({
    selectedDownloadsDomain,
    handleSelectedDownloadsDomainChange,
}) => {
    const downloadsData = useAppSelector<any>(
        (state) => state.getModelsDownloadsResult.data
    )
    const downloadsLoading = useAppSelector(
        (state) => state.getModelsDownloadsResult.loading
    )

    const [chartData, setChartData] = useState<ColumnChartData>([
        downloadsByDomainChartDataHeaders,
    ])
    const [domainsList, setDomainsList] = useState<DomainListItem[]>([])

    const [chartModelDomainData, setChartModelDomainData] =
        useState<ColumnChartData>([topDownloadsChartHeaders])

    useEffect(() => {
        if (downloadsData !== null) {
            const chartDataItems = Object.entries(
                downloadsData?.distribution?.modelDownloadsPerDomain as {
                    [key: string]: { [key: string]: number }
                }
            ).map(([key, value]) => {
                let count = Object.values(value).reduce(
                    (acc, count) => acc + count,
                    0
                )
                return [key, count, count]
            })

            setChartData([downloadsByDomainChartDataHeaders, ...chartDataItems])
            setDomainsList(
                Object.keys(
                    downloadsData?.distribution?.modelDownloadsPerDomain
                ).map((name) => ({
                    name,
                    checked: true,
                }))
            )
        }
    }, [downloadsData])

    useEffect(() => {
        if (downloadsData !== null) {
            const checkedDomainsNames = domainsList
                .filter(({ checked }) => checked)
                .map(({ name }) => name)
            const filteredAnalyticsResultData = Object.entries(
                downloadsData?.distribution?.modelDownloadsPerDomain as {
                    [key: string]: number
                }
            )
                .filter(([name, _]) => checkedDomainsNames.includes(name))
                .map(([key, value]) => {
                    let count = Object.values(value).reduce(
                        (acc, count) => acc + count,
                        0
                    )
                    return [key, count, count]
                })

            setChartData([
                downloadsByDomainChartDataHeaders,
                ...filteredAnalyticsResultData,
            ])
        }
    }, [domainsList])

    useEffect(() => {
        if (
            downloadsData?.distribution?.modelDownloadsPerDomain &&
            selectedDownloadsDomain != 'all'
        ) {
            const newChartData = Object.entries(
                downloadsData?.distribution?.modelDownloadsPerDomain[
                    selectedDownloadsDomain
                ] as {
                    [key: string]: number
                }
            )
                .filter(([key, _]) => key !== '')
                .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                .map(([name, value]) => [name, value, value])

            setChartModelDomainData([topDownloadsChartHeaders, ...newChartData])
        }

        if (
            downloadsData?.distribution?.modelDownloadsPerDomain &&
            selectedDownloadsDomain == 'all'
        ) {
            const newAllData = Object.values(
                downloadsData?.distribution?.modelDownloadsPerDomain as {
                    [key: string]: { [key: string]: number }
                }
            ).reduce((count, model) => {
                Object.entries(model).forEach(
                    ([key, value]) => (count[key] = (count[key] || 0) + value)
                )
                return count
            }, {} as { [key: string]: number })

            const newChartData = Object.entries(newAllData)
                .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                .map(([name, value]) => [name, value, value])

            setChartModelDomainData([topDownloadsChartHeaders, ...newChartData])
        }
    }, [selectedDownloadsDomain, downloadsData])

    const onDomainListItemChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target
        setDomainsList((prevList) =>
            prevList.map((item) =>
                item.name === id ? { ...item, checked } : item
            )
        )
    }

    const domainsListItems = domainsList.map(({ name, checked }) => (
        <ListItem sx={domainsListItemSx} key={`li-${name}`}>
            <Checkbox
                size="small"
                checked={checked}
                id={name}
                onChange={onDomainListItemChange}
            />
            <ListItemText primary={name} />
        </ListItem>
    ))

    const domainsMenuItems = domainsList.map(({ name }) => (
        <MenuItem value={name} key={`downloads-${name}`}>
            {name}
        </MenuItem>
    ))

    const isAnalyticsDataLoading = downloadsLoading === LoadingState.Pending

    const loadingStates = [downloadsLoading]

    const isDomainSearchDistSelectDisabled = loadingStates.some(
        (state) => state === LoadingState.Pending
    )

    return (
        <DashboardCard>
            <Typography variant="h6" sx={chartTitleSx}>
                Model Downloads
            </Typography>
            <Grid container spacing={2} sx={modelsDownloadByDomainCardGridSx}>
                <Grid item xs={12} md={9} sx={{ height: `${height + 24}px` }}>
                    <ColumnChart
                        chartData={chartData}
                        dataLoadingState={downloadsLoading}
                        height={height}
                    />
                </Grid>
                <Grid item md={3}>
                    <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
                        <Typography variant="body2" sx={domainsListTitleSx}>
                            Domains
                        </Typography>
                        {isAnalyticsDataLoading ? (
                            <ListSkeletonStack />
                        ) : (
                            <List dense sx={domainsListSx}>
                                {domainsListItems}
                            </List>
                        )}
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    columns={12}
                    xs={12}
                    spacing={2}
                    sx={downloadsGridSx}
                >
                    <Grid item xs={12}>
                        <Typography sx={downloadsAnalyticsTitleSx}>
                            Models downloaded by users from
                        </Typography>
                        <Select
                            variant="standard"
                            value={selectedDownloadsDomain}
                            sx={downloadsDomainSelectSx}
                            MenuProps={downloadsDomainSelectMenuProps}
                            disabled={isDomainSearchDistSelectDisabled}
                            onChange={handleSelectedDownloadsDomainChange}
                        >
                            <MenuItem
                                value={defaultSelectedDomainDownloadsDist}
                            >
                                all domains
                            </MenuItem>
                            {domainsMenuItems}
                        </Select>
                        {selectedDownloadsDomain !== 'all' && (
                            <Typography sx={downloadsAnalyticsTitleSuffixSx}>
                                domain
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <BarChart
                            chartData={chartModelDomainData}
                            dataLoadingState={downloadsLoading}
                            fixedHeight
                        />
                    </Grid>
                </Grid>
            </Grid>
        </DashboardCard>
    )
}

export default ModelDownloadsByDomainCard
