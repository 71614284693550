import React, { MouseEventHandler, ReactNode } from 'react'
import styled from 'styled-components'
import Download from '../icons/download'
import LineArrow from '../icons/lineArrow'
import Share from '../icons/share'
import ViewOn from '../icons/viewOn'

const buttonPalette = {
    darkDefault: '#0068B5',
    darkActive: '#004A86',
    lightDefault: '#00C7FD',
    lightActive: '#7BDEFF',
    lightText: '#00285A',
    disabledLight: '#AEAEAE',
    disabledDark: '#808080',
}

// Define the types for the props
interface CustomButtonProps {
    text?: string
    styleType?: 'dark' | 'light' | 'darkOutline' | 'lightOutline'
    styleOverride?: React.CSSProperties
    type?: 'button' | 'submit' | 'reset'
    icon?: string
    arrow?: boolean
    wrap?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement>
    href?: string // If the button acts as a link
    children?: ReactNode // For wrapping content in a link or other content
    disabled?: boolean
    'aria-label'?: string // For accessibility
}

// Define styles for the button types and states
const Button = styled.button<{ styleType: string; disabled: boolean }>`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 0;
    border: 1px solid #2a5db0;
    display: inline-flex;
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    // opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

    svg {
        min-width: 16px;
    }

    // Handle different style types and states
    ${({ styleType }) => {
        switch (styleType) {
            case 'dark':
                return `
          background-color: ${buttonPalette.darkDefault};
          color: #fff;
          border-color: transparent;
          &:hover, &:active {
            background-color: ${buttonPalette.darkActive};
          }
          &:active {
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
          }
          &:disabled{
             background-color: ${buttonPalette.disabledLight};
          }

          .arrow{
            line, path{
                stroke: #fff;
                fill: none;
            }
          }

          line, path{
              fill: #fff;
          }

        `
            case 'darkOutline':
                return `
          background-color: transparent;
          color: ${buttonPalette.darkDefault};
          border-color: ${buttonPalette.darkDefault};
          &:hover, &:active {
            color: ${buttonPalette.darkActive};
            border-color: ${buttonPalette.darkActive};
            // box-shadow: 0 0 0 1px ${buttonPalette.darkActive} inset;
          }
          &:active {
            // box-shadow: 0 0 0 1px ${buttonPalette.darkActive} inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
          }
          &:disabled{
            color: ${buttonPalette.disabledDark};
            border-color: ${buttonPalette.disabledDark};
            // box-shadow: 0 0 0 1px ${buttonPalette.disabledDark} inset;
          }

          .arrow{
            line, path{
                stroke:${buttonPalette.darkDefault};
                fill: none;
            }
          }

          line, path{
              fill:${buttonPalette.darkDefault};
          }
              
          &:hover{

            .arrow{
              line, path{
                  stroke:${buttonPalette.darkActive};
                  fill: none;
              }
            }

            line, path{
                fill:${buttonPalette.darkActive};
            }
          }
           
        `
            case 'light':
                return `
            background-color: ${buttonPalette.lightDefault};
            color: ${buttonPalette.lightText};
            border-color: transparent;
            &:hover, &:active {
              background-color: ${buttonPalette.lightActive};
            }
            &:active {
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
            }
            &:disabled {
              color: ${buttonPalette.lightText};
              background-color: ${buttonPalette.disabledLight};
            }

            .arrow{
              line, path{
                  stroke:${buttonPalette.lightText};
                  fill: none;
              }
            }

            line, path{
                fill:${buttonPalette.lightText};
            }
            

          `
            case 'lightOutline':
                return `
            background-color: transparent;
            color: ${buttonPalette.lightDefault};
            border-color: ${buttonPalette.lightDefault};
            &:hover, &:active {
              color: ${buttonPalette.lightActive};
              border-color: ${buttonPalette.lightActive};
            }
            &:active {
              // box-shadow: 0 0 0 1px ${buttonPalette.lightActive} inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
            }
            &:disabled {
              color: ${buttonPalette.disabledDark};
              border-color: ${buttonPalette.disabledDark};
              // box-shadow: 0 0 0 1px ${buttonPalette.disabledDark} inset;
            }

            .arrow{
              line, path{
                  stroke:${buttonPalette.lightDefault};
              }
            }

            line, path{
              fill:${buttonPalette.lightDefault};
            }
                
            &:hover{

            .arrow{
              line, path{
                  stroke:${buttonPalette.lightActive};
              }
            }

              line, path{
                  fill:${buttonPalette.lightActive};
              }
            }
          `
            default:
                return ''
        }
    }}

    &:disabled {
        cursor: not-allowed;
    }
`

const ArrowWrap = styled.div`
    margin-left: 0.5rem;
`

const TextWrapper = styled.span`
    margin: 0 0.5rem;
    white-space: nowrap;
`

const CustomButton: React.FC<CustomButtonProps> = ({
    text,
    styleType = 'dark',
    styleOverride = {},
    icon,
    type = 'button',
    arrow = false,
    wrap = false,
    onClick,
    href,
    children,
    disabled = false,
    'aria-label': ariaLabel,
}) => {
    const renderIcon = () => {
        if (icon) {
            switch (icon) {
                case 'share':
                    return <Share />
                    break
                case 'download':
                    return <Download />
                    break
                case 'viewOn':
                    return <ViewOn />
                    break
                default:
                    break
            }
        }
    }

    if (href) {
        return (
            <a href={href} aria-label={ariaLabel} style={styleOverride}>
                <Button
                    styleType={styleType}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {icon && renderIcon()}
                    <TextWrapper style={wrap ? { whiteSpace: 'wrap' } : {}}>
                        {text}
                    </TextWrapper>
                    {arrow && (
                        <ArrowWrap>
                            <LineArrow />
                        </ArrowWrap>
                    )}
                </Button>
            </a>
        )
    }

    return (
        <Button
            style={styleOverride}
            styleType={styleType}
            type={type}
            disabled={disabled}
            onClick={onClick}
            aria-label={ariaLabel}
        >
            {icon && renderIcon()}
            <TextWrapper style={wrap ? { whiteSpace: 'wrap' } : {}}>
                {text}
            </TextWrapper>
            {arrow && (
                <ArrowWrap>
                    <LineArrow />
                </ArrowWrap>
            )}
        </Button>
    )
}

export default CustomButton
