/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { Cache } from '@aws-amplify/cache'
import { fetchAuthSession } from 'aws-amplify/auth'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    background: rgb(255, 255, 255);
    max-height: 35rem;
    max-weidth: 35rem;
`
const OutputContainerTab = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 31.5rem;
    background: transparent;
    color: rgb(248, 248, 242);
    width: 100%;
    padding: 1rem;
`
const RecipeLinksComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: #eff6ff;
`
const RecipeTileLabel = styled.span`
    display: flex;

    text-align: left;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: rgb(0, 153, 236);
    font-size: 1rem;
`
const RecipeTileLinkLabel = styled(RecipeTileLabel)`
    font-style: normal;
    font-size: 0.9rem;
`
const RecipeCardDescriptionComponent = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: column wrap;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 1rem 0;
`
const RecipeCardText = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 1rem;
    justify-content: left;
    margin-bottom: 1rem;
`
const RecipeCardLinkText = styled(RecipeCardText)`
    padding-left: 1rem;
`
const TileLinkTextValue = styled.span`
    padding-right: 0.5rem;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 30rem;
    padding-left: 0.5rem;
`
const RecipeTileValue = styled.span`
    display: flex;
    flex-direction: row;
    flex-flow: column wrap;
    padding-left: 0.5rem;
    overflow-wrap: break-word;
    word-break: break-word;
    color: #000;
`

export interface ISupportProps {
    selectedModelName?: string
}

const RAGContainerPanel: FunctionComponent<ISupportProps> = ({
    selectedModelName,
}: ISupportProps) => {
    const [isUserInSession, setIsUserInSession] = useState(false)

    const getRefreshToken = useCallback(async () => {
        try {
            const response = await fetchAuthSession()
            //console.log(response)
            const access_token = response?.tokens?.accessToken
            if (access_token) {
                Cache.clear()
                setIsUserInSession(true)
            } else {
                localStorage.setItem(
                    'selectedModelName',
                    selectedModelName ? selectedModelName : ''
                )
            }
        } catch (err) {
            console.error(err)
            Cache.clear()
            setIsUserInSession(false)
        }
    }, [])

    useEffect(() => {
        getRefreshToken()
    }, [])
    const LinksImageRender = (
        <span
            style={{
                paddingLeft: '0.2rem',
            }}
        >
            <img
                src="/links.png"
                height="20px"
                width="20px"
                alt="link logo"
                style={{
                    paddingBottom: '0.1rem',
                }}
            />
        </span>
    )
    const renderLinkWithInText = (text: string) => {
        if (
            text &&
            (text.indexOf('https://') > -1 || text.indexOf('http://') > -1)
        ) {
            let subText = ''
            let linkValue = ''
            let startIndex = 0
            let endIndex = 0
            if (text.indexOf('https://') > -1) {
                subText = text.substring(text.indexOf('https://'), text.length)
                startIndex = text.indexOf('https://')
                if (subText.indexOf(' ') > -1) {
                    linkValue = subText.substring(0, subText.indexOf(' '))
                    endIndex = subText.indexOf(' ')
                } else {
                    linkValue = subText
                    endIndex = text.length
                }
            }
            if (text.indexOf('http://') > -1) {
                subText = text.substring(text.indexOf('http://'), text.length)
                startIndex = text.indexOf('http://')
                if (subText.indexOf(' ') > -1) {
                    linkValue = subText.substring(0, subText.indexOf(' '))
                    endIndex = subText.indexOf(' ')
                } else {
                    linkValue = subText
                    endIndex = text.length
                }
            }
            subText = subText.substring(endIndex, subText.length)
            return (
                <span>
                    {text.substring(0, startIndex) + ' '}
                    <a
                        href={!isUserInSession ? '/signIn' : linkValue}
                        target="_blank"
                        rel="noopener"
                        style={{ color: 'rgb(0,153,236)' }}
                    >
                        {linkValue}
                    </a>

                    <span>
                        {subText.indexOf('https://') > -1 ||
                        subText.indexOf('http://') > -1
                            ? renderLinkWithInText(subText)
                            : ' ' + subText}
                    </span>
                </span>
            )
        } else return text
    }
    const renderNewLine = (text: string) => {
        if (text && text.indexOf('\n') > -1) {
            let lines = text.split('\n')
            return (
                <div>
                    {lines.map((textLine: string, index: number) => {
                        return (
                            <div key={`${index}-${textLine}`}>
                                {renderLinkWithInText(textLine)}
                                <br />
                            </div>
                        )
                    })}
                </div>
            )
        } else return renderLinkWithInText(text)
    }

    const renderHyperlink = (linkName: string, refValue: string) => {
        return (
            <RecipeCardLinkText style={{ marginLeft: '1rem' }}>
                <TileLinkTextValue>
                    <a
                        href={!isUserInSession ? '/signIn' : refValue}
                        target={!isUserInSession ? '_self' : '_blank'}
                        rel="noopener"
                        style={{
                            color: '#0099EC',
                        }}
                    >
                        {linkName}
                    </a>
                    {LinksImageRender}
                </TileLinkTextValue>
            </RecipeCardLinkText>
        )
    }

    const valueProp = `This is a reference implementation of an Intel optimized demonstration deployment of RAG. This provides a framework for customers to easily leverage Intel PyTorch optimizations within their RAG implementation.`

    const howToApplyText = `We provide source code for all components required to create a chat bot using Intel® Xeon® Scalable Processors and Intel® Gaudi®. This includes scripts to ingest data, Dockerfiles, and Docker Compose files to run required components. Please refer to \"GenAI Examples\", \"GenAI Examples Container \" and \"Text Generation Inference on Intel® Gaudi®\" under the \"Links\" section for more details.\n\nContainers are also provided on Docker Hub for the LangChain component and text generation inference (TGI). Please refer to the \"GenAI Container Examples\" link provided under the \"Links\" section\n\nThe complete set of instructions is listed here: https://github.com/opea-project/GenAIExamples/blob/main/ChatQnA/README.md\n\nThe reference design also requires community versions of Redis and NGINX containers. Please refer tot he NGINX and Redis containers under the \"Links\" section for more details.\n\nThis reference design requires access to a machine with Intel® Gaudi® .\nThis can be accessed on the Intel® Tiber™ Developer Cloud : https://www.intel.com/content/www/us/en/developer/tools/devcloud/services.html#sign-up`

    return (
        <OutputInnerContainer>
            <OutputContainerTab>
                <RecipeLinksComponent>
                    <RecipeTileLinkLabel style={{ fontSize: '1.1rem' }}>
                        Links:
                    </RecipeTileLinkLabel>
                    {renderHyperlink(
                        'GenAI Examples Container',
                        'https://hub.docker.com/r/intel/gen-ai-examples/'
                    )}
                    {renderHyperlink(
                        'GenAI Examples',
                        'https://github.com/opea-project/GenAIExamples/blob/main/README.md'
                    )}
                    {renderHyperlink(
                        'NGINX Container',
                        'https://hub.docker.com/_/nginx'
                    )}
                    {renderHyperlink(
                        'Redis Container',
                        'https://hub.docker.com/_/redis'
                    )}
                    {renderHyperlink(
                        'Text Generation Inference on Intel® Gaudi® ',
                        'https://github.com/huggingface/tgi-gaudi'
                    )}
                </RecipeLinksComponent>
                <RecipeCardDescriptionComponent>
                    <RecipeCardText>
                        <RecipeTileLabel>{'Platform: '}</RecipeTileLabel>
                        <RecipeTileValue>
                            4th Gen Intel® Xeon® Scalable Processors, 5th Gen
                            Intel® Xeon® Scalable Processors, Intel® Gaudi® 2
                        </RecipeTileValue>
                    </RecipeCardText>
                    <RecipeCardText>
                        <RecipeTileLabel>{'Feature: '}</RecipeTileLabel>
                        <RecipeTileValue>
                            Intel® AVX-512 VNNI, Intel® AMX
                        </RecipeTileValue>
                    </RecipeCardText>

                    <RecipeCardText>
                        <RecipeTileLabel>
                            {'Value Proposition: '}
                        </RecipeTileLabel>
                        <RecipeTileValue>
                            {renderNewLine(valueProp)}
                        </RecipeTileValue>
                    </RecipeCardText>
                    <RecipeCardText>
                        <RecipeTileLabel>{'How To Apply: '}</RecipeTileLabel>
                        <RecipeTileValue>
                            {renderNewLine(howToApplyText)}
                        </RecipeTileValue>
                    </RecipeCardText>
                </RecipeCardDescriptionComponent>
            </OutputContainerTab>
        </OutputInnerContainer>
    )
}

export default RAGContainerPanel
