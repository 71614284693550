import { API_SERVICE_URL } from '../config/service'

import api from './auth/client'
import apiNoAuth from './auth/noAuthClient'

const serviceConfig = require('../config/service.json')

interface TimeObj {
    fromDate: string
    toDate: string
}
class AnalyticsService {
    postDownloadEvent(payload: any) {
        const url = `${API_SERVICE_URL}${serviceConfig.trackDownloadEvent}/model`
        return apiNoAuth.post(url, payload)
    }

    getDashboardData(args: any) {
        const { fromDate, toDate, customers } = args
        let url = `${API_SERVICE_URL}${serviceConfig.dashboard}?from=${fromDate}`
        if (toDate) url = `${url}&toDate=${toDate}`
        if (customers) url = `${url}&accesscontrol=${customers.join(',')}`
        return api.get(url)
    }

    getAnalytics(args: TimeObj) {
        const { fromDate } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/search-by-count?since=${fromDate}`
        return api.get(url)
    }

    getSearchDist(args: TimeObj) {
        const { fromDate } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/search-by-count?since=${fromDate}`
        return api.get(url)
    }

    getSearchMisses(args: TimeObj) {
        const { fromDate } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/missed-searches?since=${fromDate}`
        return api.get(url)
    }

    getModelUsage(args: TimeObj) {
        const { fromDate } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/models/usage?from=${fromDate}`
        return api.get(url)
    }

    getUniqueLogins(fromDate: string) {
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/uniquelogins?&from=${fromDate}`
        return api.get(url)
    }

    getUniqueLoginsWithToDate(timeObj: TimeObj) {
        const { fromDate, toDate } = timeObj
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/uniquelogins?from=${fromDate}&to=${toDate}`
        return api.get(url)
    }

    getClickCounts(args: any) {
        const { fromDate, toDate, recipeId } = args
        let url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/clickcounts?model=${recipeId}`
        if (fromDate) url = `${url}&from=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`

        return api.get(url)
    }

    getEngagedUsers(args: TimeObj) {
        const { fromDate: from, toDate: to } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/engaged`
        return api.get(url, {
            params: {
                'source-app': 'ai-catalog',
                from,
                to,
            },
        })
    }

    getDisengagedUsers(args: TimeObj) {
        const { fromDate: from, toDate: to } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/disengaged`
        return api.get(url, {
            params: {
                'source-app': 'ai-catalog',
                from,
                to,
            },
        })
    }

    getFirstTimeCustomers(args: TimeObj) {
        const { fromDate: from, toDate: to } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/first-time-customers`
        return api.get(url, {
            params: {
                'source-app': 'ai-catalog',
                from,
                to,
            },
        })
    }

    getUserDomainDistribution(args: TimeObj) {
        const { fromDate } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/user-domain-distribution?&since=${fromDate}`
        return api.get(url)
    }

    getModelsDownloads(args: TimeObj) {
        const { fromDate, toDate } = args
        const url = `${API_SERVICE_URL}${serviceConfig.aiModelDownloads}?from=${fromDate}&to=${toDate}`
        return api.get(url)
    }
}

export default new AnalyticsService()
