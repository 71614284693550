/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import { Amplify } from 'aws-amplify'
import { getCurrentUser, signInWithRedirect } from 'aws-amplify/auth'
import { FunctionComponent, useEffect } from 'react'
import { Alert, Button, Form, FormLabel } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import stringUtils from '../../lib/stringUtils'
import amplify_conf from '../../services/auth/amplify'
import { AuthenticationContainerLogic } from './authenticationContainer.hook'

const AuthPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`
const AuthHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const AuthBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: #fff;
    padding-bottom: 10rem;
`

const AuthBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const AuthPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    max-width: 100%;
`
const IntelLogoStyling = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
`
const HeaderContainer = styled.div`
    display: flex;
    //background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 2rem;
    justify-content: left;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`
const LoginContainerSSOPanel = styled.div`   
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 0%);
    border-radius: 0;
    background: transparent;
    margin-bottom-3rem;
    justify-content: center;
`
const HExternalLoginPanel = styled.div`
    background: transparent;
    display: none;
    @media screen and (max-width: 600px) {
        display: block;
    }
`
const HSeparatorDiv = styled.div`
    justify-content: center;
    padding: 30px;
    @media screen and (max-width: 600px) {
        border-bottom-style: solid;
        border-bottom-color: rgb(255, 222, 173);
        border-bottom-width: 2px;
        margin-bottom: -2px;
    }
`
const ExternalLoginContainerPanel = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 30px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 0%);
    border-radius: 0;
    background: transparent;
    @media screen and (min-width: 601px) {
        border-left-style: solid;
        border-left-color: rgb(255, 222, 173);
        border-left-width: 2px;
        margin-left: -2px;
    }
`
const VExternalLoginPanel = styled.div`
    background: transparent;
    display: none;
    @media screen and (min-width: 601px) {
        display: block;
    }
`
const HorPanel = styled.div`
    display: none;
    @media screen and (max-width: 600px) {
        font-size: 16px;
        display: block;
        position: absolute;
        background-color: #fff;
        text-align: center;
        color: #a3a3a3;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 0.2rem;
        margin-left: 35%;
    }
`
const VorPanel = styled.div`
    @media screen and (min-width: 601px) {
        font-size: 16px;
        display: block;
        position: absolute;
        background-color: #fff;
        text-align: center;
        color: #a3a3a3;
        padding-top: 15px;
        padding-bottom: 15px;
        top: 40%;
        margin-left: -8px;
    }
`
const IdpDescriptionPanel = styled.span`
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
`
const SignInButton = styled(Button)`
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0px 10px 0px;
    height: 40px;
    width: 100%;
    color: #fff;
    background-color: #ffd100;
    border-color: #ffd100;
    max-width: 25rem;
    border-radius: 0px;
`
const IntelSSOButton = styled(Button)`
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    color: #fff;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
    max-width: 25rem;
    border-radius: 0px;
    margin-bottom: 3rem;
`
const TextLabel = styled(Form.Label)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    font-weight: 400;
`

const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    max-width: 25rem;
`
const SSOTextSpan = styled.span`
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
    vertical-align: middle;
`
const InnerAuthPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
`
const HeadingLabel = styled(FormLabel)`
    display: flex;
    color: ${(props) => props.theme.color.text.heading};
    font-weight: 500;
`
const BiggerHeadingLabel = styled(HeadingLabel)`
    ${(props) => props.theme.typography.xLarge}
    margin-bottom: 2rem;
`
const StyledAnchor = styled.a`
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    color: ${(props) => props.theme.color.text.heading};
`
const NeedAnAccountContainer = styled.div`
    display: flex;
    margin-top: 2rem;
`
const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;
`
const VerticalCenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
`

export interface IAuthenticationContainerProps {}

export interface IAuthenticationContainerLogicProps
    extends IAuthenticationContainerProps {
    invitationKey: string | null
    isAPIAuthTokenRequested: boolean | false
}

const AuthenticationContainer: FunctionComponent<
    IAuthenticationContainerProps
> = () => {
    let invitationKey = localStorage.getItem('invitationKey')
    const homeURL = stringUtils.isNullOrUndefinedOrEmpty(invitationKey)
        ? '/'
        : `/?invitationKey=${invitationKey}`

    Amplify.configure(amplify_conf)
    const provider = {
        custom: 'Intel',
    }

    const {
        signInError,
        validated,
        onSubmit,
        onUserEmailChange,
        onUserPasswordChange,
    } = AuthenticationContainerLogic({
        invitationKey,
    } as IAuthenticationContainerLogicProps)

    let navigate = useNavigate()

    useEffect(() => {
        let invitationKey = localStorage.getItem('invitationKey')
        const fetchUserData = async () => {
            try {
                await getCurrentUser()
                console.log('user logged in already')
                if (!stringUtils.isNullOrUndefinedOrEmpty(invitationKey)) {
                    navigate('/invitation')
                } else {
                    navigate('/')
                }
            } catch {
                console.log('user not logged in. sign in required!')
            }
        }
        fetchUserData()
    }, [])

    const externalSignInComponent = (
        <ExternalLoginContainerPanel>
            <Form.Group>
                <BiggerHeadingLabel>
                    Sign in with your email and password
                </BiggerHeadingLabel>
            </Form.Group>
            <Form
                noValidate
                validated={validated}
                onSubmit={onSubmit}
                id="signInForm"
            >
                <Form.Group>
                    <AlertComponent
                        style={{ width: '100%' }}
                        variant="danger"
                        show={signInError ? true : false}
                        data-testid="error-text"
                    >
                        {'Error! ' + signInError?.message}
                    </AlertComponent>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <HeadingLabel data-testid="email-address-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Email address{' '}
                    </HeadingLabel>
                    <TextBoxWrapper
                        type="email"
                        placeholder="Enter email..."
                        onChange={onUserEmailChange}
                        required
                        data-testid="email-address-text"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter an email address in format name@xyg.com.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <HeadingLabel data-testid="choose-password-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Choose a password{' '}
                    </HeadingLabel>
                    <TextBoxWrapper
                        id="userPassword"
                        name="userPassword"
                        type="password"
                        placeholder="Enter your password..."
                        autoComplete="new-password"
                        onChange={onUserPasswordChange}
                        required
                        data-testid="choose-password-text"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter your password.
                    </Form.Control.Feedback>
                    <StyledAnchor href="/forgotPassword">
                        Forgot your password?
                    </StyledAnchor>
                </Form.Group>

                <SignInButton
                    variant="warning"
                    type="submit"
                    onClick={onSubmit}
                    data-testid="signin-button"
                >
                    Sign in
                </SignInButton>
            </Form>
            <NeedAnAccountContainer>
                <VerticalCenterContainer>
                    <HorizontalContainer>
                        <HeadingLabel>Need an account?</HeadingLabel>
                        <StyledAnchor href="/createAccount">
                            Sign up
                        </StyledAnchor>
                    </HorizontalContainer>
                    <HorizontalContainer>
                        <HeadingLabel>
                            Already have account confirmation code?
                        </HeadingLabel>
                        <StyledAnchor href="/activateAccount">
                            Activate your account here
                        </StyledAnchor>
                    </HorizontalContainer>
                </VerticalCenterContainer>
            </NeedAnAccountContainer>
        </ExternalLoginContainerPanel>
    )

    return (
        <AuthPanelContainer>
            <HeaderContainer>
                <IntelLogoStyling>
                    <img
                        src="/Intel_energyblue_logo.png"
                        height="65px"
                        width="171px"
                        alt="intel logo"
                        style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                        onClick={() => navigate(homeURL)}
                    />
                </IntelLogoStyling>
            </HeaderContainer>
            <AuthHorizontalContainer>
                <AuthBodyContainer>
                    <AuthBodyContainerReposition>
                        <InnerAuthPanelContainer>
                            <LoginContainerSSOPanel className="col-md-3">
                                <HSeparatorDiv>
                                    <BiggerHeadingLabel>
                                        Intel employee sign in here
                                    </BiggerHeadingLabel>
                                    <IntelSSOButton
                                        variant="info"
                                        type="submit"
                                        onClick={() =>
                                            signInWithRedirect({
                                                provider,
                                            })
                                        }
                                        aria-label="Intel"
                                    >
                                        <img
                                            src="/intel_logo_classicblue.svg"
                                            height="25px"
                                            width="50px"
                                            alt="intel logo"
                                            style={{ verticalAlign: 'middle' }}
                                        />{' '}
                                        <SSOTextSpan>SSO</SSOTextSpan>
                                    </IntelSSOButton>
                                    <HorPanel>or</HorPanel>
                                </HSeparatorDiv>
                                <HExternalLoginPanel>
                                    {externalSignInComponent}
                                </HExternalLoginPanel>
                            </LoginContainerSSOPanel>

                            <VExternalLoginPanel>
                                <VorPanel>or</VorPanel>
                                {externalSignInComponent}
                            </VExternalLoginPanel>
                        </InnerAuthPanelContainer>
                    </AuthBodyContainerReposition>
                </AuthBodyContainer>
            </AuthHorizontalContainer>
        </AuthPanelContainer>
    )
}

export default AuthenticationContainer
