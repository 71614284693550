/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import { Dispatch, FunctionComponent } from 'react'
import { Button, FormLabel } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ContainerWrapper from '../layouts/containerWrapper'

const PageNotFoundComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const PageLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xxLarge}
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 500;
    margin-top: 2rem;
`
const BackButton = styled(Button)`
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 1.25rem;
    background-color:#008000;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    margin-top: 3rem;
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
`

export interface PageNotFoundPanelProps {}

const PageNotFoundPanel: FunctionComponent<PageNotFoundPanelProps> & {
    defaultProps: Partial<PageNotFoundPanelProps>
} = ({}: PageNotFoundPanelProps) => {
    let navigate = useNavigate()

    const bodyContent = (isUserLoggedIn: boolean) => {
        return (
            <>
                <PageNotFoundComponent>
                    <PageLabel>
                        404 error, Oops! Page not found. We can't find the page
                        you're looking for.
                    </PageLabel>
                </PageNotFoundComponent>

                <PageNotFoundComponent>
                    <img src="/404.png" width="50%" height="auto" />
                </PageNotFoundComponent>
                <PageNotFoundComponent>
                    <BackButton
                        variant="primary"
                        type="submit"
                        onClick={() => navigate('/explore')}
                    >
                        Go Back Home
                    </BackButton>
                </PageNotFoundComponent>
            </>
        )
    }

    return (
        <ContainerWrapper>
            {/* isUserLoggedIn passed as argument in child function from ContainerWrapper */}
            {(isUserLoggedIn) => bodyContent(isUserLoggedIn)}
        </ContainerWrapper>
    )
}

PageNotFoundPanel.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {}

const mapStateToProps = (state: any) => {}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(PageNotFoundPanel)
