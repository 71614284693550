import { Paper, Typography } from '@mui/material'
import { FC, PropsWithChildren, ReactElement } from 'react'
import { colors } from '../../../../utils/colors'

const dashboardCardPaperSx = {
    width: '100%',
    border: `1px solid ${colors.lightGray400}`,
    boxShadow: 'none',
    padding: '1rem',
    position: 'relative',
}

const dashboardCardTitleSx = { marginLeft: '1rem', marginBlock: '0.5rem' }

interface DashboardCardProps extends PropsWithChildren<any> {
    children?: ReactElement | ReactElement[]
    title?: string
    dataTestId?: string
}

const DashboardCard: FC<DashboardCardProps> = ({
    children,
    title,
    dataTestId,
}) => (
    <Paper sx={dashboardCardPaperSx}>
        {title && (
            <Typography
                variant="h6"
                sx={dashboardCardTitleSx}
                data-testid={dataTestId}
            >
                {title}
            </Typography>
        )}
        {children}
    </Paper>
)

export default DashboardCard
