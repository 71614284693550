import { API_SERVICE_URL } from '../config/service'
import api from './auth/client'
const serviceConfig = require('../config/service.json')

class ComposeSolutionService {
    async composeGeneration(qParam: any) {
        var url = API_SERVICE_URL + serviceConfig.compose + qParam
        return api.get(url)
    }
}
export default new ComposeSolutionService()
