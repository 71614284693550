import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../config/hooks'
import DashboardCard from '../shared/cards/DashboardCard'
import DonutChart, { DonutChartData } from '../shared/charts/DonutChart'

const appSearchMissesChartHeaders: [string, string] = ['Search Model', 'Count']

const ModelMissesSearchesCard = () => {
    const searchMissesData = useAppSelector(
        (state: any) => state.getSearchMissesResult.data
    )
    const searchMissesLoading = useAppSelector(
        (state: any) => state.getSearchMissesResult?.loading
    )

    const [chartData, setChartData] = useState<DonutChartData>([
        appSearchMissesChartHeaders,
    ])

    useEffect(() => {
        if (searchMissesData !== null && searchMissesData?.missedSearches) {
            const appSearchesData = searchMissesData?.missedSearches.map(
                (item: any) => [item.search, item.count]
            )
            setChartData([appSearchMissesChartHeaders, ...appSearchesData])
        }
    }, [searchMissesData])

    return (
        <DashboardCard
            title="Top Model Search Misses"
            dataTestId="Top Model Searches-label"
        >
            <DonutChart
                chartData={chartData}
                dataLoadingState={searchMissesLoading}
            />
        </DashboardCard>
    )
}

export default ModelMissesSearchesCard
