import { Icon } from '@iconify/react'
import { IconButton } from '@mui/material'
import { CSSProperties, MouseEvent, PropsWithChildren, useState } from 'react'

const copyIconBtnSx = {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: '#fff',
    fontSize: 14,
}

const customPreWrapperStyle: CSSProperties = {
    position: 'relative',
    borderRadius: '0.25rem',
    padding: '1rem',
    backgroundColor: '#262626',
    color: '#ffffff',
    marginBottom: '1.5rem',
}

const customPreStyle: CSSProperties = {
    marginRight: '5.5rem',
    scrollbarWidth: 'thin',
    marginBottom: 0,
    whiteSpace: 'pre',
}

const CopyCodeButton = () => {
    const [copyBtnState, setCopyBtnState] = useState('idle')

    const onClick = (event: MouseEvent) => {
        if (copyBtnState === 'idle') {
            const copyButtonElement = event.currentTarget
            const codeSnippetElement = copyButtonElement.nextElementSibling
            if (codeSnippetElement) {
                const codeSnippetText = codeSnippetElement.textContent

                navigator.clipboard
                    .writeText(codeSnippetText ?? '')
                    .then(() => {
                        setCopyBtnState('success')
                    })
                    .catch((error) => {
                        setCopyBtnState('error')
                        console.error(error)
                    })
                    .finally(() => {
                        setTimeout(() => {
                            setCopyBtnState('idle')
                        }, 1000)
                    })
            }
        }
    }

    return (
        <IconButton
            sx={copyIconBtnSx}
            disableRipple={true}
            disableFocusRipple={true}
            onClick={onClick}
        >
            {copyBtnState === 'idle' && <Icon icon="bi:copy" />}
            {copyBtnState === 'success' && <Icon icon="bi:check2" />}
            {copyBtnState === 'error' && <Icon icon="bi:x" />}
            <span style={{ marginLeft: '0.5rem' }}>
                {copyBtnState === 'idle' && 'Copy'}
                {copyBtnState === 'success' && 'Copied!'}
                {copyBtnState === 'error' && 'Error while copying'}
            </span>
        </IconButton>
    )
}

const CustomPre = ({ children }: PropsWithChildren) => (
    <div style={customPreWrapperStyle}>
        {window.isSecureContext && <CopyCodeButton />}
        <pre style={{ ...customPreStyle }}>{children}</pre>
    </div>
)

const CustomCode = ({ children }: PropsWithChildren) => <code>{children}</code>

const CustomParagraph = ({ children }: PropsWithChildren) => <p>{children}</p>

export const customMarkdownComponents = {
    code: CustomCode,
    pre: CustomPre,
    p: CustomParagraph,
}
