/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { Dispatch } from '@reduxjs/toolkit'
import { FunctionComponent, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'
import ErrorPanel from '../../../components/errorPanel'
import { IAiModelCardProps } from '../../aiModelCard'
import TranslationInputOutput from './translationInputOutput'

const ViewPortControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 15rem;
    margin: 1.6rem 1.5rem;
`

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({})

const mapStateToProps = (state: any) => {
    return {
        chatCompletionError: state.postChatCompletionResult.error,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type ITranslationPanelProps = ReduxProps & {
    model: IAiModelCardProps
    setCurrentPromptMessage: any
}

const TranslationPanel: FunctionComponent<ITranslationPanelProps> = ({
    model,
    chatCompletionError,
    setCurrentPromptMessage,
}: ITranslationPanelProps) => {
    const [inputLanguage, setInputLanguage] = useState<any | undefined>({
        value: 'English',
        label: 'English',
    })
    const [outputLanguage, setOutputLanguage] = useState<any | undefined>({
        value: 'Spanish',
        label: 'Spanish',
    })

    const inputDefaultText = 'I love computers'
    const outputDefaultText = 'Amo las computadoras.'

    const [inputText, setInputText] = useState<string>(inputDefaultText)
    const [outputText, setOutputText] = useState<string>(outputDefaultText)

    return (
        <ViewPortControl>
            <Alert
                style={{
                    width: '100%',
                    backgroundColor: 'rgb(193, 166, 242, 0.2)',
                    borderColor: 'rgb(193, 166, 242, 0.2)',
                    display: 'flex',
                    marginTop: '1rem',
                    justifyContent: 'center',
                    borderRadius: '0',
                }}
                variant="primary"
                show={chatCompletionError}
            >
                <ErrorPanel />
            </Alert>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        height: '100%',
                    }}
                >
                    <div id="chatDiv">
                        <TranslationInputOutput
                            model={model}
                            input={true}
                            setCurrentPromptMessage={setCurrentPromptMessage}
                            inputLanguage={inputLanguage}
                            outputLanguage={outputLanguage} // needed for setCurrentPromptMessage
                            setInputLanguage={setInputLanguage}
                            inputText={
                                inputText === '' ? inputDefaultText : inputText
                            }
                            setInputText={setInputText}
                        ></TranslationInputOutput>
                    </div>
                </div>

                <div
                    id="seprator"
                    style={{
                        width: '1px',
                        margin: '0 1rem',
                        backgroundColor: 'rgb(101, 49, 113)',
                    }}
                ></div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        height: '100%',
                    }}
                >
                    <div id="outputtDiv">
                        <TranslationInputOutput
                            model={model}
                            outputLanguage={outputLanguage}
                            setOutputLanguage={setOutputLanguage}
                            outputText={
                                outputText === ''
                                    ? outputDefaultText
                                    : outputText
                            }
                            setOutputText={setOutputText}
                        ></TranslationInputOutput>
                    </div>
                </div>
            </div>
        </ViewPortControl>
    )
}

export default connector(TranslationPanel)
