import { FunctionComponent } from 'react'
import { Alert, Button, Form, FormLabel, Popover } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import stringUtils from '../../lib/stringUtils'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { CreateOrgResponse } from '../../models/createOrgResponse'
import { LoadingState } from '../../models/loadingState'
import { CreateAccountPanelLogic } from './createAccountPanel.hook'

import { AcceptInvitesResponse } from '../../models/acceptInvitesResponse'
import { acceptInvites } from '../../store/invitation.slice'

import { Cache } from '@aws-amplify/cache'
import { Icon } from '@iconify/react'
import { Amplify } from 'aws-amplify'
import { signInWithRedirect } from 'aws-amplify/auth'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import amplify_conf from '../../services/auth/amplify'

const CreateAccountFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 0.5rem;
`

const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const PageTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
`

const CreateAccountFormComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
`

const ConfirmCodeComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 1rem;
`

const PageTitleLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxLarge}
    text-align: left;
    color: orange;
    margin-bottom: 1rem;
`

const PageSubTitleLabel = styled(FormLabel)`
    text-align: left;
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 1.15rem;
    margin-bottom: 1rem;
`

const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
`

const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    color: #000;
    font-size: 1rem;
    font-weight: 500;
`

const ConfirmButtonComponentDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 1rem;
`

const ResendCodeButtonComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
`

const SignUpButton = styled(Button)`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    border-radius: 0;
    &:hover {
        transition: 0.5s;
    }
`

const ResetButton = styled(SignUpButton)`
    margin-left: 1rem;
`

const SubmitConfirmationButton = styled(SignUpButton)`
    margin-right: 0;
`

const ResendCodeButton = styled(SignUpButton)`
    width: 100%;
`

const ValidPasswordSpan = styled.span`
    display: flex;
    color: #19bf00;
    font-size: 14px;
    font-family: 'Arial';
`

const InValidPasswordSpan = styled(ValidPasswordSpan)`
    color: #df3312;
`

const PasswordValidationdiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 1rem;
`

const LogInContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
    align-items: center;
`

const LogInButtonLinkish = styled(Button)`
    display: flex;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;
    color: ${(props) => props.theme.color.text.heading};
    ${(props) => props.theme.typography.medium};
    &:hover {
        transition: 0.5s;
    }
`

const LogInButton = styled(LogInButtonLinkish)`
    width: 100%;
`

const LogInText = styled.div`
    display: flex;
    color: ${(props) => props.theme.color.text.heading};
    ${(props) => props.theme.typography.medium};
`

const HeadingLabel = styled(FormLabel)`
    display: flex;
    color: ${(props) => props.theme.color.text.heading};
    font-size: 1rem;
    font-weight: 500;
`

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`

const UserManualIcon = styled(Icon)`
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    color: green;
    justify-content: center;
    align-items: center;
`

const CreateAccountPopoverIcon = styled(UserManualIcon)`
    width: 1.25rem;
    height: 1.25rem;
    color: orange;
    margin-left: 0.25rem;
    margin-bottom: 1rem;
`

const StyledListItem = styled.li``

const StyledList = styled.ul``

const SignupErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ErrorMessageLabel = styled(FormLabel)`
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    color: ${(props) => props.theme.color.text.heading};
    font-size: 1rem;
    font-weight: 500;
`
const InnerAuthPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
`
const LoginContainerSSOPanel = styled.div`
    padding: 20px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 0%);
    border-radius: 0;
    background: transparent;
    margin-bottom: 3rem;
    justify-content: center;
    width: 60%;
    @media screen and (max-width: 600px) {
        width: 100%;
    }
`
const HExternalLoginPanel = styled.div`
    background: transparent;
    display: none;
    @media screen and (max-width: 600px) {
        display: block;
    }
`
const HSeparatorDiv = styled.div`
    justify-content: center;
    padding: 30px;
    @media screen and (max-width: 600px) {
        border-bottom-style: solid;
        border-bottom-color: rgb(255, 222, 173);
        border-bottom-width: 2px;
        margin-bottom: -2px;
    }
`
const ExternalLoginContainerPanel = styled.div`
    margin-bottom: 20px;
    padding: 30px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 0%);
    border-radius: 0;
    background: transparent;
    width: 100%;
    @media screen and (min-width: 601px) {
        border-left-style: solid;
        border-left-color: rgb(255, 222, 173);
        border-left-width: 2px;
        margin-left: -2px;
    }
`
const VExternalLoginPanel = styled.div`
    background: transparent;
    display: none;
    @media screen and (min-width: 601px) {
        display: block;
    }
`
const HorPanel = styled.div`
    display: none;
    @media screen and (max-width: 600px) {
        font-size: 16px;
        display: block;
        position: absolute;
        background-color: #fff;
        text-align: center;
        color: #a3a3a3;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 0.2rem;
        margin-left: 35%;
    }
`
const VorPanel = styled.div`
    @media screen and (min-width: 601px) {
        font-size: 16px;
        display: block;
        position: absolute;
        background-color: #fff;
        text-align: center;
        color: #a3a3a3;
        padding-top: 15px;
        padding-bottom: 15px;
        top: 40%;
        margin-left: -8px;
    }
`

const BiggerHeadingLabel = styled(HeadingLabel)`
    ${(props) => props.theme.typography.xLarge}
    margin-bottom: 2rem;
`
const SSOTextSpan = styled.span`
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
    vertical-align: middle;
`
const IntelSSOButton = styled(Button)`
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    color: #fff;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
    max-width: 25rem;
    border-radius: 0;
`

export interface ICreateAccountPanelProps {
    onCreateOrgSubmit: (createOrgRequest: CreateOrgRequest) => void
    createOrgLoading: LoadingState
    createOrgResponse?: CreateOrgResponse
    createOrgError: any
    invitationKey: string | null

    acceptInvites: typeof acceptInvites
    acceptInvitesError: any
    acceptInvitesLoading: LoadingState
    acceptInvitesResult: AcceptInvitesResponse
}

const CreateAccountPanel: FunctionComponent<ICreateAccountPanelProps> = ({
    onCreateOrgSubmit,
    createOrgLoading,
    createOrgResponse,
    createOrgError,
    invitationKey,
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
}: ICreateAccountPanelProps) => {
    const {
        onAccountEmailChange,
        onAccountPasswordChange,
        onAccountConfirmPasswordChange,
        onSubmit,
        validated,
        onCancel,
        onAccountOrgChange,
        onUserFullNameChange,
        signUpUserData,
        showSignupForm,
        onCodeConfirmSubmit,
        onResendConfirmationCode,
        onCodeConfirmChange,
        signUpError,
        codeValidated,
        onCodeConfirmKeyDown,
        onPasswordKeyUp,
        isPasswordContainLowerLetter,
        isPasswordContainSpecialChar,
        isPasswordContainNumber,
        isPasswordLength10Char,
        showPasswordvalidation,
    } = CreateAccountPanelLogic({
        onCreateOrgSubmit,
        createOrgResponse,
        createOrgError,
        invitationKey,
        acceptInvites,
        acceptInvitesError,
        acceptInvitesLoading,
        acceptInvitesResult,
    } as ICreateAccountPanelProps)

    let navigate = useNavigate()
    Amplify.configure(amplify_conf)
    const provider = {
        custom: 'Intel',
    }

    const organizationPopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Organization</Popover.Header>
            <Popover.Body>
                An organization can refer to a company or a team within a
                company that has one or more members.
            </Popover.Body>
        </Popover>
    )

    const createAccountPopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Create account</Popover.Header>
            <Popover.Body>
                <StyledList>
                    Create an account with Intel Performance Hub. Enter an
                    organization name for subscription to Intel Performance Hub.
                    <StyledListItem>
                        The first user to sign up and create an organization
                        gets organization admin privileges by default. An
                        organization admin can invite other users to the
                        organization with either organization admin or regular
                        user privileges.
                    </StyledListItem>
                    <StyledListItem>
                        Intel Performance Hub subscription is associated with an
                        organization. All users under an organization together
                        are subject to the same subscription tier conditions.
                    </StyledListItem>
                </StyledList>
            </Popover.Body>
        </Popover>
    )

    const loginButton = (
        <LogInButtonLinkish
            variant="link"
            type="submit"
            data-testid="login-button"
            onClick={async () => {
                Cache.clear()
                navigate('/signIn')
            }}
        >
            Log in
        </LogInButtonLinkish>
    )

    const COGNITO_SIGNUP_USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException'

    let signUpErrorMessageContainer = (
        <SignupErrorContainer></SignupErrorContainer>
    )

    const isSignUpError = !stringUtils.isNullOrUndefinedOrEmpty(signUpError)

    if (isSignUpError) {
        if (
            signUpError?.name?.includes(
                COGNITO_SIGNUP_USERNAME_EXISTS_EXCEPTION
            )
        ) {
            const message =
                signUpError?.message +
                (invitationKey
                    ? ' Please login to your account to accept the invite.'
                    : ' Please login to your account.')
            signUpErrorMessageContainer = (
                <SignupErrorContainer>
                    <ErrorMessageLabel>
                        {message}
                        {loginButton}
                    </ErrorMessageLabel>
                </SignupErrorContainer>
            )
        } else {
            signUpErrorMessageContainer = (
                <SignupErrorContainer>
                    <ErrorMessageLabel>
                        {'Error! ' + signUpError?.message}
                    </ErrorMessageLabel>
                </SignupErrorContainer>
            )
        }
    }
    const createAccountComponent = (
        <ExternalLoginContainerPanel>
            <HorizontalContainer>
                <CreateAccountFormComponent>
                    <PageTitleWrapper>
                        {showSignupForm ? (
                            <PageTitleLabel>
                                Create account
                                <OverlayTrigger
                                    trigger="click"
                                    placement="right"
                                    overlay={createAccountPopover}
                                >
                                    <CreateAccountPopoverIcon
                                        icon="lucide:info"
                                        onClick={() => {}}
                                    />
                                </OverlayTrigger>
                            </PageTitleLabel>
                        ) : (
                            <PageTitleLabel>Confirm Code</PageTitleLabel>
                        )}
                    </PageTitleWrapper>
                    <PageTitleWrapper>
                        <PageSubTitleLabel>
                            {showSignupForm
                                ? 'Realize the full potential of Intel platforms with tools and insights from performance experts.'
                                : 'Please check your email for confirmation code!'}
                        </PageSubTitleLabel>
                    </PageTitleWrapper>
                </CreateAccountFormComponent>
            </HorizontalContainer>

            <HorizontalContainer>
                {showSignupForm ? (
                    <CreateAccountFormComponent>
                        <AlertComponent variant="danger" show={isSignUpError}>
                            {signUpErrorMessageContainer}
                        </AlertComponent>
                        <StyledForm
                            noValidate
                            validated={validated}
                            onSubmit={onSubmit}
                            id="createOrgForm"
                        >
                            {/* {stringUtils.isNullOrUndefinedOrEmpty(
                                invitationKey
                            ) ? (
                                <Form.Group controlId="OrgName">
                                    <HeadingLabel>
                                        <RequiredTextLabel>*</RequiredTextLabel>
                                        Organization name{' '}
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="right"
                                            overlay={organizationPopover}
                                        >
                                            <UserManualIcon
                                                icon="lucide:info"
                                                onClick={() => {}}
                                            />
                                        </OverlayTrigger>
                                    </HeadingLabel>
                                    <TextBoxWrapper
                                        placeholder="Enter your organization's name..."
                                        onChange={onAccountOrgChange}
                                        required
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please enter your org's name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            ) : (
                                <div></div>
                            )} */}

                            <Form.Group controlId="userFullName">
                                <HeadingLabel>
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Full name{' '}
                                </HeadingLabel>
                                <TextBoxWrapper
                                    placeholder="Enter your full name..."
                                    onChange={onUserFullNameChange}
                                    required
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please your full name.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <HeadingLabel>
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Email address{' '}
                                </HeadingLabel>
                                <TextBoxWrapper
                                    type="email"
                                    placeholder="Enter email..."
                                    onChange={onAccountEmailChange}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter an email address in format
                                    name@xyg.com.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <HeadingLabel>
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Choose a password{' '}
                                </HeadingLabel>
                                <TextBoxWrapper
                                    id="userPassword"
                                    name="userPassword"
                                    type="password"
                                    placeholder="Please enter password..."
                                    autoComplete="new-password"
                                    onChange={onAccountPasswordChange}
                                    onKeyUp={onPasswordKeyUp}
                                    required
                                />
                                {showPasswordvalidation ? (
                                    <PasswordValidationdiv>
                                        {isPasswordContainLowerLetter ? (
                                            <ValidPasswordSpan>
                                                ✓ Password must contain a lower
                                                case letter
                                            </ValidPasswordSpan>
                                        ) : (
                                            <InValidPasswordSpan>
                                                ✖ Password must contain a lower
                                                case letter
                                            </InValidPasswordSpan>
                                        )}

                                        {isPasswordContainSpecialChar ? (
                                            <ValidPasswordSpan>
                                                ✓ Password must contain a
                                                special character
                                            </ValidPasswordSpan>
                                        ) : (
                                            <InValidPasswordSpan>
                                                ✖ Password must contain a
                                                special character
                                            </InValidPasswordSpan>
                                        )}

                                        {isPasswordContainNumber ? (
                                            <ValidPasswordSpan>
                                                ✓ Password must contain a number
                                            </ValidPasswordSpan>
                                        ) : (
                                            <InValidPasswordSpan>
                                                ✖ Password must contain a number
                                            </InValidPasswordSpan>
                                        )}
                                        {isPasswordLength10Char ? (
                                            <ValidPasswordSpan>
                                                ✓ Password must contain at least
                                                10 characters
                                            </ValidPasswordSpan>
                                        ) : (
                                            <InValidPasswordSpan>
                                                ✖ Password must contain at least
                                                10 characters
                                            </InValidPasswordSpan>
                                        )}
                                    </PasswordValidationdiv>
                                ) : (
                                    ''
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Please enter your password.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formMatchPassword">
                                <HeadingLabel>
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Confirm password{' '}
                                </HeadingLabel>
                                <TextBoxWrapper
                                    type="password"
                                    placeholder="Please re-enter password..."
                                    onChange={onAccountConfirmPasswordChange}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter confirm password.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <ButtonWrapper>
                                <SignUpButton
                                    variant="primary"
                                    type="submit"
                                    onClick={onSubmit}
                                >
                                    Submit
                                </SignUpButton>

                                <ResetButton
                                    variant="secondary"
                                    type="reset"
                                    onClick={onCancel}
                                >
                                    Reset
                                </ResetButton>
                            </ButtonWrapper>
                            <LogInContainer>
                                <VerticalContainer>
                                    <LogInText>Have an account?</LogInText>
                                </VerticalContainer>
                                <VerticalContainer>
                                    <LogInButton
                                        variant="link"
                                        data-testid="login-button"
                                        onClick={async () => {
                                            Cache.clear()
                                            navigate('/signIn')
                                        }}
                                    >
                                        Log in
                                    </LogInButton>
                                </VerticalContainer>
                            </LogInContainer>
                        </StyledForm>
                    </CreateAccountFormComponent>
                ) : (
                    <ConfirmCodeComponent>
                        <AlertComponent
                            variant="danger"
                            show={
                                !stringUtils.isNullOrUndefinedOrEmpty(
                                    signUpError
                                )
                            }
                        >
                            {'Error! ' + signUpError?.message}
                        </AlertComponent>
                        <StyledForm
                            noValidate
                            validated={codeValidated}
                            onSubmit={onCodeConfirmSubmit}
                            id="CodeConfirmForm"
                        >
                            <Form.Group controlId="code">
                                <HeadingLabel>
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Enter Code{' '}
                                </HeadingLabel>
                                <TextBoxWrapper
                                    placeholder="Enter your code here..."
                                    type="search"
                                    onChange={onCodeConfirmChange}
                                    onKeyDown={onCodeConfirmKeyDown}
                                    required
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter your code.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <ConfirmButtonComponentDiv>
                                <SubmitConfirmationButton
                                    variant="primary"
                                    type="submit"
                                    onClick={onCodeConfirmSubmit}
                                    id="SubmitCodeConfirm"
                                >
                                    Submit
                                </SubmitConfirmationButton>
                            </ConfirmButtonComponentDiv>
                            <ResendCodeButtonComponent>
                                <HeadingLabel>Didn't get code?</HeadingLabel>
                                <ResendCodeButton
                                    variant="warning"
                                    type="resend"
                                    onClick={onResendConfirmationCode}
                                >
                                    Resend confirmation code
                                </ResendCodeButton>
                            </ResendCodeButtonComponent>
                        </StyledForm>
                    </ConfirmCodeComponent>
                )}
            </HorizontalContainer>
        </ExternalLoginContainerPanel>
    )

    return (
        <CreateAccountFormContainer>
            <InnerAuthPanelContainer>
                <LoginContainerSSOPanel>
                    <HSeparatorDiv>
                        <BiggerHeadingLabel>
                            Intel employee sign in here
                        </BiggerHeadingLabel>
                        <IntelSSOButton
                            variant="info"
                            type="submit"
                            onClick={() =>
                                signInWithRedirect({
                                    provider,
                                })
                            }
                            aria-label="Intel"
                        >
                            <img
                                src="/intel_logo_classicblue.svg"
                                height="25px"
                                width="50px"
                                alt="intel logo"
                                style={{ verticalAlign: 'middle' }}
                            />{' '}
                            <SSOTextSpan>SSO</SSOTextSpan>
                        </IntelSSOButton>
                        <HorPanel>or</HorPanel>
                    </HSeparatorDiv>
                    <HExternalLoginPanel>
                        {createAccountComponent}
                    </HExternalLoginPanel>
                </LoginContainerSSOPanel>

                <VExternalLoginPanel>
                    <VorPanel>or</VorPanel>
                    {createAccountComponent}
                </VExternalLoginPanel>
            </InnerAuthPanelContainer>
        </CreateAccountFormContainer>
    )
}

export default CreateAccountPanel
