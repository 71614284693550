import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GroupObj } from '../containers/accessMgmtContainer/accessMgmtContainer'
import { LoadingState } from '../models/loadingState'
import AccessMgmtService from '../services/accessMgmtService'

export interface IAccessMgmtState {
    data: null
    addGroupData: null
    addGroupError: null
    setAccessData: null
    groupMemberData: null
    removeMemberData: null
    setAccessError: null
    loading: LoadingState
    error: null
}

const initialState: IAccessMgmtState = {
    data: null,
    addGroupData: null,
    addGroupError: null,
    setAccessData: null,
    groupMemberData: null,
    removeMemberData: null,
    setAccessError: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getAccessGroups = createAsyncThunk(
    'accessMgmt/getAccessGroups',
    async () => {
        return await AccessMgmtService.getAccessGroups()
    }
)

export const addGroup = createAsyncThunk(
    'diagnostics/addGroup',
    async (group: string) => await AccessMgmtService.addGroup(group)
)

export const getGroupMembers = createAsyncThunk(
    'diagnostics/getMembers',
    async (group: string) => await AccessMgmtService.getMembers(group)
)

export const setAccess = createAsyncThunk(
    'diagnostics/setAccess',
    async (groupObj: GroupObj) => await AccessMgmtService.setAccess(groupObj)
)

export const removeMember = createAsyncThunk(
    'diagnostics/removeMember',
    async (payload: any) => await AccessMgmtService.removeMember(payload)
)

// Then, handle actions in your reducers:
const accessMgmtSlice = createSlice({
    name: 'accessMgmt',
    initialState,
    reducers: {
        resetAccessGroupsData: (state: any, args: PayloadAction<any>) => {
            if (args['payload'] !== 'addGroupError') state.addGroupError = null
            if (args['payload'] !== 'setAccessData') state.setAccessData = null
            if (args['payload'] !== 'groupMemberData')
                state.groupMemberData = null
            if (args['payload'] !== 'removeMemberData')
                state.removeMemberData = null

            state.addGroupData = null
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccessGroups.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getAccessGroups.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getAccessGroups.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
            .addCase(addGroup.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(addGroup.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.addGroupData = action.payload.data
                }
            })
            .addCase(addGroup.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
            .addCase(setAccess.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(setAccess.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.setAccessData = action.payload.data
                }
            })
            .addCase(setAccess.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
            .addCase(getGroupMembers.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getGroupMembers.fulfilled, (state, action: any) => {
                // if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.groupMemberData = action.payload.data
                // }
            })
            .addCase(getGroupMembers.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
            .addCase(removeMember.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(removeMember.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.removeMemberData = action.payload.data
                }
            })
            .addCase(removeMember.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const accessGroups = accessMgmtSlice.reducer
export const { resetAccessGroupsData } = accessMgmtSlice.actions
