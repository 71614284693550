import { createTheme } from '@mui/material/styles'
import { ThemeOptions } from '@mui/material/styles/createTheme'
import components from './components'
import palette from './palette'
import typography from './typography'

const themeOptions: ThemeOptions = {
    palette,
    typography,
    shape: {
        borderRadius: 0,
    },
    components,
}

const theme = createTheme(themeOptions)

export default theme
