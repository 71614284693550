import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../config/hooks'
import DashboardCard from '../shared/cards/DashboardCard'
import DonutChart, { DonutChartData } from '../shared/charts/DonutChart'

const appSearchesChartHeaders: [string, string] = ['Search Model', 'Count']

const ModelSearchesCard = () => {
    const searchesData = useAppSelector(
        (state: any) => state.getAnalyticsResult.data
    )
    const searchesLoading = useAppSelector(
        (state: any) => state.getAnalyticsResult?.loading
    )

    const [chartData, setChartData] = useState<DonutChartData>([
        appSearchesChartHeaders,
    ])

    useEffect(() => {
        if (searchesData !== null && searchesData?.searchCounts) {
            const appSearchesData = searchesData?.searchCounts.map(
                (item: any) => [item.search, item.count]
            )
            setChartData([appSearchesChartHeaders, ...appSearchesData])
        }
    }, [searchesData])

    return (
        <DashboardCard
            title="Top Model Searches"
            dataTestId="Top Model Searches-label"
        >
            <DonutChart
                chartData={chartData}
                dataLoadingState={searchesLoading}
            />
        </DashboardCard>
    )
}

export default ModelSearchesCard
