/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/

import CardLinks from '../layouts/cardLinks'
import ContainerWrapper from '../layouts/containerWrapper'

const AudioContainer = () => {
    const bodyContent = (isUserLoggedIn: boolean) => {
        return (
            <CardLinks
                title={'Audio'}
                queryType="audio"
                isUserLoggedIn={isUserLoggedIn}
            />
        )
    }

    return (
        <ContainerWrapper>
            {/* isUserLoggedIn passed as argument in child function from ContainerWrapper */}
            {(isUserLoggedIn) => bodyContent(isUserLoggedIn)}
        </ContainerWrapper>
    )
}

export default AudioContainer
