/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import {
    ChangeEvent,
    Dispatch,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { Form, FormLabel, OverlayTrigger, Popover } from 'react-bootstrap'
import styled from 'styled-components'
import { ChatCompletionRequest } from '../../../models/chatCompletionRequest'
import { postChatCompletion } from '../../../store/chatCompletion.slice'
import { IAiModelCardProps } from '../../aiModelCard'

import mammoth from 'mammoth'
import { connect, ConnectedProps } from 'react-redux'
import CustomButton from '../../../components/customButton'

const ChatContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 0;
    padding: 1rem 0;
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    alignitems: center;
    height: 100%;
    width: 100%;
    padding: 1rem;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`

const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const RunButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: #8f5da2;
    margin-right: 1.5rem;
    border: none;
    min-width: 6rem;
    height: 2rem;
    border-radius: 0;
    border: none;
`

const ResetButton = styled(RunButton)`
    background-color: #8f5da2;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
`

const PopoverKeyDiv = styled.div`
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
`

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        postChatQuery: (question: ChatCompletionRequest) =>
            dispatch(postChatCompletion(question)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        chatCompletionAnswer: state.postChatCompletionResult.data,
        chatCompletionLoading: state.postChatCompletionResult.loading,
        chatCompletionError: state.postChatCompletionResult.error,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type ISummarizationInputProps = ReduxProps & {
    model: IAiModelCardProps
    setOutput: any
}

const SummarizationInput: FunctionComponent<ISummarizationInputProps> = ({
    model,
    setOutput,
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
}: ISummarizationInputProps) => {
    const defaultInputQuery =
        'The Roman Empire was the post-Republican state of ancient Rome. It is generally understood to mean the period and territory ruled by the Romans following Octavians assumption of sole rule under the Principate in 27 BC. It included territories in Europe, North Africa, and Western Asia and was ruled by emperors.  The fall of the Western Roman Empire in 476 AD conventionally marks the end of classical antiquity and the beginning of the Middle Ages.Rome had expanded its rule to most of the Mediterranean and beyond. However, it was severely destabilized in civil wars and political conflicts which culminated in the victory of Octavian over Mark Antony and Cleopatra at the Battle of Actium in 31 BC, and the subsequent conquest of the Ptolemaic Kingdom in Egypt. In 27 BC, the Roman Senate granted Octavian overarching power or imperium and the new title of Augustus, marking his accession as the first Roman emperor of a monarchy with Rome as its sole capital. '
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const [inputText, setInputText] = useState(defaultInputQuery)
    const [errorFile, setErrorFile] = useState<string | undefined>(undefined)

    const supportedFiles = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        '.doc',
        '.docx',
    ]

    const handleRunClick = async () => {
        //get answer
        // setIsResetClick(false)

        //get answer
        SubmitQueryForAnswer()
    }

    const SubmitQueryForAnswer = (listCountIndex?: number) => {
        const request = new ChatCompletionRequest()
        request.messages = inputText
        request.model = model.name
        request.stream = model.stream

        postChatQuery(request)
    }

    const handleResetClick = async () => {
        setInputText(defaultInputQuery)
        if (
            inputRef &&
            inputRef.current !== undefined &&
            inputRef.current !== null
        )
            inputRef.current.value = defaultInputQuery
        setOutput('')
    }

    const onInputTextChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        setInputText(target.value)
    }, [])

    const handleFileChosen = async (file: File) => {
        let fileReader = new FileReader()
        fileReader.onloadend = async (e) => {
            const content = fileReader.result
            if (content && file.type == 'text/plain') {
                setInputText(content.toString())
            } else if (e.target?.result) {
                try {
                    const textFromDocument = await mammoth.extractRawText({
                        arrayBuffer: e.target?.result as ArrayBuffer,
                    })
                    setInputText(textFromDocument.value)
                } catch (error) {
                    console.log(error)
                    setErrorFile('Error reading .docx file')
                }
            }
        }
        if (file.type == 'text/plain') {
            fileReader.readAsText(file)
        } else {
            fileReader.readAsArrayBuffer(file)
        }
    }

    useEffect(() => {
        if (
            inputRef &&
            inputRef.current !== undefined &&
            inputRef.current !== null
        )
            inputRef.current.value = inputText
    }, [inputText])

    return (
        <ChatContainer>
            <InputContainer>
                <Container>
                    <TextLabel>
                        Input
                        <OverlayTrigger
                            placement={'right'}
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Input text to embed as a string.
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Form.Control
                        ref={inputRef}
                        id="input"
                        name="input"
                        placeholder="Enter your query.."
                        as="textarea"
                        rows={15}
                        onChange={onInputTextChange}
                        defaultValue={defaultInputQuery}
                        autoFocus
                        required
                    />
                    <div style={{ paddingTop: '1rem' }}>
                        <TextLabel>
                            Input Document
                            <OverlayTrigger
                                placement={'right'}
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Popover
                                        id="pop_timeline"
                                        style={{
                                            maxWidth: '20%',
                                        }}
                                    >
                                        <Popover.Body>
                                            <PopoverKeyDiv>
                                                Attach a document you want to
                                                summarize
                                            </PopoverKeyDiv>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <i
                                    className="uil uil-info-circle pointer"
                                    tabIndex={0}
                                ></i>
                            </OverlayTrigger>
                        </TextLabel>
                        <Form.Group>
                            <Form.Control
                                type="file"
                                id={`file`}
                                name={`file`}
                                onChange={(event: ChangeEvent<HTMLElement>) => {
                                    const target =
                                        event.currentTarget as HTMLInputElement

                                    if (
                                        target?.files &&
                                        target?.files[0] &&
                                        supportedFiles.includes(
                                            target?.files[0].type
                                        )
                                    ) {
                                        handleFileChosen(target?.files[0])
                                    } else if (
                                        target?.files &&
                                        target?.files[0]
                                    ) {
                                        target.checkValidity()
                                        setErrorFile(
                                            `${target.files[0].type} is not supported.`
                                        )
                                    }
                                }}
                                key={`file`}
                                data-testid={`file-value`}
                                accept="text/plain, .doc, .docx"
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                data-validity={errorFile ? true : false}
                            >
                                {errorFile}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </Container>
                <Container style={{ flexDirection: 'row' }}>
                    <CustomButton
                        styleType="dark"
                        text="Summarize"
                        onClick={handleRunClick}
                    />
                    <CustomButton
                        styleType="darkOutline"
                        text="Reset"
                        onClick={handleResetClick}
                    />
                </Container>
            </InputContainer>
        </ChatContainer>
    )
}

export default connector(SummarizationInput)
