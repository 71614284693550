const {
    REACT_APP_API_SERVICE_URL,
    REACT_APP_COGNITO_LOGIN_URL,
    REACT_APP_COGNITO_CLIENT_ID,
    REACT_APP_COGNITO_USER_POOL_ID,
} = process.env

const win: any = window
const apiUrl: any = win.API_URL
const cognitoLoginUrl: any = win.COGNITO_LOGIN_URL
const client_id: any = win.COGNITO_CLIENT_ID
const pool_id: any = win.COGNITO_USER_POOL_ID

// For local development configure API URL ONLY in `.env` file.
// DO NOT set URL in this file as it gets deployed to production.

export const API_SERVICE_URL = REACT_APP_API_SERVICE_URL
    ? REACT_APP_API_SERVICE_URL
    : apiUrl

export const COGNITO_CLIENT_ID = REACT_APP_COGNITO_CLIENT_ID
    ? REACT_APP_COGNITO_CLIENT_ID
    : client_id

export const COGNITO_LOGIN_URL = REACT_APP_COGNITO_LOGIN_URL
    ? REACT_APP_COGNITO_LOGIN_URL
    : cognitoLoginUrl

export const COGNITO_USER_POOL_ID = REACT_APP_COGNITO_USER_POOL_ID
    ? REACT_APP_COGNITO_USER_POOL_ID
    : pool_id

export const COGNITO_REGION = COGNITO_USER_POOL_ID.split('_')[0]

export const APP_DOMAIN = window.location.hostname

export const HOST_ENV_DOMAIN_MAPPING = Object.freeze({
    localhost: 'localhost',
    dev: 'ai-dev.dcs-tools-experiments.infra-host.com',
    staging: 'ai-staging.dcs-tools-experiments.infra-host.com',
    production: 'aiswcatalog.intel.com',
})
