import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import AuthPermissionsService from '../services/authPermissionsService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getAuthPermissions = createAsyncThunk(
    'getAuthPermissions',
    async () => {
        return await AuthPermissionsService.getAuthPermission()
    }
)

// Then, handle actions in your reducers:
const getAuthPermissionsSlice = createSlice({
    name: 'getAuthPermissions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAuthPermissions.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getAuthPermissions.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(getAuthPermissions.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const authPermissionsResult = getAuthPermissionsSlice.reducer
