import { APP_DOMAIN, HOST_ENV_DOMAIN_MAPPING } from '../../config/service'
import { isDevAndLocal } from '../../lib/host'

const baseURL = isDevAndLocal(APP_DOMAIN)
    ? `https://${HOST_ENV_DOMAIN_MAPPING.dev}/api/ai`
    : `https://${APP_DOMAIN}/api/ai`

export const reasoningChatPythonCode = `from openai import OpenAI

client = OpenAI(
    base_url = "${baseURL}",
    api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
)

completion = client.chat.completions.create(
    model="deepseek-ai/deepseek-coder-33b-instruct",
    messages="Write a Python function to calculate the factorial of a number.",
    temperature=0.5,
    top_p=1,
    max_tokens=512,
    stream=True
)

print(completion.choices[0].message)

`
