/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import {
    ChangeEvent,
    Dispatch,
    FunctionComponent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useRef,
} from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Spyglass from '../icons/spyglass'
import { getAIModels } from '../store/chatCompletion.slice'

const SearchComponent = styled(FormControl)`
    border-radius: 0;
    font-size: 1rem !important;
    width: 290px;

    ::placeholder {
        color: #808080;
    }
`
const SearchGroupComponentWrapper = styled(InputGroup)`
    display: flex;
    border-radius: 0;
    width: 290px;
`
const SearchIcon = styled(InputGroup.Text)`
    border-radius: 0;
    padding: 5px;
    background-color: ${(props) => props.theme.color.text.navy};
    color: #fff;
    &.light {
        background-color: ${(props) => props.theme.color.text.brightBlue};
        color: ${(props) => props.theme.color.text.navy};
    }
`

// Moving redux up in code to properly connect dispatch to component and remove default props
// GetAIModels is required, but needed to be connected via ReduxProps

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => dispatch(getAIModels(qParam)),
    }
}

const mapStateToProps = (state: any) => ({})

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type SearchGroupComponentProps = ReduxProps & {
    light?: boolean
}

const SearchGroupComponent: FunctionComponent<SearchGroupComponentProps> = ({
    light = false,
    getAIModels,
}: SearchGroupComponentProps) => {
    const SearchComponentRef = useRef<any>()
    const navigate = useNavigate()

    const onAppNameChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value != undefined) {
            if (target.value === '') {
                //reset search banner and refresh data for default search
                setSearchURL()
            }
        }
    }, [])

    const setSearchURL = () => {
        console.log(SearchComponentRef.current)
        if (SearchComponentRef.current) {
            let searchString = encodeURIComponent(
                SearchComponentRef?.current?.value.trim()
            )
            let searchUrl = `?search=${searchString}&count=50`
            let navUrl = '/explore?search=' + searchString

            if (!searchString) {
                searchUrl = `?search=trending&count=50`
                navUrl = '/explore'
            }

            getAIModels(searchUrl)

            // if home page entry cleared, do not navigate
            if (
                !searchString &&
                !(window.location.pathname.indexOf('explore') > -1)
            )
                return

            navigate(navUrl)
        }
    }

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            setSearchURL()
        }
    }

    useEffect(() => {
        if (
            SearchComponentRef.current &&
            window.location.search.includes('search=')
        ) {
            SearchComponentRef.current.value = decodeURIComponent(
                window.location.search.split('search=')[1]
            )
        }
    }, [])

    return (
        <SearchGroupComponentWrapper>
            <SearchComponent
                placeholder="Search the catalog"
                onChange={onAppNameChange}
                onKeyDown={onKeyDown}
                required
                ref={SearchComponentRef}
                type="search"
                data-testid="search-textbox"
            />
            <SearchIcon
                className={light ? 'light' : 'dark'}
                onClick={setSearchURL}
            >
                <Spyglass white={!light} />
            </SearchIcon>
        </SearchGroupComponentWrapper>
    )
}

export default connector(SearchGroupComponent)
