import { Icon } from '@iconify/react'
import {
    Box,
    Button,
    FormLabel,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    useMediaQuery,
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment, { Moment } from 'moment'
import { ChangeEvent, FC, MouseEvent } from 'react'
import theme from '../../containers/dashboardContainer/theme'
import { colors } from '../../utils/colors'

const filtersContainerBoxSx = {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '4.5rem',
    backgroundColor: 'white',
    zIndex: 10,
    marginInline: '-2rem',
    paddingInline: '2rem',
    paddingBlock: '0.5rem',
    borderBottom: `1px solid ${colors.lightGray400}`,
}

const filtersBoxSx = {
    display: 'flex',
    gap: '1rem',
}

const filtersSwitchBoxSx = { display: 'flex', alignItems: 'center' }

const filtersSwitchFormLabelSx = { marginBottom: '0.125rem' }

const filtersSwitchOptionIconSx = {
    marginBottom: '0.125rem',
    marginRight: '0.25rem',
}

const filtersSwitchSx = {
    // '& .MuiSwitch-switchBase:not(.Mui-checked):not(.Mui-disabled)': {
    //     color: colors.geode,
    // },
    // '& .MuiSwitch-switchBase:not(.Mui-checked):not(.Mui-disabled)+.MuiSwitch-track':
    //     {
    //         backgroundColor: colors.geode,
    //     },
}

const toggleButtonGroupSx = { height: '2.25rem' }

const timeRangeBoxSx = {
    display: 'grid',
    gridTemplateColumns: '10rem 10rem 1fr',
    height: '2.25rem',
    gap: '1rem',
    alignItems: 'center',
}

const datePickerSx = { marginBottom: 0 }

export enum TimeFiltersOption {
    OneDay = '1 Day',
    OneWeek = '1 Week',
    OneMonth = '1 Month',
    ThreeMonths = '3 Months',
    SixMonths = '6 Months',
    OneYear = '1 Year',
}
export type FilterType = 'time-filters' | 'date-range'
export type DateRange = [Moment, Moment]

interface DashboardFiltersProps {
    selectedFilterType: FilterType
    selectedTimeFilter: TimeFiltersOption
    handleFilterSwitchChange: (event: ChangeEvent<HTMLInputElement>) => void
    handleTimeFilterChange: (
        _: MouseEvent<HTMLElement>,
        newTimeFilter: TimeFiltersOption | null
    ) => void
    selectedDateRange: DateRange
    handleStartDateChange: (newStartDate: Moment | null) => void
    handleEndDateChange: (newEndDate: Moment | null) => void
    disabled: boolean
    onApplyDateRangeFilters: () => void
}

const DashboardFilters: FC<DashboardFiltersProps> = ({
    selectedFilterType,
    selectedTimeFilter,
    handleFilterSwitchChange,
    handleTimeFilterChange,
    selectedDateRange,
    handleStartDateChange,
    handleEndDateChange,
    disabled,
    onApplyDateRangeFilters,
}) => {
    const filterSwitchChecked = selectedFilterType === 'date-range'

    const belowMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Box sx={filtersContainerBoxSx}>
            <Box
                sx={{
                    ...filtersBoxSx,
                    alignItems: belowMdBreakpoint ? 'flex-start' : 'center',
                    flexDirection: belowMdBreakpoint ? 'column' : 'row',
                }}
            >
                <Box sx={filtersSwitchBoxSx}>
                    <FormLabel
                        sx={{
                            ...filtersSwitchFormLabelSx,
                            color: !filterSwitchChecked
                                ? 'inherit'
                                : colors.lightGray500,
                        }}
                    >
                        <Icon
                            icon="mdi:timeline-clock"
                            style={filtersSwitchOptionIconSx}
                        />
                        Time Filters
                    </FormLabel>
                    <Switch
                        checked={filterSwitchChecked}
                        disabled={disabled}
                        onChange={handleFilterSwitchChange}
                        sx={filtersSwitchSx}
                    />
                    <FormLabel
                        sx={{
                            ...filtersSwitchFormLabelSx,
                            color: filterSwitchChecked
                                ? 'inherit'
                                : colors.lightGray500,
                        }}
                    >
                        <Icon
                            icon="mdi:calendar-range"
                            style={filtersSwitchOptionIconSx}
                        />
                        Date Range
                    </FormLabel>
                </Box>
                {selectedFilterType === 'time-filters' && (
                    <ToggleButtonGroup
                        value={selectedTimeFilter}
                        onChange={handleTimeFilterChange}
                        disabled={disabled}
                        exclusive
                        color="primary"
                        aria-label="Time filter"
                        sx={toggleButtonGroupSx}
                    >
                        {Object.values(TimeFiltersOption).map((value) => (
                            <ToggleButton
                                value={value}
                                aria-label={value}
                                key={value}
                            >
                                {value}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                )}
                {selectedFilterType === 'date-range' && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box sx={timeRangeBoxSx}>
                            <DatePicker
                                value={selectedDateRange[0]}
                                disabled={disabled}
                                maxDate={selectedDateRange[1]}
                                onChange={handleStartDateChange}
                                sx={datePickerSx}
                            />
                            <DatePicker
                                value={selectedDateRange[1]}
                                disabled={disabled}
                                minDate={selectedDateRange[0]}
                                maxDate={moment()}
                                onChange={handleEndDateChange}
                                sx={datePickerSx}
                            />
                            <Button
                                disabled={disabled}
                                onClick={onApplyDateRangeFilters}
                            >
                                Apply
                            </Button>
                        </Box>
                    </LocalizationProvider>
                )}
            </Box>
        </Box>
    )
}

export default DashboardFilters
