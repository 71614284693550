import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { libType } from '../../models/modelType'
import { Draggable } from './Draggable'

const ElementOuterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const ElementInnerWrapper = styled.div`
    display: flex;
    justify-content: left;
    align-items: left;
    background: #f6f6f6;
    border-radius: 0;
    margin-top: 12;
    margin: 0.2rem 0;
    height: 120;
    width: 100%;
    padding: 5px 25% 0 25%;
`

const ElementText = styled.h3`
    font-size: 16px;
    font-weight: 600;
    padding: 1rem;
`

interface IDraggableElement {
    identifier: string
    content: string
    type?: libType
    visible: boolean
}
const LLMImage = (
    <img
        src={'/aiModelImage/llms-icon.jpg'}
        style={{
            height: '50px',
            width: '50px',
            borderRadius: '25px',
        }}
    />
)
const VectorDbImage = (
    <img
        src={'/aiModelImage/data-icon.jpg'}
        style={{
            height: '45px',
            width: '45px',
            borderRadius: '25px',
        }}
    />
)

export const DraggableElement: FC<IDraggableElement> = ({
    identifier,
    content,
    type,
    visible,
}) => {
    const itemIdentifier = useMemo(() => identifier, [identifier])

    return (
        <Draggable id={itemIdentifier}>
            {visible && (
                <ElementOuterWrapper>
                    <ElementInnerWrapper>
                        {type == libType.Llm ? LLMImage : VectorDbImage}
                        <ElementText>{content}</ElementText>
                    </ElementInnerWrapper>
                </ElementOuterWrapper>
            )}
        </Draggable>
    )
}
