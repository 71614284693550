import styled from 'styled-components'

const SidePanelComponent = styled.div`
    display: none;
    @media screen and (min-width: 768px) {
        display: block;
    }
`

const ContainerHeadline = styled.div`
    font-weight: 300;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    padding: 2rem 20px 0 0;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`

const MainSection = styled.section`
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding: 2rem 1rem 3rem 1rem;
    height: 100%;
`

const HomeSection = styled(MainSection)`
    padding: 0;
`

const gap = 20

const CardPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${gap}px;
    margin: 2rem 0;
`

const CardColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    max-height: 512px;

    width: 100%;
    @media screen and (min-width: 768px) {
        width: calc(50% - ${gap}px);
    }
    @media screen and (min-width: 1100px) {
        width: calc(33.33% - ${gap}px);
    }

    @media screen and (min-width: 1300px) {
        width: calc(25% - ${gap}px);
    }

    @media screen and (min-width: 1600px) {
        width: calc(20% - ${gap}px);
    }
`

export {
    CardColumn,
    CardPanelContainer,
    ContainerHeadline,
    HomeSection,
    MainSection,
    SidePanelComponent,
}
