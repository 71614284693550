import { FunctionComponent } from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { customMarkdownComponents } from '../utils/markdown'

export interface CopyRenderProps {
    text: string
}

const CopyRenderer: FunctionComponent<CopyRenderProps> = ({ text }) => (
    <Markdown remarkPlugins={[remarkGfm]} components={customMarkdownComponents}>
        {text}
    </Markdown>
)

export default CopyRenderer
