import { useCallback } from 'react'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { ICreateAccountContainerProps } from './CreateAccountContainer'

export function CreateAccountContainerLogic({
    createOrg,
}: ICreateAccountContainerProps) {
    const onCreateOrgSubmit = useCallback(
        async (createOrgRequest: CreateOrgRequest) => {
            createOrg(createOrgRequest)
        },
        []
    )

    return {
        onCreateOrgSubmit,
    }
}
