class StringUtils {
    isNullOrUndefinedOrEmpty = function (obj: any): boolean {
        if (obj === undefined || obj === null || obj === '') {
            return true
        }
        return false
    }

    objectToQueryString = function (obj: any): string {
        var str = []
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                if (typeof obj[p] === 'object' && obj[p].length > 1) {
                    for (var arrElement of obj[p]) {
                        str.push(
                            encodeURIComponent(p) +
                                "='" +
                                encodeURIComponent(arrElement) +
                                "'"
                        )
                    }
                } else {
                    str.push(
                        encodeURIComponent(p) +
                            "='" +
                            encodeURIComponent(obj[p]) +
                            "'"
                    )
                }
            }
        return str.join('&')
    }
}

export default new StringUtils()
