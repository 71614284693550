import { CognitoUser } from 'amazon-cognito-identity-js'
import { Amplify } from 'aws-amplify'
import {
    confirmSignUp as ConfirmSignup,
    resendSignUpCode,
    signIn as Signin,
    signUp as Signup,
} from 'aws-amplify/auth'
import {
    ChangeEvent,
    FormEvent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import stringUtils from '../../lib/stringUtils'
import amplify_conf from '../../services/auth/amplify'
import { ICreateAccountPanelProps } from './createAccountPanel'

Amplify.configure(amplify_conf)

export function CreateAccountPanelLogic({
    onCreateOrgSubmit,
    createOrgResponse,
    createOrgError,
    invitationKey,
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
}: ICreateAccountPanelProps) {
    const [validated, setValidated] = useState(false)
    const [acountOrgName, setAcountOrgName] = useState<string>('')
    const [userFullName, setUserFullName] = useState<string>('')
    const [accountEmail, setAccountEmail] = useState<string>('')
    const [accountPassword, setAccountPassword] = useState<string>('')
    const [accountConfirmPassword, setAccountConfirmPassword] =
        useState<string>('')
    const [signUpUserData, setSignUpUserData] = useState<CognitoUser>()
    const [showSignupForm, setShowSignupForm] = useState<boolean>(true)
    const [confirmationCode, setConfirmationCode] = useState<string>('')
    const [signUpError, setSignUpError] = useState<any>(null)
    const [codeValidated, setCodeValidated] = useState(false)

    const [isPasswordContainLowerLetter, setIsPasswordContainLowerLetter] =
        useState<boolean>(false)
    const [isPasswordContainSpecialChar, setIsPasswordContainSpecialChar] =
        useState<boolean>(false)
    const [isPasswordContainNumber, setIsPasswordContainNumber] =
        useState<boolean>(false)
    const [isPasswordLength10Char, setIsPasswordLength10Char] =
        useState<boolean>(false)
    const [showPasswordvalidation, setShowPasswordvalidation] =
        useState<boolean>(false)

    let navigate = useNavigate()

    const onAccountOrgChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAcountOrgName(target.value)
            }
        },
        []
    )

    const onUserFullNameChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setUserFullName(target.value)
            }
        },
        []
    )

    const onAccountEmailChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountEmail(target.value)
            }
        },
        []
    )

    const onAccountPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountPassword(target.value)
            }
        },
        []
    )
    const onAccountConfirmPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountConfirmPassword(target.value)
            }
        },
        []
    )
    async function signUp(username: string, password: string, email: string) {
        try {
            const { isSignUpComplete, userId, nextStep } = await Signup({
                username,
                password,
                options: {
                    userAttributes: {
                        email, // optional
                        //phone_number,   // optional - E.164 number convention
                        // other custom attributes
                    },
                },
            })
            if (isSignUpComplete) {
                setShowSignupForm(false)
            }
        } catch (error) {
            setSignUpError(error)
        }
    }
    async function confirmSignUp(
        username: string,
        confirmationCode: string,
        userFullName: string
    ) {
        try {
            await ConfirmSignup({ username, confirmationCode }).then(() => {
                //silent signIn
                signIn(accountEmail, accountPassword, userFullName)
            })
        } catch (error) {
            setSignUpError(error)
        }
    }
    async function resendConfirmationCode(username: string) {
        try {
            await resendSignUpCode({ username })
            console.log('code resent successfully')
        } catch (err) {
            setSignUpError(err)
        }
    }

    async function signIn(
        username: string,
        password: string,
        userFullName: string
    ) {
        try {
            const { isSignedIn, nextStep } = await Signin({
                username,
                password,
            })
            if (isSignedIn) {
                navigate('/explore')
                // if (invitationKey) {
                //     acceptInvites({
                //         decision: 'accept',
                //         invitationKey: invitationKey,
                //         displayName: userFullName,
                //     } as AcceptInvitesRequest)
                //     } else {
                //         //create Organization after signup silently
                //         var createOrgRequest = new CreateOrgRequest()
                //         if (acountOrgName) {
                //             createOrgRequest.name = acountOrgName
                //             createOrgRequest.displayName = userFullName
                //             createOrgRequest.description = ''
                //             onCreateOrgSubmit(createOrgRequest)
                //         }
                // }
            }
        } catch (error) {
            setSignUpError(error)
        }
    }

    // useEffect(() => {
    //     if (createOrgError) {
    //         let error: {
    //             message: string
    //         } = { message: 'Error creating org' }

    //         setSignUpError(error)
    //     }
    // }, [createOrgError])

    // useEffect(() => {
    //     if (createOrgResponse) {
    //         if (createOrgResponse.id) {
    //             //nevigate to explore
    //             navigate('/explore')
    //         } else {
    //             let error: {
    //                 message: string
    //             } = { message: 'Error retrieving organization info' }

    //             setSignUpError(error)
    //         }
    //     }
    // }, [createOrgResponse])

    useEffect(() => {
        if (acceptInvitesError) {
            let error: {
                message: string
            } = { message: 'Error adding new user to the invited org' }

            setSignUpError(error)
        }
    }, [acceptInvitesError])

    useEffect(() => {
        if (acceptInvitesResult) {
            navigate('/selectTier')
        }
    }, [acceptInvitesResult])

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            let error: {
                message: string
            } = { message: '' }

            const form = event.currentTarget
            let signUpResult: any
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            if (
                accountPassword !== '' &&
                accountConfirmPassword !== '' &&
                accountPassword !== accountConfirmPassword
            ) {
                error.message = 'Entered passwords do not match'
                setSignUpError(error)
            } else {
                //cognito signUp
                error.message = ''
                setSignUpError(null)
                if (
                    !stringUtils.isNullOrUndefinedOrEmpty(accountEmail) &&
                    !stringUtils.isNullOrUndefinedOrEmpty(accountPassword) &&
                    !stringUtils.isNullOrUndefinedOrEmpty(
                        accountConfirmPassword
                    ) &&
                    !stringUtils.isNullOrUndefinedOrEmpty(userFullName)
                ) {
                    signUpResult = signUp(
                        accountEmail,
                        accountPassword,
                        accountEmail
                    )
                } else {
                    setSignUpError(error)
                }
            }
        },
        [
            accountEmail,
            accountPassword,
            accountConfirmPassword,
            invitationKey,
            userFullName,
            acountOrgName,
        ]
    )

    const onCancel = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)
        setSignUpError(null)
        setCodeValidated(false)
        window.location.reload()
    }, [])

    const onCodeConfirmChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setConfirmationCode(target.value)
            }
        },
        []
    )

    const onCodeConfirmSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            let signUpResult: any
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setCodeValidated(true)

            //confirm signup using user entered code
            confirmSignUp(accountEmail, confirmationCode, userFullName)
        },
        [accountEmail, confirmationCode, userFullName]
    )

    const onResendConfirmationCode = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            //resend code
            resendConfirmationCode(accountEmail)
        },
        [accountEmail]
    )

    const onCodeConfirmKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (
            key.match(/[0-9,c,v]/gi) ||
            key === 'Enter' ||
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'Home' ||
            key === 'End'
        ) {
            return true
        } else {
            event.preventDefault()
        }
    }
    const onPasswordKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        const passwordCtrl = event.currentTarget
        if (passwordCtrl && passwordCtrl.value.length > 0) {
            setShowPasswordvalidation(true)
            if (key.match(/[a-z]/gi)) {
                setIsPasswordContainLowerLetter(true)
                if (!passwordCtrl.value.match(/[a-z]/gi)) {
                    setIsPasswordContainLowerLetter(false)
                }
            }
            if (key.match(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi)) {
                setIsPasswordContainSpecialChar(true)
            } else if (
                !passwordCtrl.value.match(
                    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
                )
            ) {
                setIsPasswordContainSpecialChar(false)
            }
            if (key.match(/[0-9]/gi)) {
                setIsPasswordContainNumber(true)
            } else if (!passwordCtrl.value.match(/[0-9]/gi)) {
                setIsPasswordContainNumber(false)
            }

            if (passwordCtrl.value.length >= 10) {
                setIsPasswordLength10Char(true)
            } else setIsPasswordLength10Char(false)
        } else {
            setIsPasswordContainLowerLetter(false)
            setIsPasswordContainSpecialChar(false)
            setIsPasswordContainNumber(false)
            setIsPasswordLength10Char(false)
            setShowPasswordvalidation(false)
        }
    }

    return {
        onAccountEmailChange,
        onUserFullNameChange,
        onAccountPasswordChange,
        onAccountConfirmPasswordChange,
        onSubmit,
        validated,
        onCancel,
        onAccountOrgChange,
        signUpUserData,
        showSignupForm,
        onCodeConfirmSubmit,
        onResendConfirmationCode,
        onCodeConfirmChange,
        signUpError,
        codeValidated,
        onCodeConfirmKeyDown,
        onPasswordKeyUp,
        isPasswordContainLowerLetter,
        isPasswordContainSpecialChar,
        isPasswordContainNumber,
        isPasswordLength10Char,
        showPasswordvalidation,
    }
}
