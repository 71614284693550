import { FunctionComponent } from 'react'

const Hexagon: FunctionComponent = ({}) => {
    return (
        <svg
            width="148"
            height="132"
            viewBox="0 0 148 132"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="artwork">
                <g id="Clip path group">
                    <mask
                        id="mask0_223_5649"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="5"
                        y="2"
                        width="138"
                        height="127"
                    >
                        <g id="clippath">
                            <path
                                id="Vector"
                                d="M106.173 6.68953H41.8133V2.51953H106.173V6.68953ZM142.743 61.0695L113.283 5.77953L109.603 7.73953L139.063 63.0295L142.743 61.0695ZM142.373 67.5095L138.643 65.6495L109.583 123.9L113.313 125.76L142.373 67.5095ZM106.173 124.7H41.8133V128.87H106.173V124.7ZM38.5233 123.89L9.04326 65.2695L5.32326 67.1395L34.8033 125.76L38.5233 123.89ZM38.5833 7.43953L34.9033 5.49953L5.35326 61.4795L9.03326 63.4195L38.5833 7.43953Z"
                                fill="white"
                            />
                        </g>
                    </mask>
                    <g mask="url(#mask0_223_5649)">
                        <g id="Group">
                            <path
                                id="Vector_2"
                                d="M142.723 -1H5.33325V132.94H142.723V-1Z"
                                fill="url(#paint0_linear_223_5649)"
                            />
                        </g>
                    </g>
                </g>
                <path
                    id="Vector_3"
                    d="M139.493 62.0703H73.8833V66.2403H139.493V62.0703Z"
                    fill="url(#paint1_linear_223_5649)"
                />
                <path
                    id="Vector_4"
                    d="M73.8833 62.0703H8.49326V66.2403H73.8833V62.0703Z"
                    fill="url(#paint2_linear_223_5649)"
                />
                <path
                    id="Vector_5"
                    d="M104.907 5.65734L68.5275 64.6992L72.0776 66.8867L108.457 7.84484L104.907 5.65734Z"
                    fill="url(#paint3_linear_223_5649)"
                />
                <path
                    id="Vector_6"
                    d="M72.0624 63.1519L38.8145 123.83L42.4627 125.829L75.7106 65.1509L72.0624 63.1519Z"
                    fill="url(#paint4_linear_223_5649)"
                />
                <path
                    id="Vector_7"
                    d="M43.0126 5.71185L39.389 7.77539L72.0789 65.18L75.7025 63.1165L43.0126 5.71185Z"
                    fill="url(#paint5_linear_223_5649)"
                />
                <path
                    id="Vector_8"
                    d="M75.7094 63.1471L72.0587 65.1416L105.204 125.808L108.854 123.813L75.7094 63.1471Z"
                    fill="url(#paint6_linear_223_5649)"
                />
                <path
                    id="Vector_9"
                    d="M77.4632 60.5703H70.3033V67.7303H77.4632V60.5703Z"
                    fill="#001E50"
                />
                <path
                    id="Vector_10"
                    d="M74.5233 85.6502L74.4632 83.5702C85.0332 83.2602 93.3033 74.7302 93.3033 64.1602C93.3033 59.9902 92.0033 56.0202 89.5433 52.6702L91.2233 51.4402C93.9433 55.1502 95.3833 59.5502 95.3833 64.1602C95.3833 75.8702 86.2133 85.3102 74.5133 85.6602L74.5233 85.6502ZM69.0433 85.1102C63.5733 83.8502 58.8733 80.5502 55.8133 75.8202L57.5633 74.6902C60.3333 78.9702 64.5733 81.9501 69.5133 83.0901L69.0433 85.1202V85.1102ZM53.4433 70.8401C52.7333 68.6901 52.3833 66.4402 52.3833 64.1502C52.3833 55.1102 58.0933 46.9802 66.5933 43.9202L67.3033 45.8801C59.6233 48.6501 54.4733 55.9902 54.4733 64.1602C54.4733 66.2302 54.7933 68.2601 55.4333 70.2001L53.4533 70.8502L53.4433 70.8401ZM86.1233 49.0602C82.6833 46.2702 78.3333 44.7302 73.8932 44.7302C73.3132 44.7302 72.7333 44.7602 72.1533 44.8102L71.9733 42.7402C72.6033 42.6802 73.2432 42.6602 73.8932 42.6602C78.8132 42.6602 83.6233 44.3602 87.4333 47.4602L86.1233 49.0802V49.0602Z"
                    fill="#001E50"
                />
                <path
                    id="Vector_11"
                    d="M114.013 131.39H104.793V122.17H114.013V131.39ZM107.563 128.62H111.233V124.95H107.563V128.62Z"
                    fill="#001E50"
                />
                <path
                    id="Vector_12"
                    d="M43.2033 131.39H33.9833V122.17H43.2033V131.39ZM36.7633 128.62H40.4333V124.95H36.7633V128.62Z"
                    fill="#001E50"
                />
                <path
                    id="Vector_13"
                    d="M114.013 9.22H104.793V0H114.013V9.22ZM107.563 6.44H111.233V2.77H107.563V6.44Z"
                    fill="#001E50"
                />
                <path
                    id="Vector_14"
                    d="M43.2033 9.22H33.9833V0H43.2033V9.22ZM36.7633 6.44H40.4333V2.77H36.7633V6.44Z"
                    fill="#001E50"
                />
                <path
                    id="Vector_15"
                    d="M147.333 68.9202H138.113V59.7002H147.333V68.9202ZM140.883 66.1402H144.553V62.4702H140.883V66.1402Z"
                    fill="#001E50"
                />
                <path
                    id="Vector_16"
                    d="M9.88325 68.9202H0.663254V59.7002H9.88325V68.9202ZM3.43325 66.1402H7.10325V62.4702H3.43325V66.1402Z"
                    fill="#001E50"
                />
                <g id="Group_2" opacity="0.25">
                    <path
                        id="Vector_17"
                        d="M73.9133 95.5295C68.6233 95.5295 63.3533 94.1995 58.6833 91.6995L59.6732 89.8695C64.0332 92.2195 68.9633 93.4495 73.9133 93.4495C79.1433 93.4495 84.3133 92.0595 88.8633 89.4295C94.8433 85.9795 99.4733 80.5295 101.903 74.0895L103.853 74.8195C101.253 81.7095 96.3033 87.5395 89.9033 91.2295C85.0333 94.0395 79.5033 95.5295 73.9133 95.5295ZM54.1533 88.7295C50.9133 86.1995 48.2233 83.0995 46.1533 79.5095C45.4833 78.3495 44.8833 77.1395 44.3633 75.9095L46.2832 75.0995C46.7632 76.2495 47.3333 77.3795 47.9533 78.4595C49.8933 81.8195 52.4132 84.7195 55.4333 87.0795L54.1533 88.7195V88.7295ZM42.6833 70.7495C42.1333 68.3895 41.8532 65.9495 41.8532 63.5195C41.8532 55.0095 45.3032 46.7095 51.3232 40.7495L52.7933 42.2295C47.1633 47.8095 43.9433 55.5695 43.9433 63.5195C43.9433 65.7995 44.2033 68.0695 44.7233 70.2795L42.6933 70.7495H42.6833ZM105.333 69.6095L103.293 69.2095C103.663 67.3195 103.843 65.3895 103.843 63.4695C103.843 60.2995 103.343 57.1695 102.353 54.1595L104.333 53.5095C105.393 56.7295 105.923 60.0695 105.923 63.4695C105.923 65.5195 105.723 67.5895 105.333 69.6095ZM100.363 49.4895C100.193 49.1595 100.013 48.8395 99.8333 48.5195C94.8333 39.8495 85.8733 34.2795 75.8833 33.5995L76.0233 31.5195C86.7133 32.2395 96.2833 38.1995 101.633 47.4695C101.833 47.8095 102.023 48.1595 102.213 48.5095L100.373 49.4795L100.363 49.4895ZM56.6732 38.9795L55.4833 37.2695C56.2633 36.7295 57.0633 36.2095 57.8733 35.7495C61.8133 33.4695 66.0933 32.0795 70.5933 31.6195L70.8033 33.6895C66.6033 34.1195 62.6033 35.4195 58.9133 37.5495C58.1533 37.9895 57.4032 38.4695 56.6732 38.9695V38.9795Z"
                        fill="#030C26"
                    />
                </g>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_223_5649"
                    x1="5.32865"
                    y1="-3.4916"
                    x2="143.374"
                    y2="134.553"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00C5FC" />
                    <stop offset="0.07" stopColor="#00BCF6" />
                    <stop offset="0.7" stopColor="#0279C9" />
                    <stop offset="1" stopColor="#045FB8" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_223_5649"
                    x1="73.8833"
                    y1="64.1503"
                    x2="139.493"
                    y2="64.1503"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#045FB8" stopOpacity="0" />
                    <stop
                        offset="0.11"
                        stopColor="#0360B9"
                        stopOpacity="0.02"
                    />
                    <stop
                        offset="0.23"
                        stopColor="#0366BC"
                        stopOpacity="0.07"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#036FC3"
                        stopOpacity="0.16"
                    />
                    <stop offset="0.5" stopColor="#027CCB" stopOpacity="0.29" />
                    <stop
                        offset="0.64"
                        stopColor="#028DD6"
                        stopOpacity="0.45"
                    />
                    <stop
                        offset="0.79"
                        stopColor="#01A1E4"
                        stopOpacity="0.65"
                    />
                    <stop
                        offset="0.93"
                        stopColor="#00B9F4"
                        stopOpacity="0.88"
                    />
                    <stop offset="1" stopColor="#00C5FC" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_223_5649"
                    x1="-22.6667"
                    y1="-33.9996"
                    x2="8.49323"
                    y2="64.1503"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#045FB8" stopOpacity="0" />
                    <stop
                        offset="0.11"
                        stopColor="#0360B9"
                        stopOpacity="0.02"
                    />
                    <stop
                        offset="0.23"
                        stopColor="#0366BC"
                        stopOpacity="0.07"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#036FC3"
                        stopOpacity="0.16"
                    />
                    <stop offset="0.5" stopColor="#027CCB" stopOpacity="0.29" />
                    <stop
                        offset="0.64"
                        stopColor="#028DD6"
                        stopOpacity="0.45"
                    />
                    <stop
                        offset="0.79"
                        stopColor="#01A1E4"
                        stopOpacity="0.65"
                    />
                    <stop
                        offset="0.93"
                        stopColor="#00B9F4"
                        stopOpacity="0.88"
                    />
                    <stop offset="1" stopColor="#00C5FC" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_223_5649"
                    x1="68.5289"
                    y1="36.2673"
                    x2="108.449"
                    y2="36.2673"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#045FB8" stopOpacity="0" />
                    <stop
                        offset="0.11"
                        stopColor="#0360B9"
                        stopOpacity="0.02"
                    />
                    <stop
                        offset="0.23"
                        stopColor="#0366BC"
                        stopOpacity="0.07"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#036FC3"
                        stopOpacity="0.16"
                    />
                    <stop offset="0.5" stopColor="#027CCB" stopOpacity="0.29" />
                    <stop
                        offset="0.64"
                        stopColor="#028DD6"
                        stopOpacity="0.45"
                    />
                    <stop
                        offset="0.79"
                        stopColor="#01A1E4"
                        stopOpacity="0.65"
                    />
                    <stop
                        offset="0.93"
                        stopColor="#00B9F4"
                        stopOpacity="0.88"
                    />
                    <stop offset="1" stopColor="#00C5FC" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_223_5649"
                    x1="75.7218"
                    y1="94.4947"
                    x2="38.8118"
                    y2="94.4947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#045FB8" stopOpacity="0" />
                    <stop
                        offset="0.11"
                        stopColor="#0360B9"
                        stopOpacity="0.02"
                    />
                    <stop
                        offset="0.23"
                        stopColor="#0366BC"
                        stopOpacity="0.07"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#036FC3"
                        stopOpacity="0.16"
                    />
                    <stop offset="0.5" stopColor="#027CCB" stopOpacity="0.29" />
                    <stop
                        offset="0.64"
                        stopColor="#028DD6"
                        stopOpacity="0.45"
                    />
                    <stop
                        offset="0.79"
                        stopColor="#01A1E4"
                        stopOpacity="0.65"
                    />
                    <stop
                        offset="0.93"
                        stopColor="#00B9F4"
                        stopOpacity="0.88"
                    />
                    <stop offset="1" stopColor="#00C5FC" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_223_5649"
                    x1="75.6868"
                    y1="35.454"
                    x2="39.3867"
                    y2="35.454"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#045FB8" stopOpacity="0" />
                    <stop
                        offset="0.11"
                        stopColor="#0360B9"
                        stopOpacity="0.02"
                    />
                    <stop
                        offset="0.23"
                        stopColor="#0366BC"
                        stopOpacity="0.07"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#036FC3"
                        stopOpacity="0.16"
                    />
                    <stop offset="0.5" stopColor="#027CCB" stopOpacity="0.29" />
                    <stop
                        offset="0.64"
                        stopColor="#028DD6"
                        stopOpacity="0.45"
                    />
                    <stop
                        offset="0.79"
                        stopColor="#01A1E4"
                        stopOpacity="0.65"
                    />
                    <stop
                        offset="0.93"
                        stopColor="#00B9F4"
                        stopOpacity="0.88"
                    />
                    <stop offset="1" stopColor="#00C5FC" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_223_5649"
                    x1="72.0616"
                    y1="94.4796"
                    x2="108.852"
                    y2="94.4796"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#045FB8" stopOpacity="0" />
                    <stop
                        offset="0.11"
                        stopColor="#0360B9"
                        stopOpacity="0.02"
                    />
                    <stop
                        offset="0.23"
                        stopColor="#0366BC"
                        stopOpacity="0.07"
                    />
                    <stop
                        offset="0.37"
                        stopColor="#036FC3"
                        stopOpacity="0.16"
                    />
                    <stop offset="0.5" stopColor="#027CCB" stopOpacity="0.29" />
                    <stop
                        offset="0.64"
                        stopColor="#028DD6"
                        stopOpacity="0.45"
                    />
                    <stop
                        offset="0.79"
                        stopColor="#01A1E4"
                        stopOpacity="0.65"
                    />
                    <stop
                        offset="0.93"
                        stopColor="#00B9F4"
                        stopOpacity="0.88"
                    />
                    <stop offset="1" stopColor="#00C5FC" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default Hexagon
