import { API_SERVICE_URL } from '../config/service'
import { ChatCompletionRequest } from '../models/chatCompletionRequest'
import api, { isLoggedIn } from './auth/client'
import apiNoAuth from './auth/noAuthClient'
const serviceConfig = require('../config/service.json')

import { RetrievalModelRequest } from '../models/retrievalModelRequest'

class ChatCompletionService {
    async getAIModels(qParam: any) {
        var url = API_SERVICE_URL + serviceConfig.getAIModels + qParam
        if (await isLoggedIn()) return api.get(url)

        return apiNoAuth.get(url)
    }

    async SubmitChatQuery(request: ChatCompletionRequest) {
        var url = API_SERVICE_URL + serviceConfig.aiModelChatCompletion
        if (await isLoggedIn()) return api.post(url, request)
        return apiNoAuth.post(url, request)
    }

    async SubmitRetrievalQuery(request: RetrievalModelRequest) {
        var url = API_SERVICE_URL + serviceConfig.aiModelEmbeddings

        if (await isLoggedIn()) return api.post(url, request)
        return apiNoAuth.post(url, request)
    }
}
export default new ChatCompletionService()
