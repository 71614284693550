/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { Cache } from '@aws-amplify/cache'
import { fetchAuthSession } from 'aws-amplify/auth'
import {
    ChangeEvent,
    FormEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react'
import {
    Alert,
    Form,
    FormLabel,
    OverlayTrigger,
    Popover,
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import CustomButton from '../../../components/customButton'
import ErrorPanel from '../../../components/errorPanel'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { LoadingState } from '../../../models/loadingState'
import { SupportRequest } from '../../../models/supportRequest'
import { submitSupport, supportAttachFile } from '../../../store/support.slice'
import { IAiModelCardProps } from '../../aiModelCard'
import LoadingSection from '../loadingSection'

const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    //justify-content: initial;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
`
const OutputContainerTab = styled.div`
    display: flex;
    //justify-content: center;
    min-height: 31.5rem;
    //max-height: 36.5rem;
    background: transparent;
    color: rgb(248, 248, 242);
    width: 100%;
    padding: 1rem;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
`

const PopoverKeyDiv = styled.div`
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
`
const Container = styled.div`
    display: flex;
    //justify-content: center;
    flex-direction: column;
    alignitems: center;
    height: 100%;
    width: 100%;
    padding: 1rem;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
    color: var(--input-label-light-default, #494b51);
`

const RequiredTextLabel = styled.span`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-bottom: 1rem;
    width: auto;
    justify-content: center;
`
export interface ISupportProps {
    model: IAiModelCardProps
}

const SupportTab: FunctionComponent<ISupportProps> = ({
    model,
}: ISupportProps) => {
    let navigate = useNavigate()

    const [email, setUserEmail] = useState<string>('')

    const getRefreshToken = useCallback(async () => {
        try {
            const response = await fetchAuthSession()

            const email = response?.tokens?.idToken?.payload?.email
            setUserEmail(typeof email === 'string' ? email : '')

            const refresh_token = response?.tokens?.accessToken
            if (!refresh_token) {
                Cache.clear()
                navigate(`/signIn?supportRedirect=/solution/${model.uuid}`)
            }
        } catch (err) {
            console.error(err)
            Cache.clear()
            navigate(`/signIn?supportRedirect=/solution/${model.uuid}`)
        }
    }, [])

    useEffect(() => {
        getRefreshToken()
    }, [])

    const dispatch = useAppDispatch()

    const [supportSelectedFileName, setSupportSelectedFileName] = useState('')
    const [selectedFiles, setSelectedFiles] = useState<FileList>()
    const [validated, setValidated] = useState(false)
    const [isSupportLoad, setIsSupportLoad] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const [inputText, setInputText] = useState('')
    const [errorFile, setErrorFile] = useState<string | undefined>(undefined)
    const [supportPriority, setSupportPriority] = useState<string>('low')

    const onInputTextChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        setInputText(target.value)
    }, [])

    useEffect(() => {
        if (inputText.length > 0) {
            setIsSupportLoad(true)
        } else {
            setIsSupportLoad(false)
        }
    }, [inputText])

    const onPrioritySelectChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLSelectElement
            if (target.value) {
                setSupportPriority(target.value)
            }
        },
        []
    )

    const onSupportFileChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLInputElement

            if (target.files) {
                setSupportSelectedFileName(target.files[0].name)
                setSelectedFiles(target.files)
            }
        },
        []
    )

    const onSupportFormSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)

            if (inputText) {
                var supportRequest = new SupportRequest()
                supportRequest.metadata.model = model.name
                supportRequest.metadata.uuid = model.uuid

                supportRequest.description = inputText
                supportRequest.priority = supportPriority
                    ? supportPriority
                    : 'low'
                if (supportRequest.description) {
                    setIsClicked(true)
                    dispatch(submitSupport(supportRequest))
                    setIsSupportLoad(false)
                }
            }
        },
        [inputText, supportSelectedFileName, supportPriority]
    )

    const supportResult = useAppSelector<any>(
        (state) => state.submitSupportResult.data
    )

    const supportResultLoading = useAppSelector<any>(
        (state) => state.submitSupportResult.loading
    )

    const supportResultError = useAppSelector<any>(
        (state) => state.submitSupportResult.error
    )

    useEffect(() => {
        if (supportResultLoading !== LoadingState.Pending) {
            if (supportResult && !isSupportLoad) {
                if (
                    supportResult?.uuid !== undefined &&
                    selectedFiles &&
                    selectedFiles.length > 0
                ) {
                    const payload = {
                        id: supportResult.uuid,
                        file: selectedFiles,
                    }

                    dispatch(supportAttachFile(payload))
                } else {
                    setIsSupportLoad(true)
                }
            }
        }
    }, [supportResult, supportResultLoading, isSupportLoad, selectedFiles])

    const supportAttachResult = useAppSelector<any>(
        (state) => state.supportAttachFileResult.data
    )

    const supportAttachError = useAppSelector<any>(
        (state) => state.supportAttachFileResult.error
    )

    useEffect(() => {
        if (supportAttachResult) {
            setIsSupportLoad(true)
        }
    }, [supportAttachResult])

    useEffect(() => {
        if (supportAttachError || supportResultError) {
            setIsSupportLoad(true)
        }
    }, [supportAttachError, supportResultError])

    return (
        <>
            <OutputInnerContainer>
                <OutputContainerTab>
                    <Container>
                        <AlertComponent
                            variant="success"
                            show={
                                isSupportLoad &&
                                isClicked &&
                                (supportAttachResult || supportResult)
                            }
                        >
                            Your support request has been sent!
                        </AlertComponent>

                        <Alert
                            variant="primary"
                            show={
                                isSupportLoad &&
                                isClicked &&
                                (supportResultError || supportAttachError)
                            }
                            style={{
                                width: '100%',
                                backgroundColor: 'rgb(193, 166, 242, 0.2)',
                                borderColor: 'rgb(193, 166, 242, 0.2)',
                                display: 'flex',
                                marginTop: '1rem',
                                justifyContent: 'center',
                                borderRadius: '0',
                            }}
                        >
                            <ErrorPanel />
                        </Alert>
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={onSupportFormSubmit}
                            id="feedbackForm"
                        >
                            {/** Hidden email input, do we want an actual name entry as well */}
                            <Form.Control
                                id="email"
                                name="email"
                                as="text"
                                value={email}
                                hidden
                            />

                            <TextLabel>Priority:</TextLabel>
                            <Form.Select
                                size="sm"
                                onChange={onPrioritySelectChange}
                            >
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                                <option value="critical">Critical</option>
                            </Form.Select>
                            <div style={{ paddingTop: '1rem' }}>
                                <TextLabel>
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Description:
                                </TextLabel>
                                <Form.Control
                                    id="input"
                                    name="input"
                                    placeholder="Enter description.."
                                    as="textarea"
                                    rows={5}
                                    onChange={onInputTextChange}
                                    autoFocus
                                    required
                                />
                            </div>
                            <div style={{ paddingTop: '1rem' }}>
                                <TextLabel>
                                    Attach Document
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_timeline"
                                                style={{
                                                    maxWidth: '20%',
                                                }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        Attach a document to
                                                        provide more
                                                        information, the maximum
                                                        file size is 50 MB
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Form.Group>
                                    <Form.Control
                                        type="file"
                                        id={`file`}
                                        name={`file`}
                                        onChange={onSupportFileChange}
                                        key={`file`}
                                        data-testid={`file-value`}
                                    />
                                </Form.Group>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    paddingTop: '0.5rem',
                                    justifyContent: 'right',
                                }}
                            >
                                <CustomButton
                                    styleType="dark"
                                    disabled={!isSupportLoad}
                                    type="submit"
                                    text="Submit"
                                />
                            </div>
                        </Form>
                        {supportResultLoading == LoadingState.Pending && (
                            <LoadingSection />
                        )}
                    </Container>
                </OutputContainerTab>
            </OutputInnerContainer>
        </>
    )
}

export default SupportTab
