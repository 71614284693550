import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import AnalyticsService from '../services/analyticsService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const postDownloadEvent = createAsyncThunk(
    'postDownloadEvent',
    async (args: any) => await AnalyticsService.postDownloadEvent(args)
)

const postDownloadEventSlice = createSlice({
    name: 'postDownloadEvent',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postDownloadEvent.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(postDownloadEvent.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(postDownloadEvent.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getDashboardData = createAsyncThunk(
    'getDashboardData/getDashboardData',
    async (args: any) => await AnalyticsService.getDashboardData(args)
)

const getDashboardDataSlice = createSlice({
    name: 'getDashboardData',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardData.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getDashboardData.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getDashboardData.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getAnalytics = createAsyncThunk(
    'getAnalytics/getAnalytics',
    async (args: any) => await AnalyticsService.getAnalytics(args)
)

const getAnalyticsSlice = createSlice({
    name: 'getAnalytics',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAnalytics.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getAnalytics.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getAnalytics.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getSearchMisses = createAsyncThunk(
    'getSearchMisses/getSearchMisses',
    async (args: any) => await AnalyticsService.getSearchMisses(args)
)

const getSearchMissesSlice = createSlice({
    name: 'getSearchMisses',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSearchMisses.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getSearchMisses.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getSearchMisses.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getModelUsage = createAsyncThunk(
    'getModelUsage/getModelUsage',
    async (args: any) => await AnalyticsService.getModelUsage(args)
)

const getModelUsageSlice = createSlice({
    name: 'getModelUsage',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getModelUsage.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getModelUsage.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getModelUsage.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getIntelSearchDist = createAsyncThunk(
    'getSearchDist/getIntelSearchDist',
    async (args: any) => await AnalyticsService.getSearchDist(args)
)

const getIntelSearchDistSlice = createSlice({
    name: 'getIntelSearchDist',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getIntelSearchDist.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getIntelSearchDist.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getIntelSearchDist.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getUniqueLogins = createAsyncThunk(
    'getUniqueLogins/getUniqueLogins',
    async (timeStr: string) => await AnalyticsService.getUniqueLogins(timeStr)
)

const getUniqueLoginsSlice = createSlice({
    name: 'getUniqueLogins',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUniqueLogins.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getUniqueLogins.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getUniqueLogins.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getUniqueLoginsWithToDate = createAsyncThunk(
    'getUniqueLoginsWithToDate/getUniqueLoginsWithToDate',
    async (timeObj: any) =>
        await AnalyticsService.getUniqueLoginsWithToDate(timeObj)
)

const getUniqueLoginsWithToDateSlice = createSlice({
    name: 'getUniqueLoginsWithToDate',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUniqueLoginsWithToDate.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                getUniqueLoginsWithToDate.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                    }
                }
            )
            .addCase(
                getUniqueLoginsWithToDate.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})

export const getClickCounts = createAsyncThunk(
    'getClickCounts/getClickCounts',
    async (obj: any) => await AnalyticsService.getClickCounts(obj)
)

const getClickCountsSlice = createSlice({
    name: 'getClickCounts',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClickCounts.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getClickCounts.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getClickCounts.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const getEngagedUsers = createAsyncThunk(
    'getEngagedUsers/getEngagedUsers',
    async (obj: any) => await AnalyticsService.getEngagedUsers(obj)
)

const getEngagedUsersSlice = createSlice({
    name: 'getEngagedUsers',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEngagedUsers.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getEngagedUsers.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getEngagedUsers.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getDisengagedUsers = createAsyncThunk(
    'getDisengagedUsers/getDisengagedUsers',
    async (obj: any) => await AnalyticsService.getDisengagedUsers(obj)
)

const getDisengagedUsersSlice = createSlice({
    name: 'getDisengagedUsers',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDisengagedUsers.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getDisengagedUsers.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getDisengagedUsers.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getFirstTimeCustomers = createAsyncThunk(
    'getFirstTimeCustomers/getFirstTimeCustomers',
    async (obj: any) => await AnalyticsService.getFirstTimeCustomers(obj)
)

const getFirstTimeCustomersSlice = createSlice({
    name: 'getFirstTimeCustomers',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFirstTimeCustomers.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getFirstTimeCustomers.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getFirstTimeCustomers.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getUserDomainDistribution = createAsyncThunk(
    'getUserDomainDistribution/getUserDomainDistribution',
    async (obj: any) => await AnalyticsService.getUserDomainDistribution(obj)
)

const getUserDomainDistributionSlice = createSlice({
    name: 'getUserDomainDistribution',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserDomainDistribution.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                getUserDomainDistribution.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                    }
                }
            )
            .addCase(
                getUserDomainDistribution.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})

export const getModelsDownloads = createAsyncThunk(
    'getModelsDownloads/getModelsDownloads',
    async (obj: any) => await AnalyticsService.getModelsDownloads(obj)
)

const getModelsDownloadsSlice = createSlice({
    name: 'getModelsDownloads',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getModelsDownloads.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getModelsDownloads.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getModelsDownloads.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const postDownloadEventResult = postDownloadEventSlice.reducer
export const getDashboardDataResult = getDashboardDataSlice.reducer
export const getAnalyticsResult = getAnalyticsSlice.reducer
export const getIntelSearchDistResult = getIntelSearchDistSlice.reducer
export const getUniqueLoginsResult = getUniqueLoginsSlice.reducer
export const getUniqueLoginsWithToDateResult =
    getUniqueLoginsWithToDateSlice.reducer
export const getClickCountsResult = getClickCountsSlice.reducer
export const getEngagedUsersResult = getEngagedUsersSlice.reducer
export const getDisengagedUsersResult = getDisengagedUsersSlice.reducer
export const getFirstTimeCustomersResult = getFirstTimeCustomersSlice.reducer
export const getUserDomainDistributionResult =
    getUserDomainDistributionSlice.reducer
export const getSearchMissesResult = getSearchMissesSlice.reducer
export const getModelUsageResult = getModelUsageSlice.reducer
export const getModelsDownloadsResult = getModelsDownloadsSlice.reducer
