import {
    APP_DOMAIN,
    COGNITO_CLIENT_ID,
    COGNITO_LOGIN_URL,
    COGNITO_USER_POOL_ID,
} from '../../config/service'

import { Amplify, type ResourcesConfig } from 'aws-amplify'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { CookieStorage, Hub } from 'aws-amplify/utils'

const port = window.location.port
let formattedport =
    port === undefined || port === null || port.trim() === '' ? '' : ':' + port
let location_callback = `${window.location.protocol}//${window.location.hostname}${formattedport}/login/callback`
let location_logout = `${window.location.protocol}//${window.location.hostname}${formattedport}`

const authConfig: ResourcesConfig['Auth'] = {
    Cognito: {
        userPoolId: COGNITO_USER_POOL_ID,
        userPoolClientId: COGNITO_CLIENT_ID,
        loginWith: {
            oauth: {
                domain: COGNITO_LOGIN_URL,
                scopes: ['email profile openid'],
                redirectSignIn: [location_callback],
                redirectSignOut: [location_logout],
                responseType: 'code',
            },
        },
    },
}

cognitoUserPoolsTokenProvider.setAuthConfig(authConfig)

cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({
        domain: APP_DOMAIN,
    })
)

const amplify_conf = {
    Auth: authConfig,
}

Amplify.configure(amplify_conf, {
    Auth: { tokenProvider: cognitoUserPoolsTokenProvider },
})

Hub.listen('auth', (data) => {
    const { payload } = data
    console.log('A new auth event has happened: ', data.payload.event)

    console.log(payload.event)
    switch (payload.event) {
        case 'signInWithRedirect':
        case 'signedIn':
            console.log('Authenticated...')
            break
        case 'signInWithRedirect_failure':
            console.log('Error', data)
            break
    }
})

export default amplify_conf
