import { FunctionComponent } from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styled from 'styled-components'
import { customMarkdownComponents } from '../../../utils/markdown'
import { IAiModelCardProps } from '../../aiModelCard'

const DocumentationContent = styled.div`
    height: 100%;
    width: 100%;
`

const PlaceholderCopy = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h3 {
        font-weight: 300;
    }
`

interface DocumentationProps {
    model: IAiModelCardProps
}

const DocumentationTab: FunctionComponent<DocumentationProps> = ({ model }) => {
    const renderContent = () => {
        if (!model.documentation)
            return (
                <PlaceholderCopy>
                    <h3>Version Details: Coming Soon</h3>
                </PlaceholderCopy>
            )

        return (
            <Markdown
                remarkPlugins={[remarkGfm]}
                components={customMarkdownComponents}
            >
                {model.documentation}
            </Markdown>
        )
    }

    return <DocumentationContent>{renderContent()}</DocumentationContent>
}

export default DocumentationTab
