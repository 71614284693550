import { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import { Badge, Button, Form, Stack } from 'react-bootstrap'
import styled from 'styled-components'

import { LayoutStylePreference } from '../models/layoutStyle'

const ModelCardComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
    text-align: center;
`
const ModelCardButton = styled(Button)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    border-radius: 0;
    overflow: hidden;
    width: 285px;
    height: 350px;
    max-width: 350px;
    background: #fff;
    border: none;
`
const ActiveModelCardButton = styled(ModelCardButton)`
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #e2e2e4;
    &:hover {
        background: #000;
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
        transition: 0.5s;
        border: 1px solid rgb(101, 49, 113);
    }
    &:focus {
        background: #000;
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
        transition: 0.5s;
        border: 1px solid rgb(101, 49, 113);
    }
`
const ModelHCardContainer = styled.button`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: #000;
    border-radius: 0;
    margin-left: 2.5rem;
    margin-bottom: 2rem;
    cursor: unset !important;
`
const ModelRCardContainer = styled(Button)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background: #000;
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
    border: 2px solid #e2e2e4;
    border-radius: 0;
    margin-left: 2.5rem;
    margin-bottom: 2rem;
    &:hover {
        background: #000;
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
        transition: 0.5s;
        border: 1px solid rgb(101, 49, 113);
    }
    &:focus {
        background: #000;
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
        transition: 0.5s;
        border: 1px solid rgb(101, 49, 113);
    }
    &:disabled {
        background: #000;
        border: none;
    }
`

const CardImageContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 2rem;
`
const DataContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 24px;
    //width: 100%;
`
const HDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 2rem 0;
`
const CategoriesDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    //padding: 1rem;
`
const CardName = styled.span`
    display: flex;
    color: #fff;
    text-align: left;
    justify-content: left;
    font-size: 1.2rem;
    font-family: 'IntelOne Text';
    font-weight: 700;
`
const PreviewSpan = styled(CardName)`
    font-weight: 400;
    font-size: 1rem;
`

const PublishedBy = styled(CardName)`
    font-weight: 500;
    font-size: 1rem;
`
const PoweredBy = styled(CardName)`
    font-weight: 500;
    font-size: 0.9rem;
    padding-botton: 0.5rem;
`
const CardDescription = styled(CardName)`
    font-size: 1rem;
    font-weight: 700;
`
const CardImageComponent = styled.img`
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0px;
    object-fit: cover;
    object-position: center center;
    color: transparent;
    border-radius: 0;
    filter: brightness(75%);
    &:hover {
        -webkit-animation: fadeOut 0.5s forwards;
        animation: fadeOut 0.5s forwards;
    }
`
export interface IAiModelCardProps {
    onCardClickCallBack?: (name: string) => void
    onHCardClickCallBack?: (name: string) => void
    uuid?: string
    modelName: string
    displayName: string
    publishedBy: string
    imageURL: string
    category: []
    description: string
    renderStyle: LayoutStylePreference
    isActive?: boolean
    showCheckboxPanel?: boolean
    isChecked?: boolean
    index?: number
    showRadioOptionPanel?: boolean
    solution?: string
    isDeployView?: boolean
    poweredBy?: any
}

const AiModelCardV1: FunctionComponent<IAiModelCardProps> = ({
    onCardClickCallBack,
    onHCardClickCallBack,
    uuid,
    modelName,
    displayName,
    publishedBy,
    imageURL,
    category,
    description,
    renderStyle,
    isActive,
    showCheckboxPanel,
    isChecked,
    index,
    showRadioOptionPanel,
    solution,
    isDeployView,
    poweredBy,
}: IAiModelCardProps) => {
    const [isHovering, setIsHovering] = useState(false)
    const handleOnCardClick = useCallback(
        (event: FormEvent<HTMLElement>, name: string) => {
            if (onCardClickCallBack) onCardClickCallBack(name)
        },
        []
    )
    const handleOnHCardClick = useCallback(
        (event: FormEvent<HTMLElement>, name: string) => {
            if (onHCardClickCallBack) onHCardClickCallBack(name)
        },

        []
    )

    const handleMouseOver = () => {
        if (isActive) setIsHovering(true)
    }

    const handleMouseOut = () => {
        if (isActive) setIsHovering(false)
    }
    const renderCheckboxPanel = (
        <Form.Check
            type="checkbox"
            key={displayName}
            //id={displayName}
            value={displayName}
            label=""
            aria-label={displayName}
            checked={isChecked}
            //onChange={onCardCheckChange}
            onChange={(e) => {}}
            disabled={isDeployView ? !isActive : false}
        />
    )

    const renderRadioOptionPanel = (
        <Form.Check
            type="radio"
            key={modelName}
            id={modelName}
            value={modelName}
            label=""
            aria-label={modelName}
            checked={isChecked}
            disabled={!isActive}
            onChange={(e) => {}}
        />
    )

    const cardImage = <CardImageComponent src={imageURL} />
    const hCardImage = (
        <img
            src={imageURL}
            style={{
                height: '180px',
                width: '200px',
                color: 'transparent',
                borderRadius: '0',
                filter: 'brightness(75%)',
            }}
        />
    )
    const renderCategories = (category: string[]) => {
        return (
            <>
                <Stack direction="horizontal" gap={2}>
                    {category &&
                        category.map((item: string, index) => (
                            <Badge
                                bg="rgba(242, 242, 242, 0.6)"
                                text="light"
                                style={{
                                    padding: '0.5rem',
                                    backgroundColor: 'rgba(242, 242, 242, 0.5)',
                                    borderRadius: '0px',
                                    fontSize: '0.8rem',
                                }}
                                key={`badge-${index}`}
                            >
                                {item}
                            </Badge>
                        ))}
                </Stack>
            </>
        )
    }

    const renderPreview = (
        <Badge
            bg="rgba(242, 242, 242, 0.6)"
            text="normal"
            style={
                !isActive
                    ? {
                          color: '#000',
                          padding: '0.5rem',
                          backgroundColor: 'rgba(242, 242, 242, 0.6)',
                          borderRadius: '0px',
                      }
                    : {
                          padding: '0.5rem',
                          backgroundColor: 'rgba(242, 242, 242, 0.6)',
                          borderRadius: '0px',
                      }
            }
        >
            {isActive ? 'PREVIEW' : 'OFFLINE'}
        </Badge>
    )
    const renderCardInnerComponent = (
        <>
            <CardImageContainer>{cardImage}</CardImageContainer>
            <DataContainer
                style={isHovering ? { justifyContent: 'space-between' } : {}}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {showCheckboxPanel && renderCheckboxPanel}
                    {showRadioOptionPanel && renderRadioOptionPanel}
                    <PreviewSpan>
                        {!showCheckboxPanel &&
                            !showRadioOptionPanel &&
                            renderPreview}
                    </PreviewSpan>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                    }}
                >
                    {!isHovering && (
                        <>
                            <PublishedBy>{publishedBy}</PublishedBy>
                            <CardName>{displayName}</CardName>
                            {poweredBy && poweredBy.length > 0 && (
                                <PoweredBy>
                                    {`Powered by ${poweredBy[0]}`}
                                </PoweredBy>
                            )}
                            <CategoriesDiv>
                                {renderCategories(category)}
                            </CategoriesDiv>
                        </>
                    )}
                    {isHovering && (
                        <div
                            style={{
                                background: '#495057',
                                padding: '0.5rem',
                            }}
                        >
                            <PublishedBy>{publishedBy}</PublishedBy>
                            <CardName>{displayName}</CardName>
                            {poweredBy && poweredBy.length > 0 && (
                                <PoweredBy>
                                    {`Powered by ${poweredBy[0]}`}
                                </PoweredBy>
                            )}
                            <CardDescription>{description}</CardDescription>
                            <CategoriesDiv>
                                {renderCategories(category)}
                            </CategoriesDiv>
                        </div>
                    )}
                </div>
            </DataContainer>
        </>
    )

    return (
        <ModelCardComponent key={displayName + index}>
            {renderStyle === LayoutStylePreference.vStyle && isActive ? (
                <ActiveModelCardButton
                    tabIndex={0}
                    onClick={(e: FormEvent<HTMLElement>) =>
                        handleOnCardClick(e, displayName)
                    }
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                >
                    {renderCardInnerComponent}
                </ActiveModelCardButton>
            ) : renderStyle === LayoutStylePreference.vStyle && !isActive ? (
                <ModelCardButton
                    tabIndex={0}
                    onClick={(e: FormEvent<HTMLElement>) =>
                        handleOnCardClick(e, displayName)
                    }
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                >
                    {renderCardInnerComponent}
                </ModelCardButton>
            ) : renderStyle === LayoutStylePreference.rStyle ? (
                <ModelRCardContainer
                    tabIndex={0}
                    disabled={false}
                    onClick={handleOnHCardClick}
                >
                    <CardImageContainer>{hCardImage}</CardImageContainer>
                    <HDataContainer>
                        {/* <PreviewSpan>Preview</PreviewSpan> */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                                justifyContent: 'left',
                                maxWidth: '400px',
                            }}
                        >
                            <PublishedBy>{publishedBy}</PublishedBy>
                            <CardName>{displayName}</CardName>
                            <CategoriesDiv>
                                {renderCategories(category)}
                            </CategoriesDiv>
                            <CardDescription>{description}</CardDescription>
                        </div>
                    </HDataContainer>
                </ModelRCardContainer>
            ) : (
                <ModelHCardContainer
                    tabIndex={0}
                    //disabled={false}
                    //onClick={handleOnHCardClick}
                >
                    <CardImageContainer>{hCardImage}</CardImageContainer>
                    <HDataContainer>
                        {/* <PreviewSpan>Preview</PreviewSpan> */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                                justifyContent: 'left',
                                maxWidth: '400px',
                            }}
                        >
                            <PublishedBy>{publishedBy}</PublishedBy>
                            <CardName>{displayName}</CardName>
                            <CategoriesDiv>
                                {renderCategories(category)}
                            </CategoriesDiv>
                            <CardDescription>{description}</CardDescription>
                        </div>
                    </HDataContainer>
                </ModelHCardContainer>
            )}
        </ModelCardComponent>
    )
}

export default AiModelCardV1
