import { Amplify } from 'aws-amplify'
import { signIn as Signin } from 'aws-amplify/auth'
import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import stringUtils from '../../lib/stringUtils'
import amplify_conf from '../../services/auth/amplify'
import { IAuthenticationContainerLogicProps } from './authenticationContainer'
Amplify.configure(amplify_conf)

export function AuthenticationContainerLogic({
    invitationKey,
}: IAuthenticationContainerLogicProps) {
    const [validated, setValidated] = useState(false)
    const [userEmail, setUserEmail] = useState<string>('')
    const [userPassword, setUserPassword] = useState<string>('')
    const [signInError, setSignInError] = useState<any>('')

    let navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search)
    const supportRedirect = queryParams.get('supportRedirect')

    async function signIn(
        username: string,
        password: string,
        invitationKey: string | null
    ) {
        try {
            const { isSignedIn, nextStep } = await Signin({
                username,
                password,
            })
            if (isSignedIn) {
                //nevigate to application main page
                if (!stringUtils.isNullOrUndefinedOrEmpty(invitationKey)) {
                    navigate('/invitation')
                } else {
                    localStorage.setItem('SSOLogin', 'false')
                    navigate(
                        supportRedirect ? `${supportRedirect}?tab=Support` : '/'
                    )
                }
            }
        } catch (error) {
            setSignInError(error)
        }
    }

    const onUserEmailChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value) {
            setUserEmail(target.value)
        }
    }, [])
    const onUserPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setUserPassword(target.value)
            }
        },
        []
    )

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            let signInResult: any
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            setSignInError('')
            if (userEmail && userEmail.indexOf('@') > -1 && userPassword) {
                signIn(userEmail, userPassword, invitationKey) //cognito signIn
            }
        },
        [userEmail, userPassword]
    )

    return {
        signInError,
        validated,
        onSubmit,
        onUserEmailChange,
        onUserPasswordChange,
    }
}
