import { API_SERVICE_URL } from '../config/service'
import { ImageGenerationRequest } from '../models/imageGenerationRequest'
import api, { isLoggedIn } from './auth/client'
import apiNoAuth from './auth/noAuthClient'
const serviceConfig = require('../config/service.json')

class ImageGenerationService {
    async PostImageGeneration(request: ImageGenerationRequest) {
        var url = API_SERVICE_URL + serviceConfig.aiModalImageGeneration
        if (await isLoggedIn()) return api.post(url, request)
        return apiNoAuth.post(url, request)
    }
}
export default new ImageGenerationService()
