/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { Dispatch, FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'

import { postChatCompletion } from '../../../store/chatCompletion.slice'
import SummarizationInput from './summarizationInput'

import { ChatCompletionRequest } from '../../../models/chatCompletionRequest'
import { IAiModelCardProps } from '../../aiModelCard'

const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 35rem;
    padding: 1rem;
`

const PreStyle = styled.pre`
    margin: 0;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    background: transparent;
    width: 100%;
    color: #ae81ff;
    .span {
        color: #ae81ff;
    }
`

const OutputContainerTab = styled.div`
    display: flex;
    justify-content: center;
    background: transparent;
    color: rgb(248, 248, 242);
    width: 100%;
`

import { connect, ConnectedProps } from 'react-redux'
import { summarizationExampleData } from '../../../data/summarizationExampleData'
import { LoadingState } from '../../../models/loadingState'
import LoadingSection from '../../solutionLayout/loadingSection'

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        postChatQuery: (question: ChatCompletionRequest) =>
            dispatch(postChatCompletion(question)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        chatCompletionAnswer: state.postChatCompletionResult.data,
        chatCompletionLoading: state.postChatCompletionResult.loading,
        chatCompletionError: state.postChatCompletionResult.error,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type ISummarizationPanelProps = ReduxProps & {
    model: IAiModelCardProps
}
const SummarizationPanel: FunctionComponent<ISummarizationPanelProps> = ({
    model,
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
}: ISummarizationPanelProps) => {
    const [output, setOutput] = useState<string>('')

    const setOutputcontent = () => {
        const data = chatCompletionAnswer?.choices
            ? chatCompletionAnswer?.choices[0].message.content
            : summarizationExampleData

        setOutput(data)
    }

    useEffect(() => {
        setOutputcontent()
    }, [])

    useEffect(() => {
        setOutputcontent()
    }, [chatCompletionAnswer])

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    height: '100%',
                }}
            >
                <div id="chatDiv">
                    <SummarizationInput
                        model={model}
                        setOutput={setOutput}
                    ></SummarizationInput>
                </div>
            </div>

            <div
                id="seprator"
                style={{
                    width: '1px',
                    margin: '0 1rem',
                    backgroundColor: 'rgb(101, 49, 113)',
                }}
            ></div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    height: '100%',
                }}
            >
                <div id="outputtDiv">
                    <OutputInnerContainer>
                        {/* Retrieval Output */}

                        <pre
                            style={{
                                width: '100%',
                                margin: 0,
                                background: '#FFFFFF',
                                textAlign: 'justify',
                                padding: '1rem',
                            }}
                        >
                            <OutputContainerTab>
                                <PreStyle>
                                    {output === ''
                                        ? summarizationExampleData
                                        : output}
                                </PreStyle>
                            </OutputContainerTab>
                        </pre>
                        {chatCompletionLoading !== LoadingState.Idle && (
                            <LoadingSection />
                        )}
                    </OutputInnerContainer>
                </div>
            </div>
        </div>
    )
}

export default connector(SummarizationPanel)
