/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import {
    ChangeEvent,
    Dispatch,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { Form, OverlayTrigger, Popover } from 'react-bootstrap'
import styled from 'styled-components'
import { ImageGenerationRequest } from '../../models/imageGenerationRequest'
import { LoadingState } from '../../models/loadingState'
import { SubmitImageGeneration } from '../../store/imageGeneration.slice'

import { connect, ConnectedProps } from 'react-redux'
import CustomButton from '../../components/customButton'
import { QueueMessagePanel } from '../../components/customMessage'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { getQueueAttributes } from '../../store/queueAttributes.slice'
import { IAiModelCardProps } from '../aiModelCard'
import LoadingSection from '../solutionLayout/loadingSection'

const ChatContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-right: 1px solid rgb(101, 49, 113);
    border-radius: 0;
    padding: 1rem 0;
`
const InputContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 30.2rem;
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    alignitems: center;
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`
const TextLabel = styled.div`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
`
const PopOverKeyDiv = styled.div`
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
`

const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 35rem;
    padding: 1rem;
`

const OutputImageSpanContainer = styled.span`
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 1rem;
    position: relative;
`

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        submitImageGeneration: (request: ImageGenerationRequest) =>
            dispatch(SubmitImageGeneration(request)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        submitImageGenerationResult: state.submitImageGenerationResult.data,
        submitImageGenerationLoading: state.submitImageGenerationResult.loading,
        submitImageGenerationError: state.submitImageGenerationResult.error,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type IImageGenerationProps = ReduxProps & {
    model: IAiModelCardProps
}

const ImagePanel: FunctionComponent<IImageGenerationProps> = ({
    model,
    submitImageGeneration,
    submitImageGenerationResult,
    submitImageGenerationLoading,
}: IImageGenerationProps) => {
    const dispatch = useAppDispatch()
    const totalAttempt = 50
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const defaultInputQuery = 'A lion in the water'
    const defaultImagePath = '/aiModelImage/lion_water.png'
    const [inputText, setInputText] = useState(defaultInputQuery)

    const [selectedOutputTab, setSelectedOutputTab] = useState<string>('output')
    const [outputImageURL, setOutputImageURL] = useState(defaultImagePath)

    const [queueNumber, setQueueNumber] = useState<number>(0)

    const queueParam = `?model=${model.name}`

    const getQueueResult = useAppSelector<any>(
        (state: any) => state.getQueueAttributesResult.data
    )

    // is the goal here to block the user after 50 attempts forever? just clearing cache would bypass this
    const previousAttempt = localStorage.getItem(
        model.name ? model.name : 'stabilityai/stable-diffusion-2-base'
    )

    const [remainingAttempt, setRemainingAttempt] = useState<number>(
        previousAttempt && Number(window.atob(previousAttempt)) > 0
            ? Number(window.atob(previousAttempt))
            : totalAttempt
    )

    const maxAttemptError =
        'You have reached the max attempt to the Gen AI image generation. Please reach out to the administrator for help!'
    const contactUs = (
        <div style={{ marginLeft: '0.5rem' }}>
            <a href="mailto:optimizations@intel.com"> Contact administrator</a>
        </div>
    )

    const handleOutputButtonTabClick = useCallback(
        (name: string) => {
            setSelectedOutputTab(name)
        },
        [selectedOutputTab]
    )

    const handleGenerateClick = async () => {
        const request = new ImageGenerationRequest()
        request.prompt = inputText
        request.model = model.name
        request.response_format = 'b64_json'
        submitImageGeneration(request)
        //get queue info
        dispatch(getQueueAttributes(queueParam))
        setRemainingAttempt((preValue) => preValue - 1)
    }

    const base64ToBlobUsingFetch = async (
        b64_json: string,
        contentType: string
    ) => {
        const response = await fetch(`data:${contentType};base64,${b64_json}`)
        const blob = await response.blob()
        const fileBlobURL = URL.createObjectURL(blob)
        //console.log(fileBlobURL)
        setOutputImageURL(fileBlobURL)
        return fileBlobURL
    }

    useEffect(() => {
        if (
            submitImageGenerationResult &&
            submitImageGenerationLoading !== LoadingState.Pending
        ) {
            try {
                setQueueNumber(0)
                const fileBlobURL = base64ToBlobUsingFetch(
                    submitImageGenerationResult?.b64_json,
                    'image/png'
                )
                console.log(fileBlobURL)
            } catch (e) {
                console.log(`Error:${e}`)
            }
        }
    }, [submitImageGenerationResult, submitImageGenerationLoading])

    const handleResetClick = async () => {
        setInputText(defaultInputQuery)
        if (
            inputRef &&
            inputRef.current !== undefined &&
            inputRef.current !== null
        )
            inputRef.current.value = defaultInputQuery
        //set other input
        setOutputImageURL(defaultImagePath)
        setQueueNumber(0)
    }

    const onInputTextChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        setInputText(target.value)
    }, [])
    useEffect(() => {
        if (
            getQueueResult &&
            getQueueResult?.topics &&
            getQueueResult?.topics.length > 0 &&
            getQueueResult?.topics[0].depth &&
            getQueueResult?.topics[0].depth > 0
        ) {
            setQueueNumber(getQueueResult?.topics[0].depth)
        } else {
            setQueueNumber(0)
        }
    }, [getQueueResult])

    //TODO Bubble up image error to parent

    const renderChatContainer = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ChatContainer>
                    <InputContainer>
                        <Container>
                            <TextLabel>
                                Input
                                <OverlayTrigger
                                    placement={'right'}
                                    delay={{
                                        show: 250,
                                        hide: 400,
                                    }}
                                    overlay={
                                        <Popover id="pop_timeline">
                                            <Popover.Body>
                                                <PopOverKeyDiv>
                                                    Enter a prompt to receive an
                                                    AI-generated image.
                                                </PopOverKeyDiv>
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <i
                                        className="uil uil-info-circle pointer"
                                        tabIndex={0}
                                    ></i>
                                </OverlayTrigger>
                            </TextLabel>
                            <Form.Control
                                ref={inputRef}
                                id="input"
                                name="input"
                                placeholder={'Please enter your prompt here...'}
                                as="textarea"
                                rows={5}
                                onChange={onInputTextChange}
                                defaultValue={defaultInputQuery}
                                autoFocus
                            />
                        </Container>
                        {queueNumber && queueNumber > 0
                            ? QueueMessagePanel(queueNumber)
                            : ''}

                        <div style={{ marginBottom: '1rem' }}>
                            <CustomButton
                                styleType="dark"
                                text="Generate"
                                onClick={handleGenerateClick}
                                disabled={remainingAttempt === 0 ? true : false}
                            />
                            <CustomButton
                                styleType="darkOutline"
                                onClick={handleResetClick}
                                text="Reset"
                            />
                        </div>
                    </InputContainer>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <TextLabel
                            style={{
                                color: 'rgb(143, 143, 143)',
                                fontStyle: 'italic',
                                fontSize: '1rem',
                                padding: '0 1rem',
                            }}
                        >
                            {remainingAttempt} / {totalAttempt} requests
                            remaining
                        </TextLabel>
                    </div>
                </ChatContainer>

                <OutputInnerContainer style={{ minHeight: '32rem' }}>
                    <OutputImageSpanContainer>
                        <img
                            src={outputImageURL}
                            height="100%"
                            width="100%"
                            style={{ objectFit: 'contain', inset: 0 }}
                        ></img>
                    </OutputImageSpanContainer>
                    {submitImageGenerationLoading === LoadingState.Pending && (
                        <LoadingSection />
                    )}
                </OutputInnerContainer>
            </div>
        )
    }

    return renderChatContainer()
}

export default connector(ImagePanel)
