import { FunctionComponent } from 'react'

const Download: FunctionComponent = ({}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M0.5 9.9C0.632608 9.9 0.759785 9.95268 0.853553 10.0464C0.947322 10.1402 1 10.2674 1 10.4V12.9C1 13.1652 1.10536 13.4196 1.29289 13.6071C1.48043 13.7946 1.73478 13.9 2 13.9H14C14.2652 13.9 14.5196 13.7946 14.7071 13.6071C14.8946 13.4196 15 13.1652 15 12.9V10.4C15 10.2674 15.0527 10.1402 15.1464 10.0464C15.2402 9.95268 15.3674 9.9 15.5 9.9C15.6326 9.9 15.7598 9.95268 15.8536 10.0464C15.9473 10.1402 16 10.2674 16 10.4V12.9C16 13.4304 15.7893 13.9391 15.4142 14.3142C15.0391 14.6893 14.5304 14.9 14 14.9H2C1.46957 14.9 0.960859 14.6893 0.585786 14.3142C0.210714 13.9391 0 13.4304 0 12.9V10.4C0 10.2674 0.0526784 10.1402 0.146447 10.0464C0.240215 9.95268 0.367392 9.9 0.5 9.9Z"
                fill="white"
            />
            <path
                d="M7.646 11.854C7.69245 11.9006 7.74762 11.9375 7.80837 11.9627C7.86911 11.9879 7.93423 12.0009 8 12.0009C8.06577 12.0009 8.13089 11.9879 8.19163 11.9627C8.25238 11.9375 8.30755 11.9006 8.354 11.854L11.354 8.854C11.4479 8.76011 11.5006 8.63278 11.5006 8.5C11.5006 8.36722 11.4479 8.23989 11.354 8.146C11.2601 8.05211 11.1328 7.99937 11 7.99937C10.8672 7.99937 10.7399 8.05211 10.646 8.146L8.5 10.293V1.5C8.5 1.36739 8.44732 1.24021 8.35355 1.14645C8.25979 1.05268 8.13261 1 8 1C7.86739 1 7.74021 1.05268 7.64645 1.14645C7.55268 1.24021 7.5 1.36739 7.5 1.5V10.293L5.354 8.146C5.26011 8.05211 5.13278 7.99937 5 7.99937C4.86722 7.99937 4.73989 8.05211 4.646 8.146C4.55211 8.23989 4.49937 8.36722 4.49937 8.5C4.49937 8.63278 4.55211 8.76011 4.646 8.854L7.646 11.854Z"
                fill="white"
            />
        </svg>
    )
}

export default Download
