import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'
import { colors } from '../../../utils/colors'

const components: Components<Omit<Theme, 'components'>> = {
    MuiAlert: {
        styleOverrides: {
            root: {
                marginBlock: '1rem',
            },
        },
    },
    MuiButton: {
        defaultProps: {
            disableElevation: true,
            disableTouchRipple: true,
            variant: 'contained',
        },
        styleOverrides: {
            root: {
                borderRadius: 0,
                padding: '0.3125rem 0.9375rem',
                '& .MuiTouchRipple-root *': {
                    borderRadius: 0,
                    animation: 'none',
                },
                '&.MuiButton-sizeSmall': {
                    paddingInline: '0.75rem',
                    fontSize: '0.75rem',
                },
            },
        },
    },
    MuiButtonGroup: {
        defaultProps: {
            disableElevation: true,
        },
    },
    MuiDivider: {
        styleOverrides: {
            root: {
                borderColor: colors.lightGray400,
                opacity: 1,
            },
        },
    },
    MuiFormControl: {
        defaultProps: {
            fullWidth: true,
        },
        styleOverrides: {
            root: {
                marginBottom: '1rem',
            },
        },
    },
    MuiFormGroup: {
        styleOverrides: {
            root: {
                marginLeft: '0.5rem',
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                marginLeft: 0,
            },
        },
    },
    MuiFormLabel: {
        defaultProps: {
            focused: false,
        },
        styleOverrides: {
            root: {
                marginBottom: '0.25rem',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                color: colors.lightGray900,
            },
        },
    },
    MuiIconButton: {
        defaultProps: {
            disableFocusRipple: false,
            disableRipple: false,
            disableTouchRipple: false,
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontSize: '0.875rem',
            },
        },
    },
    MuiRadio: {
        defaultProps: {
            size: 'small',
        },
        styleOverrides: {
            root: {
                padding: '0.25rem',
            },
        },
    },
    MuiSelect: {
        styleOverrides: {
            root: {
                fontSize: '0.875rem',
                '& .MuiInputBase-input': {
                    fontSize: '0.875rem',
                },
            },
        },
    },
    MuiTextField: {
        defaultProps: {
            size: 'small',
            fullWidth: true,
        },
        styleOverrides: {
            root: {
                marginBottom: '1rem',
                '& .MuiInputBase-input': {
                    fontSize: '0.875rem',
                    paddingBlock: '0.5rem',
                },
                '& textarea.MuiInputBase-input': {
                    paddingBlock: 0,
                },
            },
        },
    },
    MuiToggleButton: {
        styleOverrides: {
            root: {
                paddingInline: '0.875rem',
                lineHeight: '1rem',
            },
        },
    },
}

export default components
