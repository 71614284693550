import { Box, CircularProgress, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { LoadingState } from '../../../../models/loadingState'
import DashboardCard from './DashboardCard'

const dashboardMetricCardBoxSx = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '8rem',
}

const dashboardMetricCardTitleSx = { textAlign: 'center', fontSize: '1.25rem' }

const circularProgressSx = { marginBlock: '0.75rem' }

const metricCountSx = { fontSize: '2.5rem' }

const metricTechnologyNameSx = { textOverflow: 'ellipsis' }

interface DashboardMetricCardProps {
    title: string
    count: number | null
    loading?: LoadingState | LoadingState[]
    technologyName?: string
    dataTestId: string
}

const DashboardMetricCard: FC<DashboardMetricCardProps> = ({
    title,
    count,
    loading,
    technologyName,
    dataTestId,
}) => {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(
            Array.isArray(loading)
                ? loading.some((value) => value === LoadingState.Pending)
                : loading === LoadingState.Pending
        )
    }, [loading])

    return (
        <DashboardCard>
            <Box sx={dashboardMetricCardBoxSx}>
                <Typography
                    variant="h6"
                    sx={dashboardMetricCardTitleSx}
                    data-testid={dataTestId}
                >
                    {title}
                </Typography>
                {isLoading ? (
                    <CircularProgress size={32} sx={circularProgressSx} />
                ) : (
                    <>
                        <Typography variant="body1" sx={metricCountSx}>
                            {count ? count : 'N/A'}
                        </Typography>
                        {technologyName && (
                            <Typography
                                variant="body2"
                                sx={metricTechnologyNameSx}
                            >
                                {technologyName}
                            </Typography>
                        )}
                    </>
                )}
            </Box>
        </DashboardCard>
    )
}

export default DashboardMetricCard
