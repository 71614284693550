import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { IAiModelCardProps } from '../../aiModelCard'

const VersionContent = styled.div`
    height: 100%;
    width: 100%;
`

const VersionWrapper = styled.div`
    border-bottom: 1px solid black;
    margin-bottom: 1rem;
    &:last-of-type {
        border-bottom: none;
    }
`

const HeaderRow = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        margin-bottom: 1rem;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;

        > div:last-child {
            min-width: 300px;
        }
    }
`

const VersionValue = styled.div`
    font-size: 150%;
`

const ReleaseNotes = styled.div`
    margin-top: 1rem;
    > div {
        font-size: 125%;
        margin-bottom: 0.5rem;
    }
`

const Assets = styled(ReleaseNotes)`
    a {
        display: block;
        margin-left: 0.5rem;
    }
    padding-bottom: 1rem;
    margin-bottom: 1rem;
`

const PlaceholderCopy = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    h3 {
        font-weight: 300;
    }
`

interface VersionProps {
    model: IAiModelCardProps
}

const VersionTab: FunctionComponent<VersionProps> = ({ model }) => {
    const versionData = model?.version ? JSON.parse(model.version) : []

    const renderVersion = () => {
        return versionData?.map((thisVersion: any) => {
            return (
                <VersionWrapper>
                    <HeaderRow>
                        <div>
                            <div>Version No:</div>
                            <VersionValue>{thisVersion.number}</VersionValue>
                        </div>
                        <div>
                            <div>Date Released:</div>
                            <VersionValue>
                                {thisVersion.releaseDate}
                            </VersionValue>
                        </div>
                        <div>
                            <div>Published by:</div>
                            <VersionValue>{thisVersion.author}</VersionValue>
                        </div>
                    </HeaderRow>

                    <ReleaseNotes>
                        <div>Release Notes</div>
                        <p>{thisVersion.notes}</p>
                    </ReleaseNotes>

                    {thisVersion?.assets && (
                        <Assets>
                            <div>Assets:</div>
                            {thisVersion.assets.map(
                                (asset: { name: string; link: string }) => {
                                    return <a href={asset.link}>{asset.name}</a>
                                }
                            )}
                        </Assets>
                    )}
                </VersionWrapper>
            )
        })
    }

    const renderContent = () => {
        if (!versionData || versionData.length === 0)
            return (
                <PlaceholderCopy>
                    <h3>Version Details: Coming Soon</h3>
                </PlaceholderCopy>
            )

        return renderVersion()
    }

    return <VersionContent>{renderContent()}</VersionContent>
}

export default VersionTab
