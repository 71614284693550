import { FunctionComponent } from 'react'
import { navyBrand } from '../themes/defaultTheme/palette'

interface Props {
    dark?: boolean
    blue?: boolean
}

const LineArrow: FunctionComponent<Props> = ({
    dark = false,
    blue = false,
}: Props) => {
    const color = dark ? '#fff' : blue ? navyBrand : '#183544'

    return (
        <svg
            className="arrow"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="local link arrow">
                <line
                    id="Line 1"
                    y1="8.81641"
                    x2="15.8392"
                    y2="8.81641"
                    stroke={color}
                    strokeWidth="1.5"
                />
                <path
                    id="Rectangle 24"
                    d="M8 1L16 9L8 17"
                    stroke={color}
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    )
}

export default LineArrow
