/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import ContainerWrapper from '../layouts/containerWrapper'

import CardLinks from '../layouts/cardLinks'

const RetrievalContainer = () => {
    const bodyContent = (isUserLoggedIn: boolean) => {
        return (
            <CardLinks
                title={'Retrieval'}
                queryType="retrieval"
                isUserLoggedIn={isUserLoggedIn}
            />
        )
    }

    return (
        <ContainerWrapper>
            {/* isUserLoggedIn passed as argument in child function from ContainerWrapper */}
            {(isUserLoggedIn) => bodyContent(isUserLoggedIn)}
        </ContainerWrapper>
    )
}

export default RetrievalContainer
