import { OverlayTrigger, Popover } from 'react-bootstrap'
import styled from 'styled-components'

/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/

const PopoverKeyDiv = styled.div`
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
`
export const GeneralInfoPanel = (
    <div style={{ fontSize: 12 }}>
        Note: Log In or Create an account to get a higher priority in running
        queue.
    </div>
)

export const QueueMessagePanel = (queueNumber: number) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            fontWeight: '500',
            color: '#fd7e14',
            padding: '0.5rem',
        }}
    >
        {`You have been added to the queue. Your queue number is ${queueNumber}.`}
        <OverlayTrigger
            placement={'bottom'}
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Popover
                    id="pop_timeline"
                    style={{
                        maxWidth: '20%',
                    }}
                >
                    <Popover.Body>
                        <PopoverKeyDiv>
                            The higher your queue number, the further down the
                            line you are.
                        </PopoverKeyDiv>
                    </Popover.Body>
                </Popover>
            }
        >
            <i className="uil uil-info-circle pointer" tabIndex={0}></i>
        </OverlayTrigger>
    </div>
)
