import { combineReducers } from '@reduxjs/toolkit'

import { accessGroups } from './accessMgmt.slice'
import { authPermissionsResult } from './authPermissions.slice'
import {
    getAIModelsResult,
    postChatCompletionResult,
    postRetrievalQueryResult,
} from './chatCompletion.slice'
import { getComposeSolutionResult } from './composeGeneration.slice'
import { createOrgResult } from './createOrg.slice'
import {
    getAnalyticsResult,
    getClickCountsResult,
    getDashboardDataResult,
    getDisengagedUsersResult,
    getEngagedUsersResult,
    getFirstTimeCustomersResult,
    getModelsDownloadsResult,
    getModelUsageResult,
    getSearchMissesResult,
    getUniqueLoginsResult,
    getUniqueLoginsWithToDateResult,
    getUserDomainDistributionResult,
    postDownloadEventResult,
} from './dashboard.slice'
import { submitImageGenerationResult } from './imageGeneration.slice'
import { acceptInvitesResult } from './invitation.slice'
import { getQueueAttributesResult } from './queueAttributes.slice'
import { submitSupportResult, supportAttachFileResult } from './support.slice'

const rootReducer = combineReducers({
    createOrgResult,
    acceptInvitesResult,
    getAIModelsResult,
    postChatCompletionResult,
    postRetrievalQueryResult,
    submitImageGenerationResult,
    getComposeSolutionResult,
    submitSupportResult,
    supportAttachFileResult,
    postDownloadEventResult,
    getAnalyticsResult,
    getDashboardDataResult,
    getUniqueLoginsResult,
    getUniqueLoginsWithToDateResult,
    getClickCountsResult,
    getEngagedUsersResult,
    getDisengagedUsersResult,
    getFirstTimeCustomersResult,
    accessGroups,
    authPermissionsResult,
    getUserDomainDistributionResult,
    getSearchMissesResult,
    getModelUsageResult,
    getQueueAttributesResult,
    getModelsDownloadsResult,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
