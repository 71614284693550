import { Cache } from '@aws-amplify/cache'
import { fetchAuthSession } from 'aws-amplify/auth'
import { FunctionComponent, useEffect, useState } from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import CustomButton from '../components/customButton'
import { useAppSelector } from '../config/hooks'
import { _setEncryptedValueInStorage } from '../lib/encryptStorage'
import stringUtils from '../lib/stringUtils'

const MobilePanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #e9e9e9;
    padding: 1.5rem;
    margin-top: auto;

    @media screen and (min-width: 768px) {
        display: none;
    }
`

const DesktopPanelContainer = styled.div`
    display: none;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ParentNavDiv = styled(NavDropdown.Item)`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    padding-left: 0.2rem;
    padding-right: 1.5rem;
    color: ${(props) => props.theme.color.text.navy};
    font-weight: 400;
    &:active,
    &:hover {
        color: ${(props) => props.theme.color.text.navy};
        text-decoration: underline;
    }
`

const UserNameText = styled.div`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    padding-left: 0.2rem;
    font-weight: 400;
    margin-right: 0.5rem;
    color: #262626;
    max-width: 100%;
    margin-bottom: 1.5rem;
    @media screen and (min-width: 768px) {
        text-wrap: nowrap;
        color: #000;
        margin-bottom: 0;
    }
`

const UserNameVerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
`

const LoginButton = styled.button`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: ${(props) => props.theme.color.text.navy};
    padding: 0.5rem 2rem;
    border-radius: 0;
    border: ${(props) => props.theme.color.text.navy};
    margin-bottom: 1rem;

    &:not(.mobile) {
        display: none;
        @media screen and (min-width: 768px) {
            display: block;
            margin-right: 1rem;
            margin-bottom: 0;
        }
    }

    &:hover {
        background-color: ${(props) => props.theme.color.text.blue};
        color: #fff;
    }
`

const CreateAccount = styled(LoginButton)`
    color: ${(props) => props.theme.color.text.navy};
    border: 1px solid ${(props) => props.theme.color.text.navy};
    background-color: #fff;
    margin-bottom: 0;
`

const LoginSpacer = styled.span`
    margin-bottom: 1rem;
    &:not(.mobile) {
        display: none;
        @media screen and (min-width: 768px) {
            display: block;
            margin-right: 1rem;
            margin-bottom: 0;
        }
    }
`

const CreateSpacer = styled(LoginSpacer)`
    margin-bottom: 0;
`

export interface IUserPanel {
    isPublicSearch?: boolean
    mobile?: boolean
    isUserLoggedIn?: boolean
}

export const UserProfilePanel: FunctionComponent<IUserPanel> & {} = ({
    isPublicSearch,
    mobile,
    isUserLoggedIn,
}: IUserPanel) => {
    const [userLoginName, setUserLoginName] = useState({})

    const authPermissionsResult = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )

    let isIntelEmployee = false
    if (authPermissionsResult && authPermissionsResult?.isIntelEmployee) {
        isIntelEmployee = authPermissionsResult?.isIntelEmployee
    }

    if (authPermissionsResult?.otherMetaPermissions) {
        let metaPermissions =
            authPermissionsResult.otherMetaPermissions.toString()
        _setEncryptedValueInStorage('otherMetaPermissions', metaPermissions)
    }

    if (authPermissionsResult?.userDisplayName) {
        localStorage.setItem(
            'userDisplayName',
            authPermissionsResult?.userDisplayName ?? ''
        )
    }

    const userDisplayName = localStorage.getItem('userDisplayName')

    const GetSignInInfo = async () => {
        try {
            const { tokens } = await fetchAuthSession()
            const idToken = tokens ? tokens.idToken : null
            let user_info = idToken ? idToken.payload : null
            if (user_info) {
                AssignUserInfo(user_info)
            }
            return user_info
        } catch (error) {
            console.log(error)
            Cache.clear()
        }
    }

    const formattedName = (name: string) => {
        if (name.includes('@')) {
            return name
        }
        const arr = name.split(' ')
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        }
        const str2 = arr.join(' ')
        return str2
    }

    const AssignUserInfo = (userInfo: any) => {
        if (userInfo) {
            if (
                userInfo &&
                userInfo['name'] &&
                !stringUtils.isNullOrUndefinedOrEmpty(userInfo['name'])
            ) {
                setUserLoginName(userInfo['name'])
            } else if (!stringUtils.isNullOrUndefinedOrEmpty(userDisplayName)) {
                const userDisplayNameNotNull = userDisplayName
                    ? userDisplayName
                    : ''
                const formattedUserDisplayName = formattedName(
                    userDisplayNameNotNull
                )
                setUserLoginName(formattedUserDisplayName ?? '')
            } else if (userInfo && userInfo['email']) {
                setUserLoginName(userInfo['email'])
            } else if (userInfo && userInfo['cognito:username']) {
                setUserLoginName(userInfo['cognito:username'])
            }
        }
    }

    useEffect(() => {
        //get user info from amplify session after cognito login.
        GetSignInInfo()
    }, [])

    const userName = userLoginName ? 'Welcome, ' + userLoginName : ''

    return (
        <UserInfoContainer>
            {!isPublicSearch ? (
                <UserSettings
                    userName={userName}
                    isIntelEmployee={isIntelEmployee}
                    mobile={mobile}
                    isUserLoggedIn={isUserLoggedIn}
                />
            ) : (
                <></>
            )}
        </UserInfoContainer>
    )
}

export interface IUserSettings {
    userName: string
    isIntelEmployee: boolean
    mobile?: boolean
    isUserLoggedIn?: boolean
}

export const UserSettings: FunctionComponent<IUserSettings> = ({
    userName,
    isIntelEmployee,
    mobile,
    isUserLoggedIn,
}: IUserSettings) => {
    let navigate = useNavigate()

    const loggedInView = () => {
        if (!isUserLoggedIn) return
        return (
            <>
                <UserNameVerticalContainer>
                    <UserNameText>{userName}</UserNameText>
                </UserNameVerticalContainer>

                {isIntelEmployee ? (
                    <ParentNavDiv href={'/access'}>Access</ParentNavDiv>
                ) : (
                    ''
                )}

                <ParentNavDiv href={'/logOut'}>Log out</ParentNavDiv>
            </>
        )
    }

    const loggedOutView = () => {
        if (isUserLoggedIn) return
        return (
            <>
                <LoginSpacer className={mobile ? 'mobile' : ''}>
                    <CustomButton href="/signIn" text="Login" />
                </LoginSpacer>

                <CreateSpacer className={mobile ? 'mobile' : ''}>
                    <CustomButton
                        styleType={'darkOutline'}
                        href="/createAccount"
                        text="Create Account"
                    />
                </CreateSpacer>
            </>
        )
    }

    const PanelContainer = mobile ? MobilePanelContainer : DesktopPanelContainer

    return (
        <PanelContainer>
            {loggedOutView()}
            {loggedInView()}
        </PanelContainer>
    )
}

export default UserProfilePanel
